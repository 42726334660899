import React, { useState, useEffect } from 'react'
import { createStyles, rem, Container, Text, Space, Button, Box, Alert, Loader } from '@mantine/core'
import { IconCircleCheckFilled, IconCircleXFilled, IconInfoCircle } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { notifications } from '@mantine/notifications';

const HEADER_HEIGHT = rem(70);
const HEADER_HEIGHT_MOBILE = rem(55);

const useStyles = createStyles((theme) => ({
    
    authBG: {
        backgroundSize: 'cover', 
    },

    authSection: {
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            minHeight: `calc(100vh - ${rem(HEADER_HEIGHT_MOBILE)})`,
        },
        backgroundColor: 'rgba(250, 250, 250, 0.75)',
    },

    authWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.fn.smallerThan('sm')]: {
            alignItems: 'center'
        },
        padding: '25px 20px',
        width: '100%',
    },

    authContainer: {
        width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', 
        padding: '25px 25px',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    },

    authTitle: {
        background: 'linear-gradient(261deg, #F47121 0%, #F6D00F 100%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        // marginTop: 15,
        marginBottom: 30,
    }

}));

export default function PaymentStatus() {
    const { classes, } = useStyles();
    const navigate = useNavigate()
    const {status} = useParams() 
    const { id } = useParams()
    const axiosPrivate = useAxiosPrivate()

    const [isLoading, setIsLoading] = useState(true)

    const [showAlert, setShowAlert] = useState(false)

    const [isPaid, setIsPaid] = useState(false)

    const onClickViewBooking = () => {
        navigate(`/profile/booking/${id}`, { replace: true });
    }

    const checkPaymentStatusMutation = useMutation(
        (data) => axiosPrivate.post("/payment/get-payment-status", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res)
                // console.log(res.data.isPaid)
                setIsPaid(res.data.isPaid)
                setShowAlert(!res.data.isPaid)
                setIsLoading(false)
            },
            onError:(err) => {
                setIsLoading(false)
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    useEffect(()=>{
        const timer = setTimeout(() => {
            checkPaymentStatusMutation.mutate({scheduleId: id})
        }, 3500);
        return () => clearTimeout(timer);
    },[])

    return (
        <div className={classes.authSection}>
            <Container className={classes.authWrapper} size={'sm'}>
                <div className={classes.authContainer}>
                    {showAlert ?
                    <Alert mt={10} mb={10} icon={<IconInfoCircle size={22} />} title="Info" color="yellow" withCloseButton onClose={() => setShowAlert(false)}>
                        If you have paid, we are currently processing your payment. 
                        Please contact us if your payment status is not updated within 30 minutes after payment. 
                        You can refresh this page from time to time to check your payment status.
                        If you have not made the payment, please ignore this message.
                    </Alert>
                    : null}
                    {isLoading ? 
                    <>
                        <Space h={25}/>
                        <Loader size={85}/>
                        <Space h={25}/>
                        <Text fw={600} size={20} color='#202020' lh={'normal'} align='center' pb={15}>Checking Payment Status</Text>
                        <Text fw={400} size={14} color='#5D5D5D' lh={'normal'} align='center'>Please wait while we are checking your payment status.</Text>
                        <Space h={25}/>
                    </>
                    :
                    isPaid ? <>
                        <IconCircleCheckFilled size={85} style={{color: '#29BF6E'}}/>
                        <Space h={25}/>
                        <Text fw={600} size={20} color='#202020' lh={'normal'} align='center' pb={15}>Booking Successful</Text>
                        <Text fw={400} size={14} color='#5D5D5D' lh={'normal'} align='center'>You have successfully booked your court.</Text>
                        <Space h={40}/>
                        <Box w={220}>
                            <Button onClick={onClickViewBooking} fullWidth color='#F6851E'>View Booking</Button>
                        </Box>
                    </> : <>
                        <IconCircleXFilled size={85} style={{color: '#CA2A2A'}}/>
                        <Space h={25}/>
                        <Text fw={600} size={20} color='#202020' lh={'normal'} align='center' pb={15}>Booking Unsuccessful</Text>
                        <Text fw={400} size={14} color='#5D5D5D' lh={'normal'} align='center'>There seems to be problem with the booking. Try again later.</Text>
                        <Space h={40}/>
                        <Box w={220}>
                            <Button onClick={onClickViewBooking} fullWidth color='#F6851E'>Pay Again</Button>
                        </Box>
                    </> 
                    }
                </div>
            </Container>
        </div>
    )
}
