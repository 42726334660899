// export const BASE_API_URL = 'http://localhost:1337/api'
export const BASE_API_URL = 'https://icourt-admin.link/api'


export const APPSTORE_URL = ``
export const PLAYSTORE_URL = ``

export const INSTAGRAM_URL = `https://www.instagram.com/icourt.official/`;
export const FACEBOOK_URL = `https://www.facebook.com/people/I-Court/100071567583998/`;

export const DAY_OF_WEEK = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
]
