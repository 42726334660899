import React, { useState, useEffect } from 'react'
import { ScrollArea, Space, Text, Loader, } from '@mantine/core'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import { IconAlertCircle } from '@tabler/icons-react'

import { formatDOW, formath, getDaysDiff, formatm } from '../../utils/DateTime'


export default function TimeTable({ courts, schedule, openingHours, date, isLoading, isError }) {
    const { width } = useViewportSize()
    const matches = useMediaQuery('(min-width: 48em)');

    const [isLoaded, setIsLoaded] = useState(false)

    const opening =  openingHours.find(x => x.day === formatDOW(date))

    const renderHours = () => {
        if(!opening) return null
        const hoursList = [];
        for(let i = opening?.open; i < opening?.close; i++){
            hoursList.push(<div key={i} style={{display: 'flex', width: 40, justifyContent: 'center', marginRight: 3}}>
                <Text fw={600} color='#202020' lh={'normal'} size={12}>{i >= 10 ? i : `0${i}`}:00</Text>
            </div>);
        }
        return hoursList;
    };

    const renderMarker = (court) => {
        let filteredSchedules = schedule.filter((sche) => sche.courtArr.includes(court.courtId))
        let marker = []
        if(filteredSchedules.length > 0){
            marker = filteredSchedules.map((fs)=>{ 
                let startMinute = parseInt(formatm(fs?.startDatetime))
                let endMinute = parseInt(formatm(fs?.endDatetime))
                let dayDiffEnd = getDaysDiff(date, fs?.endDatetime)
                let dayDiffStart = getDaysDiff(date, fs?.startDatetime)
                if(dayDiffStart > 0){    // from previous day
                    if(endMinute > 0)       // if end minute more than 0
                    return {start: 0, end: parseInt(formath(fs?.endDatetime))+1, startMinute: 0, endMinute}
                    else
                    return {start: 0, end: parseInt(formath(fs?.endDatetime)), startMinute: 0, endMinute}
                }
                else if(dayDiffEnd < 0) // to next day
                    return {start: parseInt(formath(fs?.startDatetime)), end: 24, startMinute, endMinute: 45}
                else{                       // same day
                    if(endMinute > 0)       // if end minute more than 0
                    return {start: parseInt(formath(fs?.startDatetime)), end: parseInt(formath(fs?.endDatetime))+1, startMinute, endMinute}
                    else
                    return {start: parseInt(formath(fs?.startDatetime)), end: parseInt(formath(fs?.endDatetime)), startMinute, endMinute}
                }
            })
            // console.log(marker)
        }
        if(!opening) return null
        const markerList = [];
        let markerIndex = 0;
        let start = null
        let end = null
        let startMinute = null
        let endMinute = null
        for(let i = opening?.open; i < opening?.close; i++){
            if(filteredSchedules.length > 0){
                if(marker[markerIndex].start === i){ 
                    start = marker[markerIndex].start
                    end = marker[markerIndex].end
                    startMinute = marker[markerIndex].startMinute
                    endMinute = marker[markerIndex].endMinute
                    if(markerIndex > 0){
                        if(marker[markerIndex-1].end-1 === i && marker[markerIndex-1].endMinute > 0){
                            startMinute = 0
                        }
                    }
                    if(filteredSchedules.length > markerIndex+1)
                        markerIndex++;
                }
            }
            if(i >= start && i < end){
                markerList.push(
                    <div key={i} style={{display: 'flex', marginRight: 3}}>
                        {i === start && startMinute >= 30 ?
                            <>
                            <div style={{height: 30, width: 20, border: '1px solid rgba(0, 0, 0, 0.15)', borderRightWidth: 0, backgroundColor: '#FAFAFA', borderTopLeftRadius: 4,borderBottomLeftRadius: 4,}}/>
                            <div style={{height: 30, width: 20, border: '1px solid #F38500', borderLeftWidth: 0, backgroundColor: '#FEE6CB', borderTopRightRadius: 4,borderBottomRightRadius: 4,}}/> 
                            </>
                        : 
                        i >= start && i === (end-1) && (endMinute > 0 && endMinute <= 30) ?
                            <>
                            <div style={{height: 30, width: 20, border: '1px solid #F38500', borderRightWidth: 0, backgroundColor: '#FEE6CB', borderTopLeftRadius: 4,borderBottomLeftRadius: 4,}}/>
                            <div style={{height: 30, width: 20, border: '1px solid rgba(0, 0, 0, 0.15)', borderLeftWidth: 0, backgroundColor: '#FAFAFA', borderTopRightRadius: 4,borderBottomRightRadius: 4,}}/> 
                            </>
                        :
                            <>
                                <div key={i} style={{height: 30, width: 40, border: '1px solid #F38500', backgroundColor: '#FEE6CB', borderRadius: 4,}} /> 
                            </>
                        }
                    </div>
                );
            }else {
                markerList.push(<div key={i} style={{height: 30, width: 40, border: '1px solid rgba(0, 0, 0, 0.15)', backgroundColor: '#FAFAFA', borderRadius: 4, marginRight: 3}} />);
            }   
        }
        return markerList;
    }

    const timeTableComponent = courts.map((item, i) => 
        (
            <div key={i} style={{display: 'flex', paddingTop: 5}}>
                <div style={{width: 65, display: 'flex', alignItems: 'center'}}>
                    <Text fw={600} color='#202020' lh={'normal'} size={12}>{item?.name}</Text>
                </div>
                {renderMarker(item)}
            </div>
        )
    )

    useEffect(()=>{
        setIsLoaded(true)
    },[])

    let scrollWidth = 638
    if(width < 1200 && matches){
        scrollWidth = 638 - ((1200 - width)/2)
    }else if(!matches){
        scrollWidth = width - (65+20)
    }

    if(isError) return (
        <ScrollArea w={scrollWidth} offsetScrollbars>
        <div style={{display: 'flex', flexDirection: 'column', height: 150, backgroundColor: '#FAFAFA', justifyContent: 'center', alignItems: 'center',marginTop: 25}}>
            <IconAlertCircle size={20} color="#5D5D5D" />
            <Text size={12} fw={'500'} color={'#5D5D5D'}>Unable to load...</Text>
        </div>
        </ScrollArea>
    )

    if(!isLoaded || isLoading) return (
        <ScrollArea w={scrollWidth} offsetScrollbars>
        <div style={{display: 'flex', flexDirection: 'column',height: 150, backgroundColor: '#FAFAFA', justifyContent: 'center', alignItems: 'center',marginTop: 25}}>
            <Loader size="xs"/>
            <Text size={12} fw={'500'} color={'#5D5D5D'}>Loading...</Text>
        </div>
        </ScrollArea>
    )

  return (
    <ScrollArea w={scrollWidth} offsetScrollbars>
        <div style={{display: 'flex', paddingTop: 25}}>
            <Space w={65}/>
            {renderHours()}
        </div>
        <Space h={5}/>
        {timeTableComponent}
    </ScrollArea>
  )
}
