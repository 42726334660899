import React, { useState, useEffect, useCallback } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Select, Image, Divider, Button, Loader, ScrollArea, Skeleton, } from '@mantine/core'
import { IconChevronLeft, IconCalendar, IconMapPinFilled, IconPhone } from '@tabler/icons-react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { DatePickerInput } from '@mantine/dates';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { Carousel } from '@mantine/carousel';
import { notifications } from '@mantine/notifications';
import { useQuery, useMutation } from 'react-query';
import moment from 'moment';
import momenttz from 'moment-timezone';

import TimeTable from '../../components/Venue/TimeTable';
import CourtCard from '../../components/Venue/CourtCard';
// import VenueCard from '../../components/Home/VenueCard';
import VenueCard from '../../components/Venue/VenueCard';

import IconShop from '../../assets/icons/IconShop.png'
import IconShower from '../../assets/icons/IconShower.png'
import IconToilet from '../../assets/icons/IconToilet.png'
import IconParking from '../../assets/icons/IconParking.png'

import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

import { formatDD_MM_YYYY, formatDOW, formath, formatDOW_NextDay, formatm } from '../../utils/DateTime';

const MAX_DURATION = 6;

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(25),
    },

    containerRow: {
        display: 'flex', alignItems: 'flex-start',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column'
        },
    },

    venueContainer: {
        flex: 6, marginRight: 25,
        [theme.fn.smallerThan('sm')]: {
            flex: 1, marginRight: 0,
        },
    },

    bookNowContainer: {
        flex: 3, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: 25,
        [theme.fn.smallerThan('sm')]: {
            display: 'none'
        },
    },

    title: {
        fontSize: 28,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 20,
        },
    },

    sectionTitle: {
        fontSize: 24,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 16,
        },
    },

    fontSM: {
        fontSize: 12,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 11,
        },
    },

    fontLG: {
        fontSize: 20,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 16,
        },
    },

    courtAvailabilityContainer: {
        display: 'flex', justifyContent: 'space-between',
        [theme.fn.smallerThan('md')]: {
            flexDirection: 'column'
        },
    },

    courtAvailabilityStatusContainer: {
        display: 'flex', alignItems: 'center',
        [theme.fn.smallerThan('md')]: {
            justifyContent: 'flex-end',
            paddingTop: 25
        },
    },

    row: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    fixedBottom: {
        position: 'fixed',
        height: 80,
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        zIndex: 100,
        padding: '14px 14px',
        [theme.fn.largerThan('sm')]: {
            display: 'none'
        },
    },

}))

const RECOMMENDED = [
    {
        id: '1',
        sports: 'BADMINTON, CAPTAINBALL',
        name: 'X Park PJ South',
        city: 'Petaling Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue01.png')
    },
    {
        id: '2',
        sports: 'TABLE TENNIS',
        name: 'Desport Table Tennis Centre',
        city: 'Subang Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue02.png')
    },
    {
        id: '3',
        sports: 'FOOTBALL',
        name: 'Endfield Football Field, The Club',
        city: 'Petaling Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue03.png')
    },
    {
        id: '4',
        sports: 'HOCKEY, HANDBALL, FUTSAL',
        name: 'Scotfield Sport Centre',
        city: 'Subang Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue04.png')
    }
]

const COURT = [
    {
        courtId: '1',
        name: '1A',
        price: 20,
    },
    {
        courtId: '2',
        name: '1B',
        price: 20,
    },
    {
        courtId: '3',
        name: '1C',
        price: 20,
    }
]


export default function ViewVenue() {
    const { classes } = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const { auth } = useAuth();
    let currentDate = moment().tz("Asia/Kuala_Lumpur").toDate()
    const location  = useLocation()

    const matches = useMediaQuery('(min-width: 48em)');

    const [embla, setEmbla] = useState(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const [gallery, setGallery] = useState([])
    const [openingHours, setOpeningHours] = useState([])
    const [courts, setCourts] = useState([])
    const [schedule, setSchedule] = useState([])
    const [sports, setSports] = useState([])
    const [pricing, setPricing] = useState([])

    //  For Court Availability
    const [selectedSport, setSelectedSport] = useState(null);
    const [date, setDate] = useState(currentDate)

    // START For Book Now
    const [courtsSelection, setCourtsSelection] = useState([])
    const [selectedCourts, setSelectedCourts] = useState('[]')
    const [timeSelection, setTimeSelection] = useState([])
    const [durationSelection, setDurationSelection] = useState([])
    const [selectedTime, setSelectedTime] = useState('')
    const [selectedDuration, setSelectedDuration] = useState('')
    const onSelectCourt = (item) => {
        let tempSelect = JSON.parse(selectedCourts)
        if(tempSelect.some((obj)=>{ return obj.courtId === item?.courtId })){
            tempSelect = tempSelect.filter(function(obj) { return obj.courtId !== item?.courtId; })
        }
        else{
            tempSelect.push(item)
        }
        setSelectedCourts(JSON.stringify(tempSelect))
    }
    useEffect(()=>{
        const generateTime = () => {
            const opening =  openingHours.find(x => x.day === formatDOW(date))
            const openingNextDay = openingHours.find(x => x.day === formatDOW_NextDay(date))
            let timeArr = []
            let openTime = opening?.open
            let closeTime = opening?.close-0.5
            if(formatDD_MM_YYYY(currentDate) === formatDD_MM_YYYY(date)){
                openTime = parseInt(formath(currentDate)) + 0.5
                if(parseInt(formatm(currentDate)) >= 30){
                    openTime = openTime + 0.5
                }
            }
            if(opening?.close === 24 && openingNextDay?.open === 0){
                closeTime = opening?.close
            }
            for (let i = openTime; i < closeTime; i=i+0.5) {
                timeArr.push({
                    label: `${i < 10 ? '0'+Math.floor(i) : i >= 24 ? '0' + (Math.floor(i)-24) : Math.floor(i)}:${(i % 1).toFixed(1) === '0.5' ? '30' : '00'}`,
                    value: `${i}`
                })
            }
            setTimeSelection(timeArr)
        }
        if(openingHours.length > 0) generateTime()
    },[openingHours, date])
    useEffect(()=>{
        const generateDuration = () => {
            const opening = openingHours.find(x => x.day === formatDOW(date))
            const openingNextDay = openingHours.find(x => x.day === formatDOW_NextDay(date))
            let durationArr = []
            let hour = 1;
            if(opening?.close === 24 && openingNextDay?.open === 0){
                for (let i = 1; i < openingNextDay?.close; i++) {
                    durationArr.push({
                        label: `${Math.floor(hour)} Hour${Math.floor(hour)>1 ? 's' : ''} ${(hour % 1).toFixed(1) === '0.5' ? '30 Min' : ''}`,
                        value: `${hour}`
                    })
                    hour = hour + 0.5;
                    if(hour>MAX_DURATION) break;
                }
            }else{
                for (let i = parseFloat(selectedTime); i < opening?.close-0.5; i=i+0.5) {
                    durationArr.push({
                        label: `${Math.floor(hour)} Hour${Math.floor(hour)>1 ? 's' : ''} ${(hour % 1).toFixed(1) === '0.5' ? '30 Min' : ''}`,
                        value: `${hour}`
                    })
                    hour = hour + 0.5;
                    if(hour>MAX_DURATION) break;
                }
            }
            setDurationSelection(durationArr)
        }
        if(selectedTime)
            generateDuration()
    },[openingHours, selectedTime])
    useEffect(()=>{
        setSelectedTime('')
        setSelectedDuration('')
        setSelectedCourts('[]')
        setDurationSelection([])
    },[date, selectedSport])
    useEffect(()=>{
        setSelectedDuration('')
        setSelectedCourts('[]')
        // setDurationSelection([])
    },[selectedTime])
    useEffect(()=>{
        setSelectedCourts('[]')
        setCourtsSelection([])
        if(selectedDuration){
            getAvailableCourts()
        }
    },[selectedDuration])
    // END For Book Now //

    const { data, isFetching, isError, isRefetching, isLoading, refetch } = useQuery(
        "getVenueDetails",
        async ({ signal }) => (await axios.post("/locations/read-by-location-id", {locationId: id, uid: auth?.uid ? auth?.uid : '' }, { signal })).data.location,
        {
            initialData: null,
            onSuccess: (res) => {
                // console.log(res)
                setGallery(JSON.parse(res?.gallery ? res?.gallery : '[]'))
                setOpeningHours(JSON.parse(res?.opening_hours ? res?.opening_hours : '[]'))
                setSelectedSport(res?.sports[0] ? res?.sports[0].value : 0)
                setSports(res?.sports)
                setPricing(JSON.parse(res?.pricing ? res?.pricing : '[]'))
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const getCourtDataMutation = useMutation(
        (data) => axios.post("/court/get-courts-by-location", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res)
                setCourts(res?.data?.courts)
                setSchedule(res?.data?.schedule)
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const getAvailableCourtsMutation = useMutation(
        (data) => axios.post("/court/get-available-courts", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res?.data?.courts)
                setCourtsSelection(res?.data?.courts)
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const getRecommendedQuery = useQuery(
        "getRecommendedHome",
        async ({ signal }) => (await axios.post("/locations/read-limit", { }, { signal })).data.locations,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    useEffect(()=>{
        if(selectedSport && date){
            getCourtData()
        }
    },[selectedSport, date])

    const getCourtData = () => {
        getCourtDataMutation.mutate({ locationId: id, type: selectedSport, date: date })
    }

    const getAvailableCourts = () => {
        getAvailableCourtsMutation.mutate({ locationId: id, type: selectedSport, date: date, time: selectedTime, duration: selectedDuration })
    }

    const handleSlideIndex = useCallback(() => {
        if (!embla) return;
        const slideIndex = embla.selectedScrollSnap();
        setCurrentSlideIndex(slideIndex);
    }, [embla, setCurrentSlideIndex]);
    
    useEffect(() => {
        if (embla) {
          embla.on('select', handleSlideIndex);
          handleSlideIndex();
        }
    }, [embla, handleSlideIndex]);


    const onClickSlide = (index) => {
        if (!embla) return;
        embla.scrollTo(index)
    }

    const onClickBookNow = (id) => {
        if(!auth) {
            navigate('/login', { state:{ from: location }, replace: true });
            return; 
        }
        navigate(`/venue/book/${id}?sport=${encodeURIComponent(selectedSport)}&date=${encodeURIComponent(moment(date).format(("YYYY-MM-DD")))}&time=${encodeURIComponent(selectedTime)}&duration=${encodeURIComponent(selectedDuration)}`)
    }

    const onClickBack = () => {
        navigate(-1)
    }

    const onClickViewVenue = (id) => {
        navigate(`/venue/${id}`)
    }

    return (
        <Container size={'lg'} className={classes.root}>
            <div className={classes.containerRow}>
                {/* Venue Information Section */}
                <div className={classes.venueContainer}>
                    <Anchor onClick={onClickBack} color='brandSecondary'>
                        <Group spacing={8}>
                            <IconChevronLeft size={22}/>
                            <Text fw={500} size={15} lh={'normal'}>Go Back</Text>
                        </Group>
                    </Anchor>
                    <Space h={15}/>
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                        <Text fw={700} color='#202020' lh={'normal'} className={classes.title}>{data?.name}</Text>
                    </Skeleton>
                    <Space h={20}/>
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                        <Carousel height={matches ? 400: 300} withControls={false} getEmblaApi={setEmbla}>
                            {gallery.map((item, i) => 
                            (
                                <Carousel.Slide key={i}>
                                    <Image height={matches?400 : 300} src={item} style={{borderRadius: 5}} radius={5} alt="With default placeholder" withPlaceholder />
                                </Carousel.Slide>
                            )
                            )}
                        </Carousel>
                    </Skeleton>
                    <Space h={15}/>
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                        <Carousel height={matches ? 80 : 60} slideSize="20%" slideGap={15} slidesToScroll={matches ? 5 : 4} align="start"
                        breakpoints={[
                            { maxWidth: 'sm', slideSize: '25%', slideGap: 15 },
                        ]}>
                            {gallery.map((item, i) => 
                            (
                                <Carousel.Slide key={i}>
                                    <div onClick={()=>onClickSlide(i)} style={{display: 'flex',  height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F0F0F9', borderRadius: 5, cursor: 'pointer'}}>
                                        <Image height={matches?80 : 60} src={item} style={{borderRadius: 5}} radius={5} alt="With default placeholder" withPlaceholder />
                                    </div>
                                </Carousel.Slide>
                            )
                            )}
                        </Carousel>
                    </Skeleton>
                    <Space h={25}/>
                    {/* Court Availability */}
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                        <div style={{backgroundColor: '#FFFFFF', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '20px 25px',}}>
                            <Text fw={700} color='#202020' lh={'normal'} size={16} pb={15}>Court Availability</Text>
                            <div className={classes.courtAvailabilityContainer}>
                                <div style={{display: 'flex',}}>
                                    <Select
                                        w={140}
                                        size='xs'
                                        placeholder="Select sport"
                                        data={sports}
                                        value={selectedSport}
                                        onChange={setSelectedSport}
                                    />
                                    <Space w={15}/>
                                    <DatePickerInput
                                        w={140}
                                        size='xs'
                                        valueFormat="DD/MM/YYYY"
                                        rightSection={<IconCalendar size="1.1rem" stroke={1.5} color='#BABABA'/>}
                                        placeholder="Select date"
                                        value={date}
                                        onChange={setDate}
                                        minDate={currentDate}
                                    />
                                </div>
                                <div className={classes.courtAvailabilityStatusContainer}>
                                    <div style={{ width: 12, height: 12, border: '1px solid #F38500', backgroundColor: '#FEE6CB', marginRight: 5 }}/>
                                    <Text fw={500} color='#202020' lh={'normal'} size={12}>Not Available</Text>
                                    <Space w={15}/>
                                    <div style={{ width: 12, height: 12, border: '1px solid #D5D5D5', backgroundColor: '#FAFAFA', marginRight: 5 }}/>
                                    <Text fw={500} color='#202020' lh={'normal'} size={12}>Available</Text>
                                </div>
                            </div>
                            <TimeTable courts={courts} schedule={schedule} openingHours={openingHours} date={date} isLoading={getCourtDataMutation.isLoading} isError={getCourtDataMutation.isError}/>
                        </div>
                    </Skeleton>
                    <Space h={25}/>
                    {/* Location & Contact Details */}
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                        <div style={{backgroundColor: '#FFFFFF', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '20px 25px',}}>
                            <Text fw={700} color='#202020' lh={'normal'} size={16} pb={15}>Location & Contact Details</Text>
                            <iframe title='Google Map' src={data?.google_embed} width="100%" height={matches ? 350 : 250} style={{border:0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            <Group spacing={8} py={10}>
                                <IconMapPinFilled size={18} color='#5D5D5D' style={{color: '#5D5D5D'}}/>
                                <Text fw={500} color='#5D5D5D' lh={'normal'} size={14}>{data?.address}, {data?.post_code} {data?.city}, {data?.state}</Text>
                            </Group>
                            <Group spacing={8}>
                                <IconPhone size={18} color='#5D5D5D' style={{color: '#5D5D5D'}}/>
                                <Text fw={500} color='#5D5D5D' lh={'normal'} size={14}>+60 {data?.contact}</Text>
                            </Group>
                        </div>
                    </Skeleton>
                    <Space h={25}/>
                    {/* Opening Hours | Prices | Facilities */}
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                    <div style={{backgroundColor: '#FFFFFF', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '20px 25px',}}>
                        <div className={classes.row}>
                            <div style={{flex: 1}}>
                                <Text fw={700} color='#202020' lh={'normal'} size={16} pb={10}>Opening Hours</Text>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                                {openingHours.map((item, i)=>(
                                    <Group key={i} position="apart">
                                        <Text fw={500} color='#5D5D5D' lh={'normal'} size={13}>{item?.day}</Text>
                                        {item?.open===0 && item?.close===24 ?
                                            <Text fw={500} color='#5D5D5D' lh={'normal'} size={13}>24 hours</Text>
                                        :
                                            <Text fw={500} color='#5D5D5D' lh={'normal'} size={13}>{item?.open >= 10 ? item?.open : `0${item?.open}`}:00 - {item?.close >= 10 ? item?.close : `0${item?.close}`}:00</Text>
                                        }
                                    </Group>
                                ))}
                                <Divider my={matches ? 10 : 20}/>
                                <Text fw={700} color='#202020' lh={'normal'} size={16} pb={10}>Prices</Text>
                                
                                {pricing.length > 0 ?
                                <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                                    {pricing.map((item, i)=>(
                                        <div key={i}>
                                        <Text fw={600} color='#202020' lh={'normal'} size={13}>{item.sport}</Text>
                                        {item.pricing.map((item2, i2)=>(
                                            <Text key={i2} fw={600} color='#5D5D5D' lh={'normal'} size={13}>{item2}</Text>
                                        ))
                                        }
                                        </div>
                                    ))
                                    }
                                </div> 
                                : 
                                <Text fw={600} color='#5D5D5D' lh={'normal'} size={13}>No pricing details</Text>
                                }
                                </div>
                            </div>
                            
                            {matches ? 
                                <Space w={25}/>
                                :
                                <Divider my={20}/>
                            }
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                
                                {/* <Text fw={700} color='#202020' lh={'normal'} size={16} pb={10}>Prices</Text>
                                
                                {pricing.length > 0 ?
                                <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                                    {pricing.map((item, i)=>(
                                        <div key={i}>
                                        <Text fw={600} color='#202020' lh={'normal'} size={13}>{item.sport}</Text>
                                        {item.pricing.map((item2, i2)=>(
                                            <Text key={i2} fw={600} color='#5D5D5D' lh={'normal'} size={13}>{item2}</Text>
                                        ))
                                        }
                                        </div>
                                    ))
                                    }
                                </div> 
                                : 
                                <Text fw={600} color='#5D5D5D' lh={'normal'} size={13}>No pricing details</Text>
                                }
                                
                                
                                <Divider my={matches ? 10 : 20}/> */}
                                <div>
                                <Text fw={700} color='#202020' lh={'normal'} size={16} pb={10}>Facilities</Text>
                                <Group>
                                    {data?.have_toilet ?
                                    <div style={{display: 'flex', width: 50, flexDirection: 'column', alignItems: 'center'}}>
                                        <Image width={35} height={35} src={IconToilet} alt="With default placeholder" withPlaceholder />
                                        <Space h={10}/>
                                        <Text fw={500} color='#202020' lh={'normal'} size={13}>Toilet</Text>
                                    </div>
                                    : null}
                                    {data?.have_parking ?
                                    <div style={{display: 'flex', width: 50, flexDirection: 'column', alignItems: 'center'}}>
                                        <Image width={35} height={35} src={IconParking} alt="With default placeholder" withPlaceholder />
                                        <Space h={10}/>
                                        <Text fw={500} color='#202020' lh={'normal'} size={13}>Parking</Text>
                                    </div>
                                    : null}
                                    {data?.have_shower ?
                                    <div style={{display: 'flex', width: 50, flexDirection: 'column', alignItems: 'center'}}>
                                        <Image width={35} height={35} src={IconShower} alt="With default placeholder" withPlaceholder />
                                        <Space h={10}/>
                                        <Text fw={500} color='#202020' lh={'normal'} size={13}>Shower</Text>
                                    </div>
                                    : null}
                                    {data?.have_shop ?
                                    <div style={{display: 'flex', width: 50,flexDirection: 'column', alignItems: 'center'}}>
                                        <Image width={35} height={35} src={IconShop} alt="With default placeholder" withPlaceholder />
                                        <Space h={10}/>
                                        <Text fw={500} color='#202020' lh={'normal'} size={13}>Shop</Text>
                                    </div>
                                    : null}
                                </Group>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Skeleton>
                    <Space h={25}/>
                    {/* Layout */}
                    {data?.layout_img ?
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                        <div style={{backgroundColor: '#FFFFFF', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '20px 25px',   }}>
                            <Text fw={700} color='#202020' lh={'normal'} size={16} pb={15}>Layout</Text>
                            <Image height={matches ? 350 : 250} src={data?.layout_img} fit='contain' alt="With default placeholder" withPlaceholder />
                        </div>
                    </Skeleton>
                    : null}
                    <Space h={25}/>
                    {/* Description */}
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                    <div style={{backgroundColor: '#FFFFFF', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '20px 25px',}}>
                        <Text fw={700} color='#202020' lh={'normal'} size={16} pb={10}>Description</Text>
                        <Text fw={500} color='#5D5D5D' lh={'normal'} size={13} align='justify'>{data?.description}</Text>
                    </div>
                    </Skeleton>
                    <Space h={50}/>
                </div>
                {/* Book Now Section */}
                <Skeleton animate={!isError} visible={isFetching || isError}>
                    <div className={classes.bookNowContainer}>
                        <Text fw={700} color='#202020' lh={'normal'} className={classes.fontLG}>Make a Booking Now</Text>
                        <Divider my={18}/>
                        <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select sports</Text>
                        <Select
                            placeholder="Select sport"
                            data={sports}
                            value={selectedSport}
                            onChange={setSelectedSport}
                        />
                        <Space h={15}/>
                        <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select a date</Text>
                        <DatePickerInput
                            valueFormat="DD/MM/YYYY"
                            rightSection={<IconCalendar size="1.1rem" stroke={1.5} color='#BABABA'/>}
                            placeholder="Select date"
                            value={date}
                            onChange={setDate}
                            minDate={currentDate}
                        />
                        <Space h={15}/>
                        <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select a start time and duration</Text>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}>
                                <Select
                                    placeholder="Select time"
                                    data={timeSelection}
                                    value={selectedTime}
                                    onChange={setSelectedTime}
                                />
                            </div>
                            <Space w={10}/>
                            <div style={{flex: 1}}>
                                <Select
                                    placeholder="Select duration"
                                    data={durationSelection}
                                    value={selectedDuration}
                                    onChange={setSelectedDuration}
                                />
                            </div>
                        </div>
                        <Divider my={18}/>
                        <Text fw={600} color='#202020' lh={'normal'} size={14} >Available Court(s)</Text>
                        <ScrollArea h={180} type="always" offsetScrollbars mb={25}>
                        {courtsSelection.length > 0 ?
                        <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                        {courtsSelection.map((item, i)=>(
                            <CourtCard key={i} data={item} select={selectedCourts} onSelect={()=>onSelectCourt(item)}/>
                        ))
                        }
                        </div>
                        :
                        <div style={{display: 'flex', height: 160, justifyContent: 'center', alignItems: 'center', backgroundColor: '#FAFAFA'}}>
                            {selectedTime && selectedDuration ?
                                <>
                                {getAvailableCourtsMutation.isLoading ?
                                    <Loader size="sm" variant="dots" />
                                :
                                    <Text size={14}>No available courts at this time slot</Text>
                                }
                                </>
                            :
                                <Text size={14}>Please select time and duration</Text>
                            }
                            
                        </div>
                        }
                        </ScrollArea>
                        <Button onClick={()=>onClickBookNow(id)} fullWidth>Book Now</Button>
                    </div>
                </Skeleton>
            </div>
            <Text fw={700} color='#202020' lh={'normal'} className={classes.sectionTitle}>Recommended Venues</Text>
            <Space h={25}/>
            <Carousel
            withIndicators
            // height={200}
            slideSize="33.333333%"
            slideGap="md"
            loop
            align="start"
            // slidesToScroll={3}
            styles={{
                indicator: {
                    backgroundColor: '#D9D9D9',
                    width: rem(10),
                    height: rem(10),
                    borderRadius: rem(10),
                    // transition: 'width 250ms ease',
        
                    '&[data-active]': {
                        backgroundColor: '#858585',
                    },
                },
                control: {
                    backgroundColor: '#DADADA',
                    // color: '#858585',
                    width: rem(20),
                    height: rem(20),
                    borderRadius: rem(40),
                    scale: 2
                }
            }}
            breakpoints={[
                { maxWidth: 'md', slideSize: '50%' },
                { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                ]}
            >
                {getRecommendedQuery.data.map((item, i)=>(
                    <Carousel.Slide key={`key${i}`}>
                            <VenueCard data={item} isError={getRecommendedQuery.isError} loading={getRecommendedQuery.isFetching} onClick={()=>onClickViewVenue(item?.locationId)}/>
                    </Carousel.Slide>
                ))
                }
            </Carousel>
            <div className={classes.fixedBottom}>
                <Button onClick={()=>onClickBookNow(id)} fullWidth disabled={isFetching || isError}>Book Now</Button>
            </div>
        </Container>
    )
}