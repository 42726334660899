import React, { useState, useEffect } from 'react'
import { createStyles, BackgroundImage, rem, Container, Text, Space, Select, Button, Grid, Loader } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates';
import { Carousel } from '@mantine/carousel';
import { IconCalendar, IconCircleX } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useQuery, useMutation } from 'react-query';
import moment from 'moment';
import momenttz from 'moment-timezone';

import VenueCard from '../../components/Home/VenueCard';
import MatchCard from '../../components/Home/MatchCard';

import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../api/axios';

import HomeBG from '../../assets/images/HomeBG3.png'

const useStyles = createStyles((theme) => ({
    root: {
      zIndex: 999,
      transition: 'background-color 0.25s ease',
    },
  
    heroSection: {
        minHeight: `calc(100svh - ${rem(70)})`,
        display: 'flex',

        [theme.fn.smallerThan('sm')]: {
            minHeight: `calc(70svh)`,
        },
    },

    heroBG: {
        // display: 'flex',
        alignSelf: 'flex-end',
        width: '60%',
        backgroundSize: 'cover',
        // backgroundSize: 'contain', 
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        // [theme.fn.smallerThan('md')]: {
        //     backgroundSize: 'cover', 
        // },
        [theme.fn.smallerThan('sm')]: {
            // clipPath: 'polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%)',
            width: '100%',
        },
    },

    tiltedBG1: {
        clipPath: 'polygon(0 0, 80% 0, 100% 100%, 0% 100%)',
        position: 'absolute', backgroundColor: 'rgba(250, 250, 250, 0.40)', zIndex: 0, top: 0, left: 0, width: '55%', height: '100%',
        [theme.fn.smallerThan('sm')]: {
            clipPath: 'polygon(0 0, 43% 0, 100% 100%, 0% 100%)',
            width: '50%',
        },
    },

    tiltedBG2: {
        clipPath: 'polygon(0 0, 80% 0, 100% 100%, 0% 100%)',
        position: 'absolute', backgroundColor: 'rgba(250, 250, 250, 0.50)', zIndex: 0, top: 0, left: 0, width: '60%', height: '100%',

        [theme.fn.smallerThan('sm')]: {
            clipPath: 'polygon(0 0, 53% 0, 100% 100%, 0% 100%)',
            width: '60%',
        },
    },

    tiltedBG3: {
        clipPath: 'polygon(0 0, 80% 0, 100% 100%, 0% 100%)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '50%',
        height: '100%',
        backgroundColor: '#FAFAFA',

        [theme.fn.smallerThan('sm')]: {
            clipPath: 'polygon(0 0, 30% 0, 100% 100%, 0% 100%)',
            width: '40%',
        },
    },

    searchWrapper: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',

        [theme.fn.smallerThan('sm')]: {
            alignItems: 'center'
        },
    },

    searchContainer: {
        width: 400, backgroundColor: 'rgba(255, 255, 255, 0.90)', borderRadius: 5, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: 25, paddingTop: 20,
        [theme.fn.smallerThan('sm')]: {
            width: '100%'
        },
    },

    searchTitle: {
        fontSize: 24,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 20
        },
    },

    searchDesc: {
        fontSize: 12,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 10
        },
    },

    sectionTitle: {
        fontSize: 36,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 20
        },
    },

    sectionDesc: {
        fontSize: 15,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 12
        },
    },

    sectionRecommended: {
        paddingTop: 100,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 50,
        },
    },

    sectionMatch: {
        paddingTop: 100,
        paddingBottom: 100,
        [theme.fn.smallerThan('sm')]: {
            paddingTop: 50,
            paddingBottom: 50,
        },
    },

}));

const RECOMMENDED = [
    {
        id: '1',
        sports: 'BADMINTON, CAPTAINBALL',
        name: 'X Park PJ South',
        city: 'Petaling Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue01.png')
    },
    {
        id: '2',
        sports: 'TABLE TENNIS',
        name: 'Desport Table Tennis Centre',
        city: 'Subang Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue02.png')
    },
    {
        id: '3',
        sports: 'FOOTBALL',
        name: 'Endfield Football Field, The Club',
        city: 'Petaling Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue03.png')
    },
    {
        id: '4',
        sports: 'HOCKEY, HANDBALL, FUTSAL',
        name: 'Scotfield Sport Centre',
        city: 'Subang Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue04.png')
    }
]

const MATCH = [
    {
        id: '1',
        sport: 'BADMINTON',
        type: 'Friendly Match 2 vs 2',
        host: 'Tan Chong Wei',
        venue: 'Sport Heroes (Lead Sport Badminton)',
        date: 'October 3, Tuesday',
        timeFrom: '8:00 pm',
        timeTo: '10:00 pm',
        players: 4,
        slot: 2,
    },
    {
        id: '2',
        sport: 'BADMINTON',
        type: 'Female Single Match',
        host: 'Tan Ching Wen',
        venue: 'X Park PJ South',
        date: 'October 3, Tuesday',
        timeFrom: '10:00 pm',
        timeTo: '12:00 am',
        players: 2,
        slot: 1,
    },
    {
        id: '3',
        sport: 'BADMINTON',
        type: 'Male Double Match (Advanced)',
        host: 'Tan Keat',
        venue: 'KAS Kepong Arena Sports',
        date: 'October 3, Tuesday',
        timeFrom: '6:00 pm',
        timeTo: '8:00 pm',
        players: 8,
        slot: 7,
    },
]

export default function Home() {
    const { classes, } = useStyles();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    let currentDate = moment().tz("Asia/Kuala_Lumpur").toDate()

    const [selectedSport, setSelectedSport] = useState(null)

    const [communityGames, setCommunityGames] = useState([])

    const onClickViewVenue = (id) => {
        navigate(`/venue/${id}`)
    }

    const onClickViewMatch = (id) => {
        navigate(`/match/${id}`)
    }

    const getSportSelectionQuery = useQuery(
        "getSportSelection",
        async ({ signal }) => (await axios.get("/sports/get-all-selection", { }, { signal })).data.sports,
        {
            initialData: [],
            onSuccess: (res) => {
                setSelectedSport(res[0].value)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const getMembershipsQuery = useQuery(
        "getMembershipsHome",
        async ({ signal }) => (await axiosPrivate.post("/membership/read-by-uid-limit-4", { uid: auth?.uid }, { signal })).data.memberships,
        {
            enabled: auth ? true : false,
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                // notifications.show({
                //     title: 'Error',
                //     message: 'Unable to load...',
                //     color: 'red',
                // })
            },
        }
    );

    const getRecommendedQuery = useQuery(
        "getRecommendedHome",
        async ({ signal }) => (await axios.post("/locations/read-limit-4", { }, { signal })).data.locations,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const getCommunityGameQuery = useQuery(
        "getCommunityGameHome",
        async ({ signal }) => (await axios.post("/schedule/read-public-games-limit", {}, { signal })).data.schedule,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const getPublicGamesBySportMutation = useMutation(
        (data) => axios.post("/schedule/read-public-games-by-sport", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res?.data.schedule)
                setCommunityGames(res?.data.schedule)
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    useEffect(()=>{
        if(selectedSport){
            getPublicGamesBySportMutation.mutate({type: selectedSport})
        }
    },[selectedSport])

    const form = useForm({
        initialValues: {
            sport: '',
            state: '',
            date: new Date(),
        },
    
        validate: {
        },
    });

    const onSearch = (data) => {
        navigate(`/search?sport=${encodeURIComponent(data.sport)}&state=${encodeURIComponent(data.state)}&date=${encodeURIComponent(moment(data.date).format(("YYYY-MM-DD")))}`)
    }

    return (
        <Container size={2000} p={0}>
            <div style={{position: 'relative'}}>
                <div style={{display: 'flex', position: 'relative', justifyContent: 'flex-end', backgroundColor: 'transparent'}}>
                    <BackgroundImage src={HomeBG} className={classes.heroBG}>
                        <div className={classes.heroSection}>
                            <div className={classes.tiltedBG1}/>
                            <div className={classes.tiltedBG2}/>
                            <div className={classes.tiltedBG3}/>
                        </div>
                    </BackgroundImage>
                </div>
                <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, display: 'flex'}}>
                    <Container size={'xl'} className={classes.searchWrapper}>
                        <div className={classes.searchContainer}>
                        <form onSubmit={form.onSubmit((values) => onSearch(values))}>
                            <Text fw={800} lh={'normal'} className={classes.searchTitle}>Make a Booking Today</Text>
                            <Text fw={500} color='#5D5D5D' className={classes.searchDesc}>We have all sorts of sports & courts for you to choose.</Text>
                            <Space h={25}/>
                            <Select
                                size='md'
                                placeholder="Select sport"
                                data={getSportSelectionQuery.data}
                                {...form.getInputProps('sport')}
                            />
                            <Space h={15}/>
                            <Select
                                size='md'
                                placeholder="Select state"
                                data={[
                                    { value: 'Johor', label: 'Johor' },
                                    { value: 'Kedah', label: 'Kedah' },
                                    { value: 'Kelantan', label: 'Kelantan' },
                                    { value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
                                    { value: 'Labuan', label: 'Labuan' },
                                    { value: 'Malacca', label: 'Malacca' },
                                    { value: 'Negeri Sembilan', label: 'Negeri Sembilan' },
                                    { value: 'Pahang', label: 'Pahang' },
                                    { value: 'Penang', label: 'Penang' },
                                    { value: 'Perak', label: 'Perak' },
                                    { value: 'Perlis', label: 'Perlis' },
                                    { value: 'Putrajaya', label: 'Putrajaya' },
                                    { value: 'Sabah', label: 'Sabah' },
                                    { value: 'Sarawak', label: 'Sarawak' },
                                    { value: 'Selangor', label: 'Selangor' },
                                    { value: 'Terengganu', label: 'Terengganu' },
                                ]}
                                {...form.getInputProps('state')}
                            />
                            <Space h={15}/>
                            <DatePickerInput
                                valueFormat="DD/MM/YYYY"
                                size='md'
                                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                                placeholder="Select date"
                                {...form.getInputProps('date')}
                                minDate={currentDate}
                            />
                            <Space h={25}/>
                            <Button type='submit' fullWidth size='md'>Search</Button>
                        </form>
                        </div>
                        
                    </Container>
                </div>
            </div>
            <div style={{display: 'flex', filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))'}}>
                <div style={{flex: 1,backgroundColor: '#FFFFFF'}}>
                    <div style={{zIndex: 3, flex: 1, minHeight: 50, background: 'linear-gradient(90deg, #F6CF0F 0%, #F47120 100%)',clipPath: 'polygon(0 0%, 95% 0, 100% 50%, 95% 100%, 0 100%, 0% 50%)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Text fw={700} size={12} color='#FFFFFF' align='center' lh={'normal'}>Search for Sport Venue</Text>
                    </div>
                </div>
                <div style={{flex: 1,background: 'linear-gradient(90deg, #FFFFFF 0%, #F6CF0F 100%)'}}>
                <div style={{zIndex: 2, flex: 1, minHeight: 50, backgroundColor: 'white',clipPath: 'polygon(0 0%, 95% 0, 100% 50%, 95% 100%, 0 100%, 0% 50%)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Text fw={700} size={12} color='#F36600' align='center' lh={'normal'}>Book Online</Text>
                </div>
                </div>
                <div style={{zIndex: 1, flex: 1, minHeight: 50, background: 'linear-gradient(90deg, #F6CF0F 0%, #F47120 100%)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Text fw={700} size={12} color='#FFFFFF' align='center' lh={'normal'}>Enjoy & Play</Text>
                </div>
            </div>
            {auth && getMembershipsQuery.data.length > 0 ?
            <>
            <div className={classes.sectionRecommended}>
                <Container size={'xl'}>
                    <Text fw={800} align='center' color='#202020' lh={'normal'} className={classes.searchTitle}>Your Memberships</Text>
                    <Space h={5}/>
                    <Text fw={500} align='center' color='#5D5D5D' lh={'normal'} className={classes.searchDesc}>Your current membership venues and most recent bookings.</Text>
                    <Space h={50}/>
                    
                    <Grid gutterXl={'xl'}>
                        {getMembershipsQuery.data.length > 0 ?
                        <>
                        {getMembershipsQuery.data.map((item, i)=>(
                            <Grid.Col key={`key${i}`} span={12} xs={12} sm={6} md={4} lg={3} xl={3}>
                                <VenueCard data={item} isError={getMembershipsQuery.isError || !auth} loading={getMembershipsQuery.isFetching} onClick={()=>onClickViewVenue(item?.locationId)}/>
                            </Grid.Col>
                        ))}
                        </>
                        :
                        <>
                        {RECOMMENDED.map((item, i)=>(
                            <Grid.Col key={`key${i}`} span={12} xs={12} sm={6} md={4} lg={3} xl={3}>
                                <VenueCard data={item} isError={getMembershipsQuery.isError || !auth} loading={getMembershipsQuery.isFetching} onClick={()=>onClickViewVenue(item?.locationId)}/>
                            </Grid.Col>
                        ))}
                        </>
                        }
                    </Grid>
                    
                </Container>
            </div>
            </>
            : null
            }
            <div className={classes.sectionRecommended}>
                <Container size={'xl'}>
                    <Text fw={800} align='center' color='#202020' lh={'normal'} className={classes.searchTitle}>Recommended For You</Text>
                    <Space h={5}/>
                    <Text fw={500} align='center' color='#5D5D5D' lh={'normal'} className={classes.searchDesc}>Discover a variety of sports facilities in different cities across Malaysia.</Text>
                    <Space h={50}/>
                    
                    <Grid gutterXl={'xl'}>
                        {getRecommendedQuery.data.length > 0 ?
                        <>
                        {getRecommendedQuery.data.map((item, i)=>(
                            <Grid.Col key={`key${i}`} span={12} xs={12} sm={6} md={4} lg={3} xl={3}>
                                <VenueCard data={item} isError={getRecommendedQuery.isError} loading={getRecommendedQuery.isFetching} onClick={()=>onClickViewVenue(item?.locationId)}/>
                            </Grid.Col>
                        ))}
                        </>
                        :
                        <>
                        {RECOMMENDED.map((item, i)=>(
                            <Grid.Col key={`key${i}`} span={12} xs={12} sm={6} md={4} lg={3} xl={3}>
                                <VenueCard data={item} isError={getRecommendedQuery.isError} loading={getRecommendedQuery.isFetching} onClick={()=>onClickViewVenue(item?.locationId)}/>
                            </Grid.Col>
                        ))}
                        </>
                        }
                    </Grid>
                </Container>
            </div>
            <div className={classes.sectionMatch}>
                <Container size={'xl'}>
                    <Text fw={800} align='center' color='#202020' lh={'normal'} className={classes.searchTitle}>Join Other People</Text>
                    <Space h={5}/>
                    <Text fw={500} align='center' color='#5D5D5D' lh={'normal'} className={classes.searchDesc}>Here are some matches available for you to play with others.</Text>
                    <Space h={25}/>
                    <Select
                        w={180}
                        size='sm'
                        placeholder="Select sport"
                        data={getSportSelectionQuery.data}
                        value={selectedSport}
                        onChange={setSelectedSport}
                        
                    />
                    <Space h={15}/>
                    <Carousel
                    withIndicators
                    // height={200}
                    slideSize={getCommunityGameQuery.isFetching || communityGames.length === 0 ? '100%' : '33.333333%'}
                    slideGap="md"
                    loop
                    align="start"
                    // slidesToScroll={3}
                    styles={{
                        indicator: {
                            backgroundColor: '#D9D9D9',
                            width: rem(10),
                            height: rem(10),
                            borderRadius: rem(10),
                            // transition: 'width 250ms ease',
                
                            '&[data-active]': {
                                backgroundColor: '#858585',
                            },
                        },
                        control: {
                            backgroundColor: '#DADADA',
                            // color: '#858585',
                            width: rem(20),
                            height: rem(20),
                            borderRadius: rem(40),
                            scale: 2
                        }
                    }}
                    breakpoints={[
                        { maxWidth: 'md', slideSize: '50%' },
                        { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                      ]}
                    >
                        {communityGames.length > 0 ?
                        <>
                        {communityGames.map((item, i)=>(
                            <Carousel.Slide key={`key${i}`}>
                                <MatchCard data={item} isError={getPublicGamesBySportMutation.isError} loading={getPublicGamesBySportMutation.isLoading} onClick={()=>onClickViewMatch(item?.scheduleId)}/>
                            </Carousel.Slide>
                        ))}
                        </>
                        :
                        <>
                        {
                            // getCommunityGameQuery.isFetching ? 
                            // <>
                            // {
                            // MATCH.map((item, i)=>(
                            //     <Carousel.Slide key={`key${i}`}>
                            //         <MatchCard data={item} isError={getPublicGamesBySportMutation.isError || communityGames.length === 0} loading={getPublicGamesBySportMutation.isLoading} onClick={()=>onClickViewMatch(item?.scheduleId)}/>
                            //     </Carousel.Slide>
                            // ))
                            // }
                            // </>
                            // :
                            <Carousel.Slide style={{position: 'relative'}}>
                                <MatchCard data={MATCH[0]} isError={getPublicGamesBySportMutation.isError || communityGames.length === 0} loading={getPublicGamesBySportMutation.isLoading}/>
                                <div style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                    {
                                    getPublicGamesBySportMutation.isLoading ? 
                                    <>
                                        <Loader size={34}/>
                                        <Text fw={'500'} mt={10}>Searching Game...</Text>
                                    </>
                                    :
                                    <>
                                        <IconCircleX size={34} color={'#F67421'}/>
                                        <Text fw={'500'} mt={10}>No community game found</Text>
                                    </>
                                    }
                                </div>
                            </Carousel.Slide>
                        }
                        </>
                        }
                    </Carousel>
                </Container>
            </div>
        </Container>
    )
}
