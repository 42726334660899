import React, { useState, useEffect } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Divider, Button, NumberInput, Select, Box, Image, Skeleton  } from '@mantine/core'
import { IconUserFilled, IconChevronLeft } from '@tabler/icons-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import ConfirmationModal from '../../components/General/ConfirmationModal';

import useLogout from '../../hooks/useLogout';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { convertToCurrency } from '../../utils/Currency';
import { formatMMMM_DD_DOW, formath_mm_a, formatDD_MM_YYYY } from '../../utils/DateTime';

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(25),
    },

    containerRow: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'initial',
        },
    },

    navContainer: {
        flex: 2, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px', marginRight: 25,
        [theme.fn.smallerThan('sm')]: {
            marginRight: 0,
            marginBottom: 25,
        },
    },

    contentContainer: {
        flex: 8, 
    },

    bookingDetailsContainter: {
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px',
        // [theme.fn.smallerThan('sm')]: {
        //     padding: '15px 20px',
        // },
    },

    amountDetailsContainer: {
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '20px 30px',
        [theme.fn.smallerThan('sm')]: {
            padding: '15px 20px',
        },
    },

    title: {
        fontSize: 15,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    row: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    profilePlaceholderSM: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 38, height: 38, borderRadius: 50
    },

    profilePlaceholderXL: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 100, height: 100, borderRadius: 100
    },

    amountBtn: {
        display: 'flex', width: 95, padding: '14px 0px', justifyContent: 'center', alignItems: 'center',border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: 5,
        cursor: 'pointer', color: '#2B2B2B',

        '&:hover': {
            color: '#F6851E',
        },
    },

    amountBtnActive: {
        border: '1px solid #F8A456',
        color: '#F6851E',
    },
}))

export default function ViewBooking() {
    const { classes, cx } = useStyles();
    const matches = useMediaQuery('(min-width: 48em)');
    const { id } = useParams();
    const navigate = useNavigate();
    const logout = useLogout();
    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const queryClient = useQueryClient()

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)

    const [status, setStatus] = useState({
        name: '---',
        bgColor: '#BFBFBF',
        color: '#FFFFFF',
    })

    const signOut = async () => {
        await logout();
        navigate('/');
    }
    

    const { data, isFetching, isError, isRefetching, refetch } = useQuery(
        "getBookingDetails",
        async ({ signal }) => (await axiosPrivate.post("/schedule/read-booking-details", {scheduleId: id, uid: auth.uid}, { signal })).data.schedule,
        {
            initialData: null,
            onSuccess: (res) => {
                // console.log(res)
                let status = res.uid !== auth.uid ? 'Joined' : 'Booked'
                let statusBGColor = '#F6851E'
                let statusColor = '#FFFFFF'
                if(!res?.isPaid){
                    status = 'Pending Payment'
                    statusBGColor = '#BFBFBF'
                    statusColor = '#FFFFFF'
                }
                if(res?.deleted){
                    status = 'Ended'
                    statusBGColor = '#BFBFBF'
                    statusColor = '#FFFFFF'
                }
                if(res?.isCancelled){
                    status = 'Cancelled'
                    statusBGColor = '#CA2A2A'
                    statusColor = '#FFFFFF'
                }
                setStatus(prev => {return { ...prev, name: status, bgColor: statusBGColor, color: statusColor }})
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const cancelBookingMutation = useMutation(
        (data) => axiosPrivate.post("/schedule/change-status", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries("getBookingDetails")
                queryClient.invalidateQueries("getUpcoming")
                queryClient.invalidateQueries("getHistory")
                notifications.show({
                    title: 'Success',
                    message: 'Successfully canceled booking',
                    color: 'green',
                })
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const leaveMatchMutation = useMutation(
        (data) => axiosPrivate.post("/schedule/leave-game", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries("getGameDetails")
                queryClient.invalidateQueries("getBookingDetails")
                queryClient.invalidateQueries("getUpcoming")
                queryClient.invalidateQueries("getHistory")
                notifications.show({
                    title: 'Success!',
                    message: 'Successfully leave game',
                    color: 'green',
                })
                navigate(`/profile/booking`, { replace: true });
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                // console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const onJoinLeavePressed = () => {
        setConfirmationModalVisible(false)
        leaveMatchMutation.mutate({uid: auth.uid, scheduleId: id})
    }

    const onClickConfirmedCancelBooking = () => {
        setConfirmationModalVisible(false)
        cancelBookingMutation.mutate({uid: auth.uid, scheduleId: id, isCancelled: true})
    }

    const onClickPayNow = () => {
        navigate(`/venue/payment/${id}`)
    }

    return (
        <Container size={'lg'} className={classes.root}>
            {/* <ConfirmationModal opened={confirmationModalVisible} onClose={()=>setConfirmationModalVisible(false)} text={'Cancel Booking?'} onClick={onClickConfirmedCancelBooking} approveBtnText='Confirm'/> */}
            <ConfirmationModal opened={confirmationModalVisible} onClose={()=>setConfirmationModalVisible(false)} text={`Are you sure you want to leave?`} onClick={onJoinLeavePressed} approveBtnText='Confirm'/>
            {!matches ? <>
            <Anchor component={Link} to={'/profile/booking'} color='brandSecondary'>
                <Group spacing={8}>
                    <IconChevronLeft size={22}/>
                    <Text fw={500} size={15}>Back</Text>
                </Group>
            </Anchor>
            <Space h={15}/>
            </> : null}
            <div className={classes.containerRow}>
                {/* Nav Section */}
                <div className={classes.navContainer}>
                    <Group spacing={10}>
                        <div className={classes.profilePlaceholderSM}>
                            <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                        </div>
                        <div>
                        <Text fw={600} color='#202020' lh={'normal'} size={13}>{auth?.fullname}</Text>
                        <Space h={4}/>
                        <Anchor component={Link} to={'/profile'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={11}>Edit Profile</Text>
                        </Anchor>
                        </div>
                    </Group>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        {/* <Anchor component={Link} to={'/profile/loyalty-points'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Loyalty Point</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/wallet'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Wallet</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/voucher'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Voucher</Text>
                        </Anchor>
                        <Space h={15}/> */}
                        <Anchor component={Link} to={'/profile/booking'} color='brandSecondary.6'>
                            <Text fw={500} lh={'normal'} size={12}>My Booking</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/membership'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Membership</Text>
                        </Anchor>
                    </div>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor onClick={signOut} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>Log Out</Text>
                        </Anchor>
                    </div>
                </div>
                {/* Content Section */}
                <div className={classes.contentContainer}>
                    {matches ? <>
                    <Anchor component={Link} to={'/profile/booking'} color='brandSecondary'>
                        <Group spacing={8}>
                            <IconChevronLeft size={22}/>
                            <Text fw={500} size={15}>Back</Text>
                        </Group>
                    </Anchor>
                    <Space h={15}/>
                    </> : null}
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                    <div className={classes.bookingDetailsContainter}>
                        <div style={{flex: 1,display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{display: 'flex'}}>
                                <div style={{border: '1px solid #E6E6E6', borderRadius: 5,}}>
                                    <Image width={75} height={75} src={data?.logo} fit='contain' alt="With default placeholder" radius={5} withPlaceholder />
                                </div>
                                <div style={{marginLeft: 15, marginTop: 8}}>
                                    <Text fw={600} lh={'normal'} color='#202020' size={16}>{data?.locationName}</Text>
                                    <Text fw={400} lh={'normal'} color='#5D5D5D' size={13}>{data?.city}</Text>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                <div style={{ padding: '5px 18px', borderRadius: 3, backgroundColor: `${status.bgColor}`, }}>
                                    <Text fw={600} lh={'normal'} color={`${status.color}`} size={10}>{status.name}</Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Skeleton>
                    <Space h={15}/>
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                    <div className={classes.bookingDetailsContainter}>
                        <Text fw={600} lh={'normal'} color='#202020' size={15} pb={15}>Booking Details</Text>
                        <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3} tt="uppercase">{data?.sport}</Text>
                        <Text fw={500} lh={'normal'} color='#202020' size={14} pb={3}>{data?.isPublic ? 'Public Match' : 'Private Match'}</Text>
                        <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>Hosted by {data?.host}</Text>
                        <Space h={15}/>
                        <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>LOCATION</Text>
                        <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{data?.locationName}</Text>
                        <Space h={15}/>
                        <Group spacing={matches ? 50 : 15}>
                            <div>
                                <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>DATE</Text>
                                <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{formatMMMM_DD_DOW(data?.startDatetime)}</Text>
                            </div>
                            <div>
                                <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>TIME</Text>
                                <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{formath_mm_a(data?.startDatetime)} - {formath_mm_a(data?.endDatetime)}</Text>
                            </div>
                            <div>
                                <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>COURT BOOKED</Text>
                                <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{data?.courtArrName ? data?.courtArrName.map((item, i)=>(data?.courtArrName.length === i+1 ?  item : item + ', ')) : null}</Text>
                            </div>
                        </Group>
                        {data?.isPublic ? <>
                        <Space h={15}/>
                        <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={10}>JOINED PLAYERS</Text>
                        
                        <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                        {data?.players.map((item, i) => (
                            <div key={i} style={{display: 'flex', alignItems: 'center', gap: 6}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                                    <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                                </div>
                                <Text fw={'500'} lh={'normal'} color='#5D5D5D' size={14}>{item?.fullname}</Text>
                            </div>
                        ))}
                        {/* {[...Array(data?.noOfPlayers)].map((x, i) => {
                            if(i>4)
                            return null
                            if(i===4 && data?.noOfPlayers > 5 && data?.players.length > 5)
                            return(
                                <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                                    <Text size={13} fw={700} color='#FFFFFF' lh={'normal'}>+{data?.players.length-i}</Text>
                                </div>
                            )
                            else if(data?.players.length > i)
                            return(
                                <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                                    <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                                </div>
                            )
                            else
                            return(
                                <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: '#D4D4D4', marginRight: 5}}>
                                    <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                                </div>
                            )
                        }
                        )} */}
                        </div>
                        </> 
                        : null}
                        <Space h={5}/>
                    </div>
                    </Skeleton>
                    <Space h={15}/>
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                    <div className={classes.bookingDetailsContainter}>
                        <Text fw={600} lh={'normal'} color='#202020' size={15} pb={15}>Payment Details</Text>
                        <Group position='apart'>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={14}>Paid By</Text>
                            <Text fw={500} lh={'normal'} color='#F6851E' size={14}>{data?.paymentMethod ? data?.paymentMethod : '-'}</Text>
                        </Group>
                        <Space h={15}/>
                        <Group position='apart'>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={14}>Court(s) Booked Subtotal</Text>
                            <Text fw={500} lh={'normal'} color='#5D5D5D' size={14}>RM {convertToCurrency(data?.price)}</Text>
                        </Group>
                        <Space h={5}/>
                        {data?.membershipDiscount > 0 &&
                        <>
                        <Group position='apart'>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={14}>Membership Discount</Text>
                            <Text fw={500} lh={'normal'} color='#5D5D5D' size={14}>- RM {convertToCurrency(data?.membershipDiscount)}</Text>
                        </Group>
                        <Space h={5}/>
                        </>
                        }
                        {data?.processing_fees > 0 &&
                        <>
                        <Group position='apart'>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={14}>Processing Fees</Text>
                            <Text fw={500} lh={'normal'} color='#5D5D5D' size={14}>RM {convertToCurrency(data?.processing_fees)}</Text>
                        </Group>
                        <Space h={5}/>
                        </>
                        }
                        <Group position='apart'>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={14}>Total Payment</Text>
                            <Text fw={500} lh={'normal'} color='#F6851E' size={14}>RM {convertToCurrency(data?.total_price)}</Text>
                        </Group>
                        <Space h={5}/>
                    </div>
                    </Skeleton>
                    
                    {data?.uid !== auth.uid ? 
                    <>
                    <Space h={15}/> 
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                        <div className={classes.bookingDetailsContainter}>
                            <Group position='right'>
                                {/* <Text fw={400} lh={'normal'} color='#5D5D5D' size={10}>Booking made on {formatDD_MM_YYYY(data?.createdAt)}</Text> */}
                                <Box w={matches ? 180 : 140}>
                                    <Button onClick={()=>setConfirmationModalVisible(true)} size='xs' color='brand' fullWidth loading={leaveMatchMutation.isLoading} disabled={data?.deleted || data?.isCancelled}>Leave Match</Button>
                                </Box>
                            </Group>
                        </div>
                    </Skeleton>
                    </>
                    : null } 
                    {status.name === 'Pending Payment' && data.uid === auth.uid ?<>
                    <Space h={15}/>
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                    <div className={classes.bookingDetailsContainter}>
                        <Group position='right'>
                            {/* <Text fw={400} lh={'normal'} color='#5D5D5D' size={10}>Booking made on {formatDD_MM_YYYY(data?.createdAt)}</Text> */}
                            <Box w={matches ? 180 : 140}>
                                <Button onClick={()=>onClickPayNow()} size='xs' color='brand' fullWidth loading={cancelBookingMutation.isLoading} disabled={data?.deleted || data?.isCancelled}>Pay Now</Button>
                            </Box>
                        </Group>
                    </div>
                    </Skeleton>
                    </>: null }
                    {/* <Space h={15}/> */}
                    {/* <Skeleton animate={!isError} visible={isFetching || isError}>
                    <div className={classes.bookingDetailsContainter}>
                        <Group position='apart'>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={10}>Booking made on {formatDD_MM_YYYY(data?.createdAt)}</Text>
                            <Box w={matches ? 180 : 140}>
                                <Button onClick={()=>setConfirmationModalVisible(true)} size='xs' color='brand' fullWidth loading={cancelBookingMutation.isLoading} disabled={data?.deleted || data?.isCancelled}>Cancel Booking</Button>
                            </Box>
                        </Group>
                    </div>
                    </Skeleton> */}
                </div>
            </div>
        </Container>
    )
}
