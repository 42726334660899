import React, { useState, useEffect } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Select, Image, Divider, Button, Box, Radio, ScrollArea, Textarea, Skeleton, Loader } from '@mantine/core'
import { IconChevronLeft, IconCalendar, } from '@tabler/icons-react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DatePickerInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { useQuery, useMutation } from 'react-query';
import { notifications } from '@mantine/notifications';
import moment from 'moment';
import momenttz from 'moment-timezone';

import { getCurrentDateTime } from '../../utils/DateTime';

import VoucherModal from '../../components/Venue/VoucherModal';
import CourtCard from '../../components/Venue/CourtCard';

import { convertToCurrency } from '../../utils/Currency';
import { formatDD_MM_YYYY, formatDOW, formath, formatDOW_NextDay, formatm } from '../../utils/DateTime';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';

const MAX_DURATION = 6;

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(25),
        [theme.fn.smallerThan('sm')]: {
            paddingBottom: rem(10),
        },
    },

    sectionContainer: {
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        padding: '20px 30px',
        borderRadius: 5,
        backgroundColor: '#FFFFFF',
        [theme.fn.smallerThan('sm')]: {
            // boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
            padding: '20px 20px',
            // backgroundColor: '#FAFAFA',
        },
    },

    fontSM: {
        fontSize: 12,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 11,
        },
    },

    fontLG: {
        fontSize: 20,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 16,
        },
    },

    fixedBottom: {
        position: 'fixed',
        height: 80,
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        zIndex: 100,
        padding: '14px 14px',
        [theme.fn.largerThan('sm')]: {
            display: 'none'
        },
    },

    hideSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none'
        },
    }

}))

const COURT = [
    {
        courtId: '1',
        name: '1A',
        price: 20,
    },
    {
        courtId: '2',
        name: '1B',
        price: 20,
    },
    {
        courtId: '3',
        name: '1C',
        price: 20,
    }
]

export default function BookVenue() {
    const navigate = useNavigate();
    const { classes, cx } = useStyles();
    const { id } = useParams()
    const currentDate = moment().tz("Asia/Kuala_Lumpur").toDate()
    // const matches = useMediaQuery('(min-width: 48em)');
    const [opened, { open, close }] = useDisclosure(false);
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth()
    const [searchParams] = useSearchParams();
    const getSport = searchParams.get("sport")
    const getDate = searchParams.get("date")
    const getTime = searchParams.get("time")
    const getDuration = searchParams.get("duration")

    const [openingHours, setOpeningHours] = useState([])
    const [sports, setSports] = useState([])

    
    const [timeSelection, setTimeSelection] = useState([])
    const [durationSelection, setDurationSelection] = useState([])
    const [courtsSelection, setCourtsSelection] = useState([])


    const [date, setDate] = useState(currentDate)

    const [priceDetails, setPriceDetails] = useState({
        subtotal: 0,
        membership: 0,
        admin_fees: 0,
        total: 0,
    })

    // const [selectedVoucher, setSelectedVoucher] = useState('');

    const form = useForm({
        initialValues: {
            sport: null,
            // date: getCurrentDateTime(),
            time: '',
            duration: '',
            courts: '[]',
            matchType: 'Private Match',
            noOfPlayers: 0,
            description: '',
            // paymentType: '',
            voucher: '',
        },
    
        validate: {
            sport: (value) => (value === null ? 'Please select sport' : null),
            // date: (value) => (value.trim().length < 1 ? 'Please select date' : null),
            time: (value) => (value.trim().length < 1 ? 'Please select time' : null),
            duration: (value) => (value.trim().length < 1 ? 'Please select duration' : null),
            courts: (value) => (JSON.parse(value).length < 1 ? 'Please select court(s)' : null),
            matchType: (value) => (value.trim().length < 1 ? 'Please select match type' : null),
            noOfPlayers: (value, values) => (value < 1 && values.matchType === 'Public Match' ? 'Please select number of players' : null),
            description: (value, values) => (value.trim().length < 1 && values.matchType === 'Public Match' ? 'Please fill in the description' : null),
            // paymentType: (value) => (value.trim().length < 1 ? 'Please select payment type' : null),
        },
    });

    const onSelectCourt = (item) => {
        // let tempSelect = JSON.parse(selectedCourts)
        let tempSelect = JSON.parse(form.values.courts)
        if(tempSelect.some((obj)=>{ return obj.courtId === item?.courtId })){
            tempSelect = tempSelect.filter(function(obj) { return obj.courtId !== item?.courtId; })
        }
        else{
            tempSelect.push(item)
        }
        // setSelectedCourts(JSON.stringify(tempSelect))
        form.setFieldValue('courts', JSON.stringify(tempSelect))
    }

    useEffect(()=>{
        const generateTime = () => {
            const opening =  openingHours.find(x => x.day === formatDOW(date))
            const openingNextDay = openingHours.find(x => x.day === formatDOW_NextDay(date))
            let timeArr = []
            let openTime = opening?.open
            let closeTime = opening?.close-0.5
            if(formatDD_MM_YYYY(currentDate) === formatDD_MM_YYYY(date)){
                openTime = parseInt(formath(currentDate)) + 0.5
                if(parseInt(formatm(currentDate)) >= 30){
                    openTime = openTime + 0.5
                }
            }
            if(opening?.close === 24 && openingNextDay?.open === 0){
                closeTime = opening?.close
            }
            for (let i = openTime; i < closeTime; i=i+0.5) {
                timeArr.push({
                    label: `${i < 10 ? '0'+Math.floor(i) : i >= 24 ? '0' + (Math.floor(i)-24) : Math.floor(i)}:${(i % 1).toFixed(1) === '0.5' ? '30' : '00'}`,
                    value: `${i}`
                })
            }
            setTimeSelection(timeArr)
        }
        if(openingHours.length > 0) generateTime()
    },[openingHours, date])

    useEffect(()=>{
        const generateDuration = () => {
            const selectedTimeFloat = parseFloat(form.values.time)
            const opening = openingHours.find(x => x.day === formatDOW(date))
            const openingNextDay = openingHours.find(x => x.day === formatDOW_NextDay(date))
            let durationArr = []
            let hour = 1;
            if(opening?.close === 24 && openingNextDay?.open === 0){
                for (let i = 1; i < openingNextDay?.close; i++) {
                    durationArr.push({
                        label: `${Math.floor(hour)} Hour${Math.floor(hour)>1 ? 's' : ''} ${(hour % 1).toFixed(1) === '0.5' ? '30 Minutes' : ''}`,
                        value: `${hour}`
                    })
                    hour = hour + 0.5;
                    if(hour>MAX_DURATION) break;
                }
            }else{
                for (let i = selectedTimeFloat; i < opening?.close-0.5; i=i+0.5) {
                    durationArr.push({
                        label: `${Math.floor(hour)} Hour${Math.floor(hour)>1 ? 's' : ''} ${(hour % 1).toFixed(1) === '0.5' ? '30 Minutes' : ''}`,
                        value: `${hour}`
                    })
                    hour = hour + 0.5;
                    if(hour>MAX_DURATION) break;
                }
            }
            setDurationSelection(durationArr)
        }
        if(form.values.time)
            generateDuration()
    },[openingHours, form.values.time])
    useEffect(()=>{
        form.setFieldValue('time', '')
        form.setFieldValue('duration', '')
        form.setFieldValue('courts', '[]')
        setDurationSelection([])
        setPriceDetails(prev => {return{
            ...prev,
            subtotal: 0,
            total: 0,
        }})
        setCourtsSelection([])
    },[date,form.values.sport])
    useEffect(()=>{
        form.setFieldValue('duration', '')
        form.setFieldValue('courts', '[]')
        setPriceDetails(prev => {return{
            ...prev,
            subtotal: 0,
            total: 0,
        }})
        setCourtsSelection([])
    },[form.values.time])
    useEffect(()=>{
        form.setFieldValue('courts', '[]')
        setPriceDetails(prev => {return{
            ...prev,
            subtotal: 0,
            total: 0,
        }})
        if(form.values.duration){
            setCourtsSelection([])
            getAvailableCourts()
        }
    },[form.values.duration])
    //set price
    useEffect(()=>{
        let courtsArr = JSON.parse(form.values.courts)
        if(courtsArr.length > 0){
            let price = 0;
            for(let c of courtsArr){
                price = price+c.price 
            }
            // price = price * parseInt(getValues('duration'))
            setPriceDetails(prev => {return{
                ...prev,
                subtotal: price,
                total: (price - prev?.membership + prev?.admin_fees) > 0 ? (price - prev?.membership + prev?.admin_fees) : 0,
            }})
        }
    },[form.values.courts])

    const { data, isFetching, isError, isRefetching, isLoading, refetch, isSuccess } = useQuery(
        "getVenueDetails",
        async ({ signal }) => (await axiosPrivate.post("/locations/read-by-location-id", {locationId: id, uid: auth?.uid }, { signal })).data.location,
        {
            enabled: auth ? true : false,
            initialData: null,
            onSuccess: (res) => {
                setOpeningHours(JSON.parse(res?.opening_hours ? res?.opening_hours : '[]'))
                setSports(res?.sports)
                // setSelectedSport(res?.sports[0] ? res?.sports[0].value : '0')
                form.setFieldValue('sport', res?.sports[0] ? res?.sports[0].value : '0')
                if(res?.isMember){
                    setPriceDetails(prev => {return{
                        ...prev,
                        membership: res?.isMember ? res?.membershipDiscount : 0,
                        admin_fees: res?.admin_fees ? res?.admin_fees : 0,
                    }})
                }
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const getAvailableCourtsMutation = useMutation(
        (data) => axiosPrivate.post("/court/get-available-courts", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res?.data?.courts)
                setCourtsSelection(res?.data?.courts)
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const checkoutMutation = useMutation(
        (data) => axiosPrivate.post("/schedule/book-court", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                console.log('Proceed to payment page')
                // navigate(`/venue/booking-status/${'success'}`, { replace: true });
                navigate(`/venue/payment/${res.data.scheduleId}`, { replace: true });
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const getAvailableCourts = () => {
        getAvailableCourtsMutation.mutate({ locationId: id, type: form.values.sport, date: date, time: form.values.time, duration: form.values.duration })
    }
    const onClickCheckout = (data) => {
        
        let courts = JSON.parse(data.courts);
        let courtArr = []
        for(let item of courts){
            courtArr.push(item.courtId)
        }
        courtArr = JSON.stringify(courtArr)
        const fields = {
            uid: auth.uid,
            locationId: id,
            type: data.sport,
            date: date,
            time: data.time,
            duration: data.duration,
            courtArr,
            isPublic: data.matchType === 'Private Match' ? 0 : 1,
            noOfPlayers: data.matchType === 'Private Match' ? 0 : data.noOfPlayers,
            players: data.matchType === 'Private Match' ? JSON.stringify([]) : JSON.stringify([auth.uid]),
            description: data.description.trim(),
            voucherId: '',
            price: priceDetails.subtotal,
            membershipDiscount: priceDetails.membership,
            processing_fees: priceDetails.admin_fees,
            total_price: priceDetails.total,
        }
        checkoutMutation.mutate(fields)
        
    }

    const onClickBack = () => {
        navigate(-1)
    }

    const [toGetTime, setToGetTime] = useState(false)
    const [toGetDuration, setToGetDuration] = useState(false)

    useEffect(()=>{
        const setGetInfo = async () => {
            if(getSport){
                // setSelectedSport(parseInt(getSport))
                form.setFieldValue('sport', parseInt(getSport))
            }
            if(getDate){
                if(getDate < moment(currentDate).format(("YYYY-MM-DD"))) return
                setDate(moment(getDate).toDate())
                setToGetTime(true)
            }
        }
        if(!isFetching && isSuccess){
            setGetInfo()
        }
    },[getSport, getDate, getTime, getDuration, isSuccess, isFetching])

    useEffect(()=>{
        const setGetInfo = async () => {
            if(getTime){
                await new Promise(resolve => setTimeout(resolve, 150));
                if(timeSelection.some(time => time.value === getTime)){
                    // setSelectedTime(getTime)
                    form.setFieldValue('time', getTime)
                    setToGetDuration(true)
                }
                else{
                    return
                }
            }
            // if(getDuration){
            //     await new Promise(resolve => setTimeout(resolve, 150));
            //     if(durationSelection.some(duration => duration.value === getDuration)){
            //         setSelectedDuration(getDuration)
            //     }
            // }
            setToGetTime(false)
        }
        if(toGetTime && timeSelection.length>0){
            setGetInfo()
        }
    },[toGetTime, timeSelection])

    useEffect(()=>{
        const setGetInfo = async () => {
            if(getDuration){
                await new Promise(resolve => setTimeout(resolve, 150));
                if(durationSelection.some(duration => duration.value === getDuration)){
                    // setSelectedDuration(getDuration)
                    form.setFieldValue('duration', getDuration)
                }
            }
            setToGetDuration(false)
        }
        if(toGetDuration && durationSelection.length>0){
            setGetInfo()
        }
    },[toGetDuration, durationSelection])

    return (
        <>
        {/* <VoucherModal vouchers={VOUCHERS} selectedVoucher={selectedVoucher} setSelectedVoucher={setSelectedVoucher} opened={opened} onClose={close}/> */}
        <Container size={'sm'} className={classes.root}>
            <Anchor onClick={onClickBack} color='brandSecondary'>
                <Group spacing={8}>
                    <IconChevronLeft size={22}/>
                    <Text fw={500} size={15}>Go Back</Text>
                </Group>
            </Anchor>
            <Space h={25}/>
            <form onSubmit={form.onSubmit((values) => onClickCheckout(values))}>
                <Skeleton animate={!isError} visible={isFetching || isError}>
                <div className={classes.sectionContainer}>
                    <div style={{display: 'flex'}}>
                        <div style={{borderRadius: 5, overflow: 'hidden'}}>
                            <Image width={100} height={100} src={data?.logo} fit='contain' alt="With default placeholder" withPlaceholder />
                        </div>
                        <Space w={20}/>
                        <div>
                            <Text fw={600} color='#202020' lh={'normal'} size={16} pb={4}>{data?.name}</Text>
                            <Text fw={700} color='#5D5D5D' lh={'normal'} size={13} >{data?.city}</Text>
                        </div>
                    </div>
                </div>
                </Skeleton>
                <Space h={15}/>
                <Skeleton animate={!isError} visible={isFetching || isError}>
                <div className={classes.sectionContainer}>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select sports</Text>
                    <Select
                        placeholder="Select sport"
                        data={sports}
                        // onChange={setSelectedSport}
                        // value={selectedSport}
                        readOnly={checkoutMutation.isLoading}
                        {...form.getInputProps('sport')}
                    />
                    <Space h={15}/>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select a date</Text>
                    <DatePickerInput
                        valueFormat="DD/MM/YYYY"
                        rightSection={<IconCalendar size="1.1rem" stroke={1.5} color='#BABABA'/>}
                        placeholder="Select date"
                        onChange={setDate}
                        value={date}
                        minDate={currentDate}
                        readOnly={checkoutMutation.isLoading}
                    />
                    <Space h={15}/>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select a start time</Text>
                    <Select
                        placeholder="Select time"
                        data={timeSelection}
                        // onChange={setSelectedTime}
                        // value={selectedTime}
                        readOnly={checkoutMutation.isLoading}
                        {...form.getInputProps('time')}
                    />
                    <Space h={15}/>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select duration</Text>
                    <Select
                        placeholder="Select duration"
                        data={durationSelection}
                        // onChange={setSelectedDuration}
                        // value={selectedDuration}
                        readOnly={checkoutMutation.isLoading}
                        {...form.getInputProps('duration')}
                    />
                    <Divider my={25}/>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} >Available Court(s)</Text>
                    <ScrollArea h={250} type="always" style={{border: '1px solid rgba(0, 0, 0, 0.15)', borderLeftWidth: 0, borderRightWidth: 0, borderColor: form.errors.courts ? '#fa5252' :'rgba(0, 0, 0, 0.15)'}}>
                    {courtsSelection.length > 0 ?
                    <div style={{display: 'flex', flexDirection: 'column', gap: 8, paddingTop: 8, paddingBottom: 8}}>

                    {courtsSelection.map((item, i)=>(
                        <CourtCard key={i} data={item} select={form.values.courts} onSelect={()=>onSelectCourt(item)} disable={checkoutMutation.isLoading}/>
                    ))
                    }
                    </div>
                    :
                    <div style={{display: 'flex', height: 245, justifyContent: 'center', alignItems: 'center', backgroundColor: '#FAFAFA'}}>
                        {form.values.time && form.values.duration ?
                            <>
                            {getAvailableCourtsMutation.isLoading ?
                                <Loader size="sm" variant="dots" />
                            :
                                <Text size={14}>No available courts at this time slot</Text>
                            }
                            </>
                        :
                            <Text size={14}>Please select time and duration</Text>
                        }
                        
                    </div>
                    }
                    </ScrollArea>
                    {form.errors.courts ?
                        <Text fw={400} color='#fa5252' lh={'normal'} size={12} mt={2}>{form.errors.courts}</Text>
                        : null
                    }
                    
                </div>
                </Skeleton>
                <Space h={15}/>
                <Skeleton animate={!isError} visible={isFetching || isError} style={{zIndex: 9}}>
                <div className={classes.sectionContainer}>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select match type</Text>
                    <Select
                        placeholder="Select match type"
                        data={[
                            { value: 'Private Match', label: 'Private Match' },
                            { value: 'Public Match', label: 'Public Match (Community)' },
                        ]}
                        {...form.getInputProps('matchType')}
                        // onChange={setSelectedMatchType}
                        // value={selectedMatchType}
                        readOnly={checkoutMutation.isLoading}
                        // zIndex={99999999999}
                    />
                    {form.values.matchType === 'Public Match' ?
                    <>
                    <Space h={15}/>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Number of Players</Text>
                    <Select
                        placeholder="Select number of players"
                        data={[
                            { value: '2', label: '2' },
                            { value: '4', label: '4' },
                            { value: '6', label: '6' },
                            { value: '8', label: '8' },
                            { value: '10', label: '10' },
                        ]}
                        // onChange={setNoOfPlayers}
                        // value={noOfPlayers}
                        readOnly={checkoutMutation.isLoading}
                        {...form.getInputProps('noOfPlayers')}
                    />
                    <Space h={15}/>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Description</Text>
                    <Textarea
                        placeholder="Description"
                        minRows={4}
                        maxRows={6}
                        {...form.getInputProps('description')}
                        // onChange={(event) => setDescription(event.currentTarget.value)}
                        // value={description}
                        readOnly={checkoutMutation.isLoading}
                    />
                    </>
                    : null
                    }
                </div>
                </Skeleton>
                <Space h={15}/>
                <Skeleton animate={!isError} visible={isFetching || isError}>
                <div className={classes.sectionContainer}>
                    <Text fw={600} color='#202020' lh={'normal'} size={15} pb={15}>Payment Details</Text>
                    <Group position="apart" pb={5}>
                        <Text fw={400} color='#5D5D5D' lh={'normal'} size={14}>Court(s) Booked Subtotal</Text>
                        <Text fw={500} color='#5D5D5D' lh={'normal'} size={14}>RM{convertToCurrency(priceDetails?.subtotal)}</Text>
                    </Group>
                    {priceDetails.membership > 0 ?
                    <Group position="apart" pb={5}>
                        <Text fw={400} color='#5D5D5D' lh={'normal'} size={14}>Membership Discount</Text>
                        <Text fw={500} color='#5D5D5D' lh={'normal'} size={14}>- RM{convertToCurrency(priceDetails?.membership)}</Text>
                    </Group>
                    : null
                    }
                    {priceDetails.admin_fees > 0 ?
                    <Group position="apart" pb={5}>
                        <Text fw={400} color='#5D5D5D' lh={'normal'} size={14}>Processing Fees</Text>
                        <Text fw={500} color='#5D5D5D' lh={'normal'} size={14}>RM{convertToCurrency(priceDetails?.admin_fees)}</Text>
                    </Group>
                    : null
                    }
                    <Group position="apart">
                        <Text fw={400} color='#5D5D5D' lh={'normal'} size={14}>Total Payment</Text>
                        <Text fw={500} color='#F6851E' lh={'normal'} size={14}>RM{convertToCurrency(priceDetails?.total)}</Text>
                    </Group>
                </div>
                </Skeleton>
                <Space h={15}/>
                <Skeleton animate={!isError} visible={isFetching || isError}>
                <div className={cx(classes.sectionContainer, classes.hideSM)}>
                    <Group position="apart">
                        <div>
                            <Text fw={500} color='#202020' lh={'normal'} size={14}>Total</Text>
                            <Text fw={600} color='#202020' lh={'normal'} size={20}>RM{convertToCurrency(priceDetails?.total)}</Text>
                        </div>
                        <Box w={200}>
                            <Button loading={checkoutMutation.isLoading} type="submit" fullWidth>Checkout</Button>
                        </Box>
                    </Group>
                </div>
                </Skeleton>
                <div className={classes.fixedBottom}>
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                    <Group position="apart">
                        <div>
                            <Text fw={500} color='#202020' lh={'normal'} size={14}>Total</Text>
                            <Text fw={600} color='#202020' lh={'normal'} size={16}>RM{convertToCurrency(priceDetails?.total)}</Text>
                        </div>
                        <Box w={150}>
                            <Button loading={checkoutMutation.isLoading} type="submit" fullWidth>Checkout</Button>
                        </Box>
                    </Group>
                    </Skeleton>
                </div>
            </form>
        </Container>
        </>
    )
}
