import React from 'react'
import { createStyles, Container, TypographyStylesProvider, rem  } from '@mantine/core'

import { TERMS_AND_CONDITIONS } from '../../utils/Terms'

const HEADER_HEIGHT = rem(70);
const HEADER_HEIGHT_MOBILE = rem(55);

const useStyles = createStyles((theme) => ({
  
    docsWrapper: {
        backgroundColor: '#FAFAFA',
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
        [theme.fn.smallerThan('sm')]: {
            minHeight: `calc(100vh - ${rem(HEADER_HEIGHT_MOBILE)})`,
        },
    },

    docsContainer: {
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
        padding: 40,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15);',
        [theme.fn.smallerThan('xs')]: {
            padding: 20,
        },
        [theme.fn.smallerThan('sm')]: {
            minHeight: `calc(100vh - ${rem(HEADER_HEIGHT_MOBILE)})`,
        },
    }

}));

export default function TermsConditions() {
    const { classes } = useStyles();
    return (
        <div className={classes.docsWrapper}>
            <Container size={'sm'} className={classes.docsContainer}>
                <TypographyStylesProvider>
                    <div dangerouslySetInnerHTML={{ __html: TERMS_AND_CONDITIONS }} />
                </TypographyStylesProvider>
            </Container>
        </div>
    )
}
