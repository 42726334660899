import React, { useState, useEffect, useRef } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Select, Image, Divider, Button, Box, Radio, ScrollArea, Textarea, Skeleton, Loader } from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { useQuery, useMutation } from 'react-query';
import { notifications } from '@mantine/notifications';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { IconChevronLeft } from '@tabler/icons-react';


import VoucherModal from '../../components/Venue/VoucherModal';
import CourtCard from '../../components/Venue/CourtCard';

import { convertToCurrency } from '../../utils/Currency';
import { formatMMMM_DD_DOW, formath_mm_a } from '../../utils/DateTime';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';


const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(25),
        [theme.fn.smallerThan('sm')]: {
            paddingBottom: rem(10),
        },
    },

    sectionContainer: {
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        padding: '20px 30px',
        borderRadius: 5,
        backgroundColor: '#FFFFFF',
        [theme.fn.smallerThan('sm')]: {
            // boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
            padding: '20px 20px',
            // backgroundColor: '#FAFAFA',
        },
    },

    fontSM: {
        fontSize: 12,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 11,
        },
    },

    fontLG: {
        fontSize: 20,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 16,
        },
    },

    fixedBottom: {
        position: 'fixed',
        height: 80,
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        zIndex: 100,
        padding: '14px 14px',
        [theme.fn.largerThan('sm')]: {
            display: 'none'
        },
    },

    hideSM: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none'
        },
    }

}))

const FPX_BANK = [
    {
        label: 'Maybank2U',
        value: 'fpx_mb2u',
    },
    {
        label: 'CIMB Clicks',
        value: 'fpx_cimbclicks',
    },
    {
        label: 'Public Bank',
        value: 'fpx_pbb',
    },
    {
        label: 'RHB Now',
        value: 'fpx_rhb',
    },
    {
        label: 'AmBank',
        value: 'fpx_amb',
    },
    {
        label: 'myBSN',
        value: 'fpx_bsn',
    },
    {
        label: 'Bank Rakyat',
        value: 'fpx_bkrm',
    },
    {
        label: 'UOB',
        value: 'fpx_uob',
    },
    {
        label: 'Affin Bank',
        value: 'fpx_abb',
    },
    {
        label: 'Bank Islam',
        value: 'fpx_bimb',
    },
    {
        label: 'HSBC Online',
        value: 'fpx_hsbc',
    },
    {
        label: 'Standard Chartered Bank',
        value: 'fpx_scb',
    },
    {
        label: 'Kuwait Finance House',
        value: 'fpx_kfh',
    },
    {
        label: 'Bank Mualamat',
        value: 'fpx_bmmb',
    },
    {
        label: 'OCBC Online',
        value: 'fpx_ocbc',
    },
    {
        label: 'Alliance Bank',
        value: 'fpx_abmb',
    },
    {
        label: 'Hong Leong',
        value: 'fpx_hlb',
    },
]


export default function Payment() {
    const navigate = useNavigate();
    const { classes, cx } = useStyles();
    const { id } = useParams()
    const matches = useMediaQuery('(min-width: 48em)');
    const currentDate = moment().tz("Asia/Kuala_Lumpur").toDate()
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth()
    const buttonRef = useRef(null);


    const [date, setDate] = useState(currentDate)

    const [priceDetails, setPriceDetails] = useState({
        subtotal: 0,
        membership: 0,
        admin_fees: 0,
        total: 0,
    })

    // const [selectedVoucher, setSelectedVoucher] = useState('');

    const [paymentDetails, setPaymentDetails] = useState(null)
    const [isPaymentLoading, setIsPaymentLoading] = useState(false)

    const form = useForm({
        initialValues: {
            paymentType: '',
            channel: '',
            voucher: '',
        },
    
        validate: {
            paymentType: (value) => (value.trim().length < 1 ? 'Please select payment type' : null),
            channel: (value, values) => (values.paymentType ==='fpx' && value.trim().length < 1 ? 'Please select payment type' : null),
        },
    });

    const { data, isFetching, isError, isRefetching, isLoading, refetch, isSuccess } = useQuery(
        "getPaymentDetails",
        async ({ signal }) => (await axiosPrivate.post("/payment/get-payment-details", {scheduleId: id, uid: auth?.uid }, { signal })).data.paymentDetails,
        {
            enabled: auth ? true : false,
            initialData: null,
            onSuccess: (res) => {
                // console.log(res)
                setPaymentDetails(res.razerPayDetails)
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const checkAvailabilityMutation = useMutation(
        (data) => axiosPrivate.post("/payment/check-availability-before-payment", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                buttonRef.current.click();
            },
            onError:(err) => {
                setIsPaymentLoading(false)
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );


    const onClickCheckout = (data) => {
        //check courts availability
        alert("Payment on testing")
        // setIsPaymentLoading(true)
        // checkAvailabilityMutation.mutate({scheduleId: id})
    }

    const onClickBack = () => {
        navigate(-1)
    }


    return (
        <>
        {/* <VoucherModal vouchers={VOUCHERS} selectedVoucher={selectedVoucher} setSelectedVoucher={setSelectedVoucher} opened={opened} onClose={close}/> */}
        <Container size={'sm'} className={classes.root}>
            <Anchor onClick={onClickBack} color='brandSecondary'>
                <Group spacing={8}>
                    <IconChevronLeft size={22}/>
                    <Text fw={500} size={15}>Go Back</Text>
                </Group>
            </Anchor>
            <Space h={25}/>
            <form onSubmit={form.onSubmit((values) => onClickCheckout(values))}>
                <Skeleton animate={!isError} visible={isFetching || isError}>
                <div className={classes.sectionContainer}>
                    <Text fw={600} lh={'normal'} color='#202020' size={15} pb={15}>Booking Details</Text>
                    <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3} tt="uppercase">{data?.sport}</Text>
                    <Text fw={500} lh={'normal'} color='#202020' size={14} pb={3}>{data?.isPublic ? 'Public Match' : 'Private Match'}</Text>
                    <Space h={15}/>
                    <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>LOCATION</Text>
                    <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{data?.locationName}</Text>
                    <Space h={15}/>
                    <Group spacing={matches ? 50 : 15}>
                        <div>
                            <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>DATE</Text>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{formatMMMM_DD_DOW(data?.startDatetime)}</Text>
                        </div>
                        <div>
                            <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>TIME</Text>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{formath_mm_a(data?.startDatetime)} - {formath_mm_a(data?.endDatetime)}</Text>
                        </div>
                        <div>
                            <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>COURT TO BOOK</Text>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{data?.courtArrName ? data?.courtArrName.map((item, i)=>(data?.courtArrName.length === i+1 ?  item : item + ', ')) : null}</Text>
                        </div>
                    </Group>
                    
                </div>
                </Skeleton>
                <Space h={15}/>
                <Skeleton animate={!isError} visible={isFetching || isError}>
                <div className={classes.sectionContainer}>
                    <Text fw={600} color='#202020' lh={'normal'} size={15} pb={15}>Payment Details</Text>
                    <Group position="apart" pb={5}>
                        <Text fw={400} color='#5D5D5D' lh={'normal'} size={14}>Court(s) Booked Subtotal</Text>
                        <Text fw={500} color='#5D5D5D' lh={'normal'} size={14}>RM{convertToCurrency(data?.price)}</Text>
                    </Group>
                    {data?.membershipDiscount > 0 ?
                    <Group position="apart" pb={5}>
                        <Text fw={400} color='#5D5D5D' lh={'normal'} size={14}>Membership Discount</Text>
                        <Text fw={500} color='#5D5D5D' lh={'normal'} size={14}>- RM{convertToCurrency(data?.membershipDiscount)}</Text>
                    </Group>
                    : null
                    }
                    {data?.processing_fees > 0 ?
                    <Group position="apart" pb={5}>
                        <Text fw={400} color='#5D5D5D' lh={'normal'} size={14}>Processing Fees</Text>
                        <Text fw={500} color='#5D5D5D' lh={'normal'} size={14}>RM{convertToCurrency(data?.processing_fees)}</Text>
                    </Group>
                    : null
                    }
                    <Group position="apart">
                        <Text fw={400} color='#5D5D5D' lh={'normal'} size={14}>Total Payment</Text>
                        <Text fw={500} color='#F6851E' lh={'normal'} size={14}>RM{convertToCurrency(data?.total_price)}</Text>
                    </Group>
                </div>
                </Skeleton>
                <Space h={15}/>
                <Skeleton animate={!isError} visible={isFetching || isError} style={{zIndex: 8}}>
                <div className={classes.sectionContainer}>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select preferred payment method</Text>
                    <Select
                        placeholder="Select payment method"
                        data={[
                            { value: 'creditAN', label: 'Credit / Debit Card' },
                            { value: 'fpx', label: 'Online Banking (FPX)' },
                        ]}
                        // onChange={setPaymentType}
                        // value={paymentType}
                        readOnly={checkAvailabilityMutation.isLoading || isPaymentLoading}
                        {...form.getInputProps('paymentType')}
                    />
                    
                    {form.values.paymentType === 'fpx' ? 
                    <>
                    <Space h={15}/>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select a bank</Text>
                    <Select
                        // label="Bank"
                        placeholder="Select a bank"
                        data={FPX_BANK}
                        readOnly={checkAvailabilityMutation.isLoading || isPaymentLoading}
                        {...form.getInputProps('channel')}
                    /> 
                    </>
                    : null}
                    {/* <Space h={15}/>
                    <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select a voucher</Text>
                    <TextInput
                        onClick={open}
                        placeholder="Select a voucher"
                        readOnly
                        {...form.getInputProps('voucher')}
                        styles={() => ({
                            input: {
                                cursor: 'pointer'
                            }
                        })}
                    /> */}
                </div>
                </Skeleton>
                <Space h={15}/>
                <Skeleton animate={!isError} visible={isFetching || isError}>
                <div className={cx(classes.sectionContainer, classes.hideSM)}>
                    <Group position="apart">
                        <div>
                            <Text fw={500} color='#202020' lh={'normal'} size={14}>Total</Text>
                            <Text fw={600} color='#202020' lh={'normal'} size={20}>RM{convertToCurrency(data?.total_price)}</Text>
                        </div>
                        <Box w={200}>
                            <Button loading={checkAvailabilityMutation.isLoading || isPaymentLoading} type="submit" fullWidth disabled={!(form.values.paymentType === 'fpx' ? form.values.channel : form.values.paymentType)}>Pay Now</Button>
                        </Box>
                    </Group>
                </div>
                </Skeleton>
                <div className={classes.fixedBottom}>
                    <Skeleton animate={!isError} visible={isFetching || isError}>
                    <Group position="apart">
                        <div>
                            <Text fw={500} color='#202020' lh={'normal'} size={14}>Total</Text>
                            <Text fw={600} color='#202020' lh={'normal'} size={16}>RM{convertToCurrency(data?.total_price)}</Text>
                        </div>
                        <Box w={150}>
                            <Button loading={checkAvailabilityMutation.isLoading || isPaymentLoading} type="submit" fullWidth disabled={!(form.values.paymentType === 'fpx' ? form.values.channel : form.values.paymentType)}>Pay Now</Button>
                        </Box>
                    </Group>
                    </Skeleton>
                </div>
                <Button ref={buttonRef} style={{display: 'none'}} type="button" id="myPay" data-toggle="molpayseamless"
                    data-mpsmerchantid={paymentDetails?.mp_merchant_ID}
                    data-mpschannel={form.values.paymentType === 'fpx' ? form.values.channel : form.values.paymentType}
                    data-mpsamount={paymentDetails?.mp_amount}
                    data-mpsorderid={paymentDetails?.mp_order_ID}
                    data-mpsbill_name={paymentDetails?.mp_bill_name}
                    data-mpsbill_email={paymentDetails?.mp_bill_email}
                    data-mpsbill_mobile={paymentDetails?.mp_bill_mobile}
                    data-mpscurrency={paymentDetails?.mp_currency}
                    data-mpsbill_desc={paymentDetails?.mp_bill_description}
                    data-mpsreturnurl={`${window.location.origin}/venue/payment-status/${id}`}
                    data-mpscancelurl={`${window.location.origin}/venue/payment/${id}`}
                    data-mpsvcode={data?.vcode}
                    disabled={!(form.values.paymentType === 'fpx' ? form.values.channel : form.values.paymentType)}
                >
                    Pay Now
                </Button>
            </form>
        </Container>
        </>
    )
}

