import React, { useState } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Divider, Button, Image, Box, ScrollArea  } from '@mantine/core'
import { IconUserFilled } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';

import useLogout from '../../hooks/useLogout';

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(15),
    },

    containerRow: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'initial',
        },
    },

    navContainer: {
        flex: 2, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px', marginRight: 25,
        [theme.fn.smallerThan('sm')]: {
            marginRight: 0,
            marginBottom: 25,
        },
    },

    contentContainer: {
        flex: 8, 
    },

    pointsContainter: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px',
        [theme.fn.smallerThan('sm')]: {
            padding: '15px 20px',
        },
    },

    tabContainer: {
        display: 'flex',
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        overflow: 'hidden',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
    },

    tabBtn: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        color: '#202020',

        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',

        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: 0,
        borderTopWidth: 4,
        borderBottomWidth: 4,
        borderColor: '#FFFFFF',

        '&:hover': {
            color: '#F47120',
        },
    },

    tabBtnActive: {
        color: '#F47120',
        borderBottomColor: '#F47120',
    },

    pillContainer: {
        display: 'flex',
    },

    pillBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        color: '#5D5D5D',

        padding: '0px 22px',

        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',

        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#E6E6E6',

        borderRadius: 10,

        '&:hover': {
            color: '#F6851E',
        },
    },

    pillBtnActive: {
        color: '#F6851E',
        borderColor: '#F6851E',
    },

    title: {
        fontSize: 15,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    row: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    profilePlaceholderSM: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 38, height: 38, borderRadius: 50
    },

    profilePlaceholderXL: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 100, height: 100, borderRadius: 100
    },

    logContainer: {
        display: 'flex', 
        backgroundColor: '#FFFFFF', border: '1px solid #E6E6E6', borderRadius: 5, marginBottom: 10, overflow: 'hidden',
    },

    paginationContainer: {
        marginTop: rem(25),
        display: 'flex',
        justifyContent: 'center',
    },
}))

const VOUCHER = [
    {
        name: 'RM5 Discount Code',
        valid: 'Valid from 1 Nov to 1 Dec',
        img: null,
    },
    {
        name: 'RM5 Discount Code',
        valid: 'Valid from 1 Nov to 1 Dec',
        img: null,
    },
    {
        name: 'RM5 Discount Code',
        valid: 'Valid from 1 Nov to 1 Dec',
        img: null,
    },
    {
        name: 'RM10 Discount Code',
        valid: 'Valid from 1 Nov to 1 Dec',
        img: null,
    },
    {
        name: 'RM10 Discount Code',
        valid: 'Valid from 1 Nov to 1 Dec',
        img: null,
    },
    {
        name: 'RM10 Discount Code',
        valid: 'Valid from 1 Nov to 1 Dec',
        img: null,
    },
    {
        name: 'RM15 Discount Code',
        valid: 'Valid from 1 Nov to 1 Dec',
        img: null,
    },
]

const ALL_TAB = 'ALL'
const HISTORY_TAB = 'HISTORY'

const ALL_PILL = 'ALL'
const FOOD_PILL = 'FOOD'
const SPORT_PILL = 'SPORT'
const SHOPPING_PILL = 'SHOPPING'
const ECOMMERCE_PILL = 'ECOMMERCE'

export default function Voucher() {
    const { classes, cx } = useStyles();
    const [activeTab, setActiveTab] = useState(ALL_TAB)
    const [voucherType, setVoucherType] = useState(ALL_PILL)
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/');
    }

    return (
        <Container size={'lg'} className={classes.root}>
            <div className={classes.containerRow}>
                {/* Nav Section */}
                <div className={classes.navContainer}>
                    <Group spacing={10}>
                        <div className={classes.profilePlaceholderSM}>
                            <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                        </div>
                        <div>
                        <Text fw={600} color='#202020' lh={'normal'} size={13}>Tan Chong Wei</Text>
                        <Space h={4}/>
                        <Anchor component={Link} to={'/profile'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={11}>Edit Profile</Text>
                        </Anchor>
                        </div>
                    </Group>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor component={Link} to={'/profile/loyalty-points'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Loyalty Point</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/wallet'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Wallet</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/voucher'} color='brandSecondary.6'>
                            <Text fw={500} lh={'normal'} size={12}>My Voucher</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/booking'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Booking</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/membership'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Membership</Text>
                        </Anchor>
                    </div>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor onClick={signOut} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>Log Out</Text>
                        </Anchor>
                    </div>
                </div>
                {/* Content Section */}
                <div className={classes.contentContainer}>
                    <div className={classes.tabContainer}>
                        <div onClick={()=>setActiveTab(ALL_TAB)} className={cx(classes.tabBtn, { [classes.tabBtnActive]: activeTab === ALL_TAB })}>
                            <Text fw={500} lh={'normal'} size={14}>All</Text>
                        </div>
                        <div onClick={()=>setActiveTab(HISTORY_TAB)} className={cx(classes.tabBtn, { [classes.tabBtnActive]: activeTab === HISTORY_TAB })}>
                            <Text fw={500} lh={'normal'} size={14}>History</Text>
                        </div>
                    </div>
                    <Space h={15}/>
                    <ScrollArea>
                    <div className={classes.pillContainer}>
                        <div onClick={()=>setVoucherType(ALL_PILL)} className={cx(classes.pillBtn, { [classes.pillBtnActive]: voucherType === ALL_PILL })}>
                            <Text fw={500} lh={'normal'} size={14}>All</Text>
                        </div>
                        <Space w={10}/>
                        <div onClick={()=>setVoucherType(FOOD_PILL)} className={cx(classes.pillBtn, { [classes.pillBtnActive]: voucherType === FOOD_PILL })}>
                            <Text fw={500} lh={'normal'} size={14}>Food</Text>
                        </div>
                        <Space w={10}/>
                        <div onClick={()=>setVoucherType(SPORT_PILL)} className={cx(classes.pillBtn, { [classes.pillBtnActive]: voucherType === SPORT_PILL })}>
                            <Text fw={500} lh={'normal'} size={14}>Sport</Text>
                        </div>
                        <Space w={10}/>
                        <div onClick={()=>setVoucherType(SHOPPING_PILL)} className={cx(classes.pillBtn, { [classes.pillBtnActive]: voucherType === SHOPPING_PILL })}>
                            <Text fw={500} lh={'normal'} size={14}>Shopping</Text>
                        </div>
                        <Space w={10}/>
                        <div onClick={()=>setVoucherType(ECOMMERCE_PILL)} className={cx(classes.pillBtn, { [classes.pillBtnActive]: voucherType === ECOMMERCE_PILL })}>
                            <Text fw={500} lh={'normal'} size={14}>E-Commerce</Text>
                        </div>
                    </div>
                    </ScrollArea>
                    <Space h={15}/>
                    {VOUCHER.map((data, i)=>(
                        <div key={i} className={classes.logContainer}>
                            <Image width={90} height={90} src={data?.img} alt="With default placeholder" withPlaceholder />
                            <div style={{flex: 1, display: 'flex', padding: '10px 20px', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                    <Text fw={600} lh={'normal'} color='#202020' size={14}>{data?.name}</Text>
                                    <Text fw={400} lh={'normal'} color='#ADADAD' size={13}>{data?.valid}</Text>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                    <Box w={85}>
                                    <Button size='xs' color='brandSecondary' fullWidth>Use</Button>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    )
}
