import React, {  } from 'react'
import { createStyles, rem, Container, Text, Space, Button, Box } from '@mantine/core'
import { IconCircleCheckFilled, IconCircleXFilled } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';

const HEADER_HEIGHT = rem(70);
const HEADER_HEIGHT_MOBILE = rem(55);

const useStyles = createStyles((theme) => ({
    
    authBG: {
        backgroundSize: 'cover', 
    },

    authSection: {
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            minHeight: `calc(100vh - ${rem(HEADER_HEIGHT_MOBILE)})`,
        },
        backgroundColor: 'rgba(250, 250, 250, 0.75)',
    },

    authWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.fn.smallerThan('sm')]: {
            alignItems: 'center'
        },
        padding: '25px 20px',
        width: '100%',
    },

    authContainer: {
        width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: '40px 25px',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    },

    authTitle: {
        background: 'linear-gradient(261deg, #F47121 0%, #F6D00F 100%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        // marginTop: 15,
        marginBottom: 30,
    }

}));

export default function BookingStatus() {
    const { classes, } = useStyles();
    const navigate = useNavigate()
    const {status} = useParams() 
    const onClickViewBooking = () => {
        navigate('/profile/booking', { replace: true });
    }

    return (
        <div className={classes.authSection}>
            <Container className={classes.authWrapper} size={'sm'}>
                <div className={classes.authContainer}>
                    {status === 'success' ? <>
                        <IconCircleCheckFilled size={85} style={{color: '#29BF6E'}}/>
                        <Space h={25}/>
                        <Text fw={600} size={20} color='#202020' lh={'normal'} align='center' pb={15}>Booking Successful</Text>
                        <Text fw={400} size={14} color='#5D5D5D' lh={'normal'} align='center'>You have successfully booked your court.</Text>
                        <Space h={40}/>
                        <Box w={220}>
                            <Button onClick={onClickViewBooking} fullWidth color='#F6851E'>View Booking</Button>
                        </Box>
                    </> : <>
                        <IconCircleXFilled size={85} style={{color: '#CA2A2A'}}/>
                        <Space h={25}/>
                        <Text fw={600} size={20} color='#202020' lh={'normal'} align='center' pb={15}>Booking Unsuccessful</Text>
                        <Text fw={400} size={14} color='#5D5D5D' lh={'normal'} align='center'>There seems to be problem with the booking. Try again later.</Text>
                        <Space h={40}/>
                        <Box w={220}>
                            <Button onClick={onClickViewBooking} fullWidth color='#F6851E'>Pay Again</Button>
                        </Box>
                    </> 
                    }
                </div>
            </Container>
        </div>
    )
}
