import React from 'react'
import { createStyles, BackgroundImage, rem, Container, Text, Space, Button, TextInput, Anchor } from '@mantine/core'
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import axios from '../../api/axios';
import { useMutation } from 'react-query';

import AuthBG from '../../assets/images/AuthBG.png'

const HEADER_HEIGHT = rem(70);
const HEADER_HEIGHT_MOBILE = rem(55);

const useStyles = createStyles((theme) => ({
    
    authBG: {
        backgroundSize: 'cover', 
    },

    authSection: {
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            minHeight: `calc(100vh - ${rem(HEADER_HEIGHT_MOBILE)})`,
        },
        backgroundColor: 'rgba(250, 250, 250, 0.75)',
    },

    authWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.fn.smallerThan('sm')]: {
            alignItems: 'center'
        },
        padding: '25px 0px'
    },

    authContainer: {
        width: 400, backgroundColor: '#FFFFFF', borderRadius: 5, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: '40px 25px',
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
        },
    },

    authTitle: {
        background: 'linear-gradient(261deg, #F47121 0%, #F6D00F 100%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        // marginTop: 15,
        marginBottom: 30,
    }

}));

export default function Register() {
    const { classes, } = useStyles();
    const { width } = useViewportSize();
    const matches = useMediaQuery('(min-width: 36em)');

    const navigate = useNavigate();

    const form = useForm({
        initialValues: {
            fullname: '',
            phone_number: '',
            email: '',
            referral: '',
        },
    
        validate: {
            fullname: (value) => (value.length < 1 ? 'Please enter your name' : null),
            phone_number: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : value.trim().length < 9 ? 'Invalid Phone Number' : !/^\d*$/.test(value.trim()) ? 'Invalid Phone Number' : null),
            email: (value) => (value.length < 1 ? 'Please enter your email' : /^\S+@\S+$/.test(value) ? null : 'Invalid email address'),
        },
    });

    const registerMutation = useMutation(
        (data) => axios.post(`/accounts/register`, data),
        {
            onSuccess: (res) => {
                notifications.show({
                    title: 'Register Successful',
                    message: 'Login to book your preferred court',
                })
                navigate('/login', { replace: true })
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            },
        }
    );

    const onRegister = async (data) => {
        registerMutation.mutate({fullname: data.fullname.trim(), phone_number: data.phone_number.trim(), email: data.email.trim(), referral: data.referral.trim()})
    }

    return (
        <BackgroundImage 
        src={AuthBG}
        className={classes.authBG}>
            <div className={classes.authSection}>
                <Container className={classes.authWrapper} >
                    <div className={classes.authContainer} style={{width: !matches ? width-50 : 400}}>
                    <form onSubmit={form.onSubmit((values) => onRegister(values))}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Text fw={700} size={24} className={classes.authTitle}>Register</Text>
                        </div>
                        <Text fw={600} size={13} color='#202020' lh={'normal'} pb={5}>Full Name</Text>
                        <TextInput
                            size='md'
                            placeholder="Full Name"
                            {...form.getInputProps('fullname')}
                            disabled={registerMutation.isLoading}
                        />
                        <Space h={15}/>
                        <Text fw={600} size={13} color='#202020' lh={'normal'} pb={5}>Phone Number</Text>
                        <TextInput
                            size='md'
                            placeholder="Phone Number"
                            {...form.getInputProps('phone_number')}
                            maxLength={10}
                            disabled={registerMutation.isLoading}
                            icon={<Text size={16} color='#2D2D2D' lh={'normal'}>+60</Text>}
                        />
                        <Space h={15}/>
                        <Text fw={600} size={13} color='#202020' lh={'normal'} pb={5}>Email Address</Text>
                        <TextInput
                            size='md'
                            placeholder="Email Address"
                            {...form.getInputProps('email')}
                            disabled={registerMutation.isLoading}
                        />
                        {/* <Space h={15}/>
                        <Text fw={600} size={13} color='#202020' lh={'normal'} pb={5}>Referral Code</Text>
                        <TextInput
                            size='md'
                            placeholder="Optional"
                            {...form.getInputProps('referral')}
                            disabled={registerMutation.isLoading}
                        /> */}
                        {/* <Divider my={25}/>
                        <Text fw={600} size={13} color='#202020' lh={'normal'} pb={5}>Password</Text>
                        <PasswordInput
                            size='md'
                            placeholder="Password"
                            {...form.getInputProps('password')}
                            disabled={registerMutation.isLoading}
                        />
                        <Space h={15}/>
                        <Text fw={600} size={13} color='#202020' lh={'normal'} pb={5}>Confirm Password</Text>
                        <PasswordInput
                            size='md'
                            placeholder="Confirm Password"
                            {...form.getInputProps('confirm_password')}
                            disabled={registerMutation.isLoading}
                        /> */}
                        <Space h={30}/>
                        <Button type='submit' fullWidth loading={registerMutation.isLoading}>
                            Create Account
                        </Button>
                        <Space h={10}/>
                        <Text fw={400} size={12} color='#202020' lh={'normal'} align='center'>Already have an account? <Anchor component={Link} to={'/login'}>Log In Now</Anchor></Text>
                    </form>
                    </div>
                </Container>
            </div>
        </BackgroundImage>
    )
}
