import React, { useEffect, useState } from 'react'
import { createStyles, BackgroundImage, rem, Container, Text, Space, Checkbox , Button, TextInput, PinInput, Anchor, Transition, Group } from '@mantine/core'
import { useMediaQuery, useViewportSize, useTimeout } from '@mantine/hooks';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from '@mantine/form';
import useAuth from '../../hooks/useAuth';
import { notifications } from '@mantine/notifications';
import axios from '../../api/axios';
import { useMutation } from 'react-query';

import AuthBG from '../../assets/images/AuthBG.png'

const HEADER_HEIGHT = rem(70);
const HEADER_HEIGHT_MOBILE = rem(55);

const useStyles = createStyles((theme) => ({
    
    authBG: {
        backgroundSize: 'cover', 
    },

    authSection: {
        minHeight: `calc(100vh - ${rem(HEADER_HEIGHT)})`,
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            minHeight: `calc(100vh - ${rem(HEADER_HEIGHT_MOBILE)})`,
        },
        backgroundColor: 'rgba(250, 250, 250, 0.75)',
    },

    authWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.fn.smallerThan('sm')]: {
            alignItems: 'center'
        },
        padding: '25px 0px'
    },

    authContainer: {
        width: 400, backgroundColor: '#FFFFFF', borderRadius: 5, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', padding: '40px 25px',
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
        },
    },

    authTitle: {
        background: 'linear-gradient(261deg, #F47121 0%, #F6D00F 100%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        // marginTop: 15,
        marginBottom: 30,
    }

}));

export default function Login() {
    const { classes, } = useStyles();
    const { width } = useViewportSize();
    const matches = useMediaQuery('(min-width: 36em)');
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [countDown, setCountDown] = useState(0);
    const [uid, setUid] = useState(null);
    const [isPhoneExist, setPhoneExist] = useState(false)
    const [showEnterPin, setShowEnterPin] = useState(false)
    const showEnterPinTimeout = useTimeout(() => setShowEnterPin(!showEnterPin), 400);
    const showEnterPhoneTimeout = useTimeout(() => setPhoneExist(!isPhoneExist), 400);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false)
    const [phoneNumber, setPhoneNUmber] = useState('')


    const onClickBackPin = () => {
        //
        setShowEnterPin(!showEnterPin)
        showEnterPhoneTimeout.start();
    }

    const { setAuth, persist, setPersist } = useAuth()

    const togglePersist = () => {
        setPersist(prev => !prev)
    }

    useEffect(() => {
        localStorage.setItem("persist", persist)
    }, [persist])

    useEffect(() => {
        if(countDown > 0){
            const interval = setInterval(() => {
                setCountDown(prev => prev-1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [countDown]);

    const form = useForm({
        initialValues: {
            phone_number: '',
        },
    
        validate: {
            phone_number: (value) => (value.trim().length < 1 ? 'Please fill in your phone number' : value.trim().length < 9 ? 'Invalid Phone Number' : !/^\d*$/.test(value.trim()) ? 'Invalid Phone Number' : null),
        },
    });

    const loginMutation = useMutation(
        (data) => axios.post("/auth/login", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                setUid(res?.data?.uid)
                setCountDown(60)
                setPhoneExist(!isPhoneExist);
                showEnterPinTimeout.start();
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const resendMutation = useMutation(
        (data) => axios.post("/auth/login", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                setUid(res?.data?.uid)
                setCountDown(60)
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const validateOTPMutation = useMutation(
        (data) => axios.post("/auth/verify-code", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                setAuth({ fullname: res.data.userDetails.fullname, uid: res.data.userDetails.uid, accessToken: res.data.token, })
                navigate(from, { replace: true })
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const onRequestOTPPressed = (data) => {
        setPhoneNUmber(data.phone_number.trim())
        loginMutation.mutate({phone_number: data.phone_number.trim()})
    }

    const onSubmitOTPPressed = () => {
        if(code.trim().length < 6){
            setCodeError(true)
            return
        }
        setCodeError(false)
        validateOTPMutation.mutate({uid, code: code.trim()})
    }

    const onResendOTP = () => {
        if(phoneNumber){
            resendMutation.mutate({phone_number: phoneNumber})
        }
    }

    return (
        <BackgroundImage 
        src={AuthBG}
        className={classes.authBG}>
            <div className={classes.authSection}>
                <Container className={classes.authWrapper}>
                    <Transition mounted={!isPhoneExist} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => 
                    <div style={styles}>
                        <div className={classes.authContainer} style={{width: !matches ? width-50 : 400}}>
                        <form onSubmit={form.onSubmit((values) => onRequestOTPPressed(values))}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Text fw={700} size={24} className={classes.authTitle}>Login</Text>
                            </div>
                            <Text fw={600} size={13} color='#202020' lh={'normal'} pb={5}>Enter Phone Number</Text>
                            <TextInput
                                size='md'
                                placeholder="Phone Number"
                                {...form.getInputProps('phone_number')}
                                maxLength={10}
                                disabled={loginMutation.isLoading}
                                icon={<Text size={16} color='#2D2D2D' lh={'normal'}>+60</Text>}
                            />
                            <Space h={15}/>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Checkbox
                                    label="Remember Me"
                                    checked={persist} onChange={togglePersist}
                                />
                            </div>
                            <Space h={30}/>
                            <Button type='submit' fullWidth loading={loginMutation.isLoading}>
                                Request OTP
                            </Button>
                            <Space h={10}/>
                            <Text fw={400} size={12} color='#202020' lh={'normal'} align='center'>Don’t have an account? <Anchor component={Link} to={'/register'}>Create Now</Anchor></Text>
                        </form>
                        </div>
                    </div>
                    }
                    </Transition>
                    <Transition mounted={showEnterPin} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => 
                    <div style={styles}>
                        <div className={classes.authContainer} style={{width: !matches ? width-50 : 400, styles}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Text fw={700} size={24} className={classes.authTitle}>Login</Text>
                            </div>
                            <Text fw={600} size={13} color='#202020' lh={'normal'} pb={20}>Please enter the One-time Password sent to +60{phoneNumber}.</Text>
                            <Group position="center">
                                <PinInput 
                                    length={6} 
                                    size={!matches ? 'lg' : 'xl'}
                                    type="number"
                                    oneTimeCode
                                    placeholder=""
                                    value={code}
                                    onChange={setCode}
                                    error={codeError}
                                    disabled={validateOTPMutation.isLoading}
                                />
                            </Group>
                            <Space h={20}/>
                            <Text fw={500} size={13} color='#202020' lh={'normal'}>Didn’t get the code yet? <Anchor onClick={()=>{if(countDown === 0){onResendOTP()}}}>{countDown === 0 ? `Resend` : `Resend (${countDown})`}</Anchor></Text>
                            
                            <Space h={30}/>
                            <Button onClick={onSubmitOTPPressed} fullWidth loading={validateOTPMutation.isLoading}>
                                Login
                            </Button>
                            <Space h={10}/>
                            <Text fw={400} size={12} color='#202020' lh={'normal'} align='center'>Not your number? <Anchor onClick={onClickBackPin}>Return</Anchor></Text>
                        </div>
                    </div>
                    }
                    </Transition>
                </Container>
            </div>
        </BackgroundImage>
    )
}
