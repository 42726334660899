import React, { useEffect, useState } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group,TextInput, Select, Image, Divider, Button, Box, ActionIcon, Pagination, Skeleton } from '@mantine/core'
import { IconChevronLeft, IconCalendar, IconCircleCheckFilled, IconHeart, IconHeartFilled } from '@tabler/icons-react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DatePickerInput } from '@mantine/dates';
import { useMediaQuery, useDebouncedValue } from '@mantine/hooks';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';
import useAuth from '../../hooks/useAuth';
import moment from 'moment';
import momenttz from 'moment-timezone';

import SearchVenueCard from '../../components/Venue/SearchVenueCard';

import axios from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(25),
    },

    searchContainer: {
        display: 'flex',
        marginTop: rem(25),
        marginBottom: rem(25),

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            marginBottom: rem(20),
        },
    },

    inputWrapperLarge: {
        flex: 2,
        [theme.fn.smallerThan('sm')]: {
            marginBottom: 15,
        },
    },

    inputWrapperSmall: {
        flex:1,
        [theme.fn.smallerThan('sm')]: {
            marginBottom: 5,
        },
    },

    contentBox: {
        backgroundColor: '#FFFFFF',
        padding: `${rem(15)} ${rem(15)}`,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
        marginTop: rem(20),
    },

    contentContainer: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            // flexDirection: 'column',
        },
    },

    btnContainer: {
        display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'
    },

    paginationContainer: {
        marginTop: rem(25),
        display: 'flex',
        justifyContent: 'center',
    },

    venueImgWrapper: {
        flex: 1, 
        [theme.fn.smallerThan('sm')]: {
            flex: 2
        },
    },

    venueTextWrapper: {
        flex: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
        [theme.fn.smallerThan('sm')]: {
            // flex: 2
        },
    },

    fontSM: {
        fontSize: 12,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 11,
        },
    },

    fontLG: {
        fontSize: 20,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 16,
        },
    },

}))

const SEARCH = [
    {
        id: '1',
        sports: 'BADMINTON, CAPTAINBALL',
        name: 'X Park PJ South',
        city: 'Petaling Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue01.png'),
        location: '19-3 USJ 9/5M 47620 SUBANG JAYA, SELANGOR',
        liked: true,
    },
    {
        id: '2',
        sports: 'TABLE TENNIS',
        name: 'Desport Table Tennis Centre',
        city: 'Subang Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue02.png'),
        location: '19-3 USJ 9/5M 47620 SUBANG JAYA, SELANGOR',
        liked: true,
    },
    {
        id: '3',
        sports: 'FOOTBALL',
        name: 'Endfield Football Field, The Club',
        city: 'Petaling Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue03.png'),
        location: '19-3 USJ 9/5M 47620 SUBANG JAYA, SELANGOR',
        liked: false,
    },
    {
        id: '4',
        sports: 'HOCKEY, HANDBALL, FUTSAL',
        name: 'Scotfield Sport Centre',
        city: 'Subang Jaya',
        state: 'Selangor',
        imgUrl: require('../../assets/sample/Venue04.png'),
        location: '19-3 USJ 9/5M 47620 SUBANG JAYA, SELANGOR',
        liked: false,
    }
]


export default function SearchVenue() {
    const { classes } = useStyles();
    const matches = useMediaQuery('(min-width: 48em)');
    const [activePage, setPage] = useState(1)
    const navigate = useNavigate();
    const { auth } = useAuth()
    const [searchParams] = useSearchParams();
    const sport = searchParams.get("sport")
    const state = searchParams.get("state")
    const date = searchParams.get("date")
    const axiosPrivate = useAxiosPrivate()
    const location = useLocation()
    const queryClient = useQueryClient()
    let currentDate = moment().tz("Asia/Kuala_Lumpur").toDate()

    // console.log(sport)
    // console.log(state)
    // console.log(date)

    const [selectedSport, setSelectedSport] = useState(sport ? parseInt(sport) : 1)
    const [selectedState, setSelectedState] = useState(state)
    const [selectedDate, setSelectedDate] = useState()

    const [locations, setLocations] = useState([])
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);

    const getSportSelectionQuery = useQuery(
        "getSportSelection",
        async ({ signal }) => (await axios.get("/sports/get-all-selection", { }, { signal })).data.sports,
        {
            initialData: [],
            onSuccess: (res) => {
                if(!sport){
                    setSelectedSport(res[0].value)
                }
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    // const { data: locations, isFetching, isError, isRefetching, isLoading, refetch } = useQuery(
    //     "getSearchVenue",
    //     async ({ signal }) => (await axios.post("/locations/read-by-sport-state", {type: selectedSport ? selectedSport : 1, state: selectedState ? selectedState : '' }, { signal })).data.locations,
    //     {
    //         initialData: [],
    //         onSuccess: (res) => {
    //             console.log(res)
    //         },
    //         onError: (err) => {
    //             let errMsg = err?.response?.data?.message
    //             notifications.show({
    //                 title: 'Error',
    //                 message: errMsg ? errMsg : 'Unable to load...',
    //                 color: 'red',
    //             })
    //         }
    //     }
    // );

    const getSearchVenueMutation = useMutation(
        (data) => axios.post("/locations/read-by-sport-state", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                setLocations(res.data.locations)
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const getLikedQuery = useQuery(
        "getLikedLocationId",
        async ({ signal }) => (await axiosPrivate.post("/liked/read-by-uid", { uid: auth?.uid }, { signal })).data.liked,
        {
            enabled: auth ? true : false,
            initialData: null,
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const unlikeVenueMutation = useMutation(
        (data) => axiosPrivate.post("/liked/remove-by-uid", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries("getLiked")
                queryClient.invalidateQueries("getLikedLocationId")
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const likeVenueMutation = useMutation(
        (data) => axiosPrivate.post("/liked/add-by-uid", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries("getLiked")
                queryClient.invalidateQueries("getLikedLocationId")
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const onClickViewVenue = (id) => {
        navigate(`/venue/${id}`)    
    }

    const onClickLikedUnLikedVenue = (item, liked) => {
        if(!auth) {
            navigate('/login', { state:{ from: location }, replace: true });
            return; 
        }
        if(unlikeVenueMutation.isLoading || likeVenueMutation.isLoading) return
        if(liked){
            unlikeVenueMutation.mutate({ uid: auth.uid, locationId: item?.locationId })
        }
        else{
            likeVenueMutation.mutate({ uid: auth.uid, locationId: item?.locationId })
        }
    }

    useEffect(() => {
        setRecords(
            locations.filter(({ name }) => {
                if (
                debouncedQuery !== '' &&
                !`${name} `
                    .toLowerCase()
                    .includes(debouncedQuery.trim().toLowerCase())
                ) {
                return false;
                }
                return true;
            })
        );
    }, [debouncedQuery, locations]);

    useEffect(()=>{
        queryClient.invalidateQueries('getSearchVenue')
        getSearchVenueMutation.mutate({type: selectedSport ? selectedSport : 1, state: selectedState ? selectedState : '' })
    }, [selectedSport, selectedState])

    useEffect(()=>{
        if(date){
            setSelectedDate(moment(date).toDate())
        }
    },[date])


    return (
        <Container size={'lg'} className={classes.root}>
            <Anchor component={Link} to={'/'} color='brandSecondary'>
                <Group spacing={8}>
                    <IconChevronLeft size={22}/>
                    <Text fw={500} size={15}>Back to Home</Text>
                </Group>
            </Anchor>
            <div className={classes.searchContainer}>
                <div className={classes.inputWrapperLarge}>
                    <TextInput
                        size='md'
                        placeholder="Search"
                        // withAsterisk
                        onChange={(e) => setQuery(e.currentTarget.value)}
                        value={query}
                    />
                </div>
                <Space w={10}/>
                <div className={classes.inputWrapperSmall}>
                    <Select
                        size='md'
                        placeholder="Select sport"
                        data={getSportSelectionQuery.data}
                        value={selectedSport}
                        onChange={setSelectedSport}
                    />
                </div>
                <Space w={10}/>
                <div className={classes.inputWrapperSmall}>
                    <Select
                        size='md'
                        placeholder="Select state"
                        data={[
                            { value: 'Johor', label: 'Johor' },
                            { value: 'Kedah', label: 'Kedah' },
                            { value: 'Kelantan', label: 'Kelantan' },
                            { value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
                            { value: 'Labuan', label: 'Labuan' },
                            { value: 'Malacca', label: 'Malacca' },
                            { value: 'Negeri Sembilan', label: 'Negeri Sembilan' },
                            { value: 'Pahang', label: 'Pahang' },
                            { value: 'Penang', label: 'Penang' },
                            { value: 'Perak', label: 'Perak' },
                            { value: 'Perlis', label: 'Perlis' },
                            { value: 'Putrajaya', label: 'Putrajaya' },
                            { value: 'Sabah', label: 'Sabah' },
                            { value: 'Sarawak', label: 'Sarawak' },
                            { value: 'Selangor', label: 'Selangor' },
                            { value: 'Terengganu', label: 'Terengganu' },
                        ]}
                        onChange={setSelectedState}
                        value={selectedState}
                    />
                </div>
                <Space w={10}/>
                <div className={classes.inputWrapperSmall}>
                    <DatePickerInput
                        valueFormat="DD/MM/YYYY"
                        size='md'
                        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                        placeholder="Select date"
                        minDate={currentDate}
                        onChange={setSelectedDate}
                        value={selectedDate}
                    />
                </div>
            </div>
            <Text size={16} fw={700} color='#202020' lh={'normal'}>Available for Booking ({records.length})</Text>
            {records.map((item, i)=>(
                <SearchVenueCard key={i} data={item} isError={getSearchVenueMutation.isError} loading={getSearchVenueMutation.isLoading} onClick={()=>onClickViewVenue(item?.locationId)} onClickLikedUnLikedVenue={onClickLikedUnLikedVenue} likedLocationsId={getLikedQuery.data?.locationIdArr}/>
            ))}
            {/* <div className={classes.paginationContainer}>
                <Pagination value={activePage} onChange={setPage} total={10} size={matches? 'md' : 'sm'}/>
            </div> */}
        </Container>
    )
}
