import React, { useState, useEffect } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Divider, Button, NumberInput, Select, Box  } from '@mantine/core'
import { IconUserFilled, IconChevronLeft } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';

import useLogout from '../../hooks/useLogout';
// import { convertToCurrency } from '../../utils/Currency';

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(25),
    },

    containerRow: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'initial',
        },
    },

    navContainer: {
        flex: 2, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px', marginRight: 25,
        [theme.fn.smallerThan('sm')]: {
            marginRight: 0,
            marginBottom: 25,
        },
    },

    contentContainer: {
        flex: 8, 
    },

    topUpContainter: {
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '25px 30px',
        [theme.fn.smallerThan('sm')]: {
            padding: '15px 20px',
        },
    },

    amountDetailsContainer: {
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '20px 30px',
        [theme.fn.smallerThan('sm')]: {
            padding: '15px 20px',
        },
    },

    title: {
        fontSize: 15,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    row: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    profilePlaceholderSM: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 38, height: 38, borderRadius: 50
    },

    profilePlaceholderXL: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 100, height: 100, borderRadius: 100
    },

    amountBtn: {
        display: 'flex', width: 95, padding: '14px 0px', justifyContent: 'center', alignItems: 'center',border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: 5,
        cursor: 'pointer', color: '#2B2B2B',

        '&:hover': {
            color: '#F6851E',
        },
    },

    amountBtnActive: {
        border: '1px solid #F8A456',
        color: '#F6851E',
    },
}))

export default function TopUp() {
    const { classes, cx } = useStyles();
    const matches = useMediaQuery('(min-width: 48em)');
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/');
    }

    const [selectedAmount, setSelectedAmount] = useState(null)

    const form = useForm({
        initialValues: {
            amount: 0,
            paymentType: '',
        },
    
        validate: {
            paymentType: (value) => (value.trim().length < 1 ? 'Please select payment type' : null),
        },
    });

    useEffect(()=>{
        if(selectedAmount){
            form.setValues({
                amount: selectedAmount,
            })
        }
    }, [selectedAmount])

    return (
        <Container size={'lg'} className={classes.root}>
            {!matches ? <>
            <Anchor component={Link} to={'/profile/wallet'} color='brandSecondary'>
                <Group spacing={8}>
                    <IconChevronLeft size={22}/>
                    <Text fw={500} size={15}>Back</Text>
                </Group>
            </Anchor>
            <Space h={15}/>
            </> : null}
            <div className={classes.containerRow}>
                {/* Nav Section */}
                <div className={classes.navContainer}>
                    <Group spacing={10}>
                        <div className={classes.profilePlaceholderSM}>
                            <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                        </div>
                        <div>
                        <Text fw={600} color='#202020' lh={'normal'} size={13}>Tan Chong Wei</Text>
                        <Space h={4}/>
                        <Anchor component={Link} to={'/profile'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={11}>Edit Profile</Text>
                        </Anchor>
                        </div>
                    </Group>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor component={Link} to={'/profile/loyalty-points'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Loyalty Point</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/wallet'} color='brandSecondary.6'>
                            <Text fw={500} lh={'normal'} size={12}>My Wallet</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/voucher'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Voucher</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/booking'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Booking</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/membership'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Membership</Text>
                        </Anchor>
                    </div>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor onClick={signOut} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>Log Out</Text>
                        </Anchor>
                    </div>
                </div>
                {/* Content Section */}
                <div className={classes.contentContainer}>
                    <form onSubmit={form.onSubmit((values) => console.log(values))}>
                    {matches ? <>
                    <Anchor component={Link} to={'/profile/wallet'} color='brandSecondary'>
                        <Group spacing={8}>
                            <IconChevronLeft size={22}/>
                            <Text fw={500} size={15}>Back</Text>
                        </Group>
                    </Anchor>
                    <Space h={15}/>
                    </> : null}
                    <div className={classes.topUpContainter}>
                        <Text fw={600} lh={'normal'} color='#202020' size={14}>Top-up Amount</Text>
                        <Space h={15}/>
                        <Group spacing={15}>
                            <div onClick={()=>setSelectedAmount(10.00)} className={cx(classes.amountBtn, { [classes.amountBtnActive]: selectedAmount === 10.00 })}>
                                <Text fw={500} lh={'normal'} size={14}>RM 10.00</Text>
                            </div>
                            <div onClick={()=>setSelectedAmount(50.00)} className={cx(classes.amountBtn, { [classes.amountBtnActive]: selectedAmount === 50.00 })}>
                                <Text fw={500} lh={'normal'} size={14}>RM 50.00</Text>
                            </div>
                            <div onClick={()=>setSelectedAmount(100.00)} className={cx(classes.amountBtn, { [classes.amountBtnActive]: selectedAmount === 100.00 })}>
                                <Text fw={500} lh={'normal'} size={14}>RM 100.00</Text>
                            </div>
                            <div onClick={()=>setSelectedAmount(200.00)} className={cx(classes.amountBtn, { [classes.amountBtnActive]: selectedAmount === 200.00 })}>
                                <Text fw={500} lh={'normal'} size={14}>RM 200.00</Text>
                            </div>
                            <div onClick={()=>setSelectedAmount(500.00)} className={cx(classes.amountBtn, { [classes.amountBtnActive]: selectedAmount === 500.00 })}>
                                <Text fw={500} lh={'normal'} size={14}>RM 500.00</Text>
                            </div>
                        </Group>
                        <Space h={15}/>
                        <NumberInput 
                            decimalSeparator="."
                            placeholder='Enter Amount'
                            precision={2}
                            min={5}
                            hideControls
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            formatter={(value) =>
                                !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                            }
                            // value={inputAmount} onChange={setInputAmount}
                            {...form.getInputProps('amount')}
                            icon={<Text fw={500} lh={'normal'} size={14} color='#5D5D5D' pl={6}>RM</Text>}
                        />
                        <Divider mt={25} mb={15}/>
                        <Text fw={600} color='#202020' lh={'normal'} size={14} pb={5}>Select payment type</Text>
                        <Select
                            placeholder="Select payment type"
                            data={[
                                { value: 'credit', label: 'Credit / Debit Card' },
                                { value: 'FPX', label: 'Online Banking (FPX)' },
                            ]}
                            {...form.getInputProps('paymentType')}
                        />
                    </div>
                    <Space h={15}/>
                    <div className={classes.amountDetailsContainer}>
                        <Text fw={600} lh={'normal'} color='#202020' size={15}>Payment Details</Text>
                        <Space h={15}/>
                        <Group position='apart'>
                            <div>
                                <Text fw={400} lh={'normal'} color='#5D5D5D' size={14} pb={5}>Top-up Amount</Text>
                                <Text fw={400} lh={'normal'} color='#5D5D5D' size={14}>Total Payment</Text>
                            </div>
                            <div>
                                <Text fw={500} lh={'normal'} color='#5D5D5D' size={14} pb={5}>RM 100.00</Text>
                                <Text fw={500} lh={'normal'} color='#F6851E' size={14}>RM 100.00</Text>
                            </div>
                        </Group>
                    </div>
                    <Space h={15}/>
                    <div className={classes.amountDetailsContainer}>
                        <Group position='apart'>
                            <div>
                                <Text fw={500} lh={'normal'} color='#202020' size={14}>Total</Text>
                                <Text fw={600} lh={'normal'} color='#202020' size={20}>RM 100.00</Text>
                            </div>
                            <Box w={150}>
                                <Button type="submit" fullWidth>Top Up</Button>
                            </Box>
                        </Group>
                    </div>
                    </form>
                </div>
            </div>
        </Container>
    )
}
