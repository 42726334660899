import React from 'react'
import { createStyles, Image, Text, Divider, Space, Group, Box, Button  } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks';

import { IconUserFilled,  } from '@tabler/icons-react'

import { formatMMMM_DD_DOW, formath_mm_a, formatDD_MM_YYYY } from '../../utils/DateTime';

const useStyles = createStyles((theme) => ({

    upcomingContainer: {
        display: 'flex', flexDirection: 'column',
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, marginBottom: 15, overflow: 'hidden',
        padding: '15px 20px 20px 20px'
    },
}))

export default function HistoryCard({ data, onClick, isError, loading }) {
    const matches = useMediaQuery('(min-width: 48em)');
    const { classes, cx } = useStyles();

    const players = JSON.parse(data?.players ? data?.players : '[]')

    let status = 'Ended'
    if(data?.isCancelled){
        status = 'Cancelled'
    }

    return (
        <div className={classes.upcomingContainer}>
            <div style={{flex: 1,display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex'}}>
                    <div style={{border: '1px solid #E6E6E6', borderRadius: 5,}}>
                        <Image width={75} height={75} src={data?.logo} fit='contain' alt="With default placeholder" radius={5} withPlaceholder />
                    </div>
                    <div style={{marginLeft: 15, marginTop: 8}}>
                        <Text fw={600} lh={'normal'} color='#202020' size={16}>{data?.locationName}</Text>
                        <Text fw={400} lh={'normal'} color='#5D5D5D' size={13}>{data?.city}</Text>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    {
                    status  === 'Cancelled' ? 
                    <div style={{ padding: '5px 18px', borderRadius: 3, backgroundColor: '#CA2A2A', }}>
                        <Text fw={600} lh={'normal'} color='#FFFFFF' size={10}>{status}</Text>
                    </div>
                    :
                    <div style={{ padding: '5px 18px', borderRadius: 3, backgroundColor: '#BFBFBF', }}>
                        <Text fw={600} lh={'normal'} color='#FFFFFF' size={10}>{status}</Text>
                    </div>
                    }
                </div>
            </div>
            <Divider my={15}/>
            <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3} tt="uppercase">{data?.sport}</Text>
            <Text fw={500} lh={'normal'} color='#202020' size={14} pb={3}>{data?.isPublic ? 'Public Match' : 'Private Match'}</Text>
            <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>Hosted by {data?.host}</Text>
            <Space h={15}/>
            <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>LOCATION</Text>
            <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{data?.locationName}</Text>
            <Space h={15}/>
            <Group spacing={matches ? 50 : 15}>
                <div>
                    <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>DATE</Text>
                    <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{formatMMMM_DD_DOW(data?.startDatetime)}</Text>
                </div>
                <div>
                    <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>TIME</Text>
                    <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{formath_mm_a(data?.startDatetime)} to {formath_mm_a(data?.endDatetime)}</Text>
                </div>
                <div>
                    <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={3}>COURT BOOKED</Text>
                    <Text fw={400} lh={'normal'} color='#5D5D5D' size={12}>{data?.courtArrName.map((item, i)=>(data?.courtArrName.length === i+1 ?  item : item + ', '))}</Text>
                </div>
            </Group>
            {data?.noOfPlayers > 0 ? <>
            <Space h={15}/>
            <Text fw={500} lh={'normal'} color='#F6851E' size={12} pb={10}>JOINED PLAYERS</Text>
            <div style={{display: 'flex', flex: 1,}}>
            {[...Array(data?.noOfPlayers)].map((x, i) => {
                if(i>4)
                return null
                if(i===4 && data?.noOfPlayers > 5 && players.length > 5)
                return(
                    <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                        <Text size={13} fw={700} color='#FFFFFF' lh={'normal'}>+{players.length-i}</Text>
                    </div>
                )
                else if(players.length > i)
                return(
                    <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                        <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                    </div>
                )
                else
                return(
                    <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: '#D4D4D4', marginRight: 5}}>
                        <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                    </div>
                )
            }
            )}
            </div>
            </> 
            : null}
            <Divider my={20}/>
            <Group position='apart'>
                <Text fw={400} lh={'normal'} color='#5D5D5D' size={10}>Booking made on {formatDD_MM_YYYY(data?.createdAt)}</Text>
                <Group spacing={10}>
                    <Box w={matches ? 180 : 140}>
                        <Button onClick={onClick} size='xs' color='brand' fullWidth>View Details</Button>
                    </Box>
                </Group>
            </Group>
        </div>
    )
}
