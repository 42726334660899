import { Skeleton, Image, Text, Space, Group, Button } from '@mantine/core'
import React from 'react'

import { IconMapPinFilled } from '@tabler/icons-react'

export default function VenueCard({ isError, loading, data, onClick }) {

    const gallery = JSON.parse(data?.gallery ? data?.gallery : '[]')

  return (
    <Skeleton animate={!isError} visible={loading || isError}>
        <div style={{backgroundColor: '#FFFFFF', borderRadius: 5, boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.15)', overflow: 'hidden'}}>
            <Image height={200} src={gallery.length > 0 ? gallery[0] : null} alt="With default placeholder" withPlaceholder />
            <div style={{minHeight: 200,display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '20px 18px'}}>
                <div style={{flex: 1}}>
                    {/* <Text size={12} fw={500} color='#F47120' lh={'normal'}>{data?.sports}</Text>
                    <Space h={5}/> */}
                    <Text size={16} fw={700} color='#202020' lh={'normal'}>{data?.name}</Text>
                    <Space h={15}/>
                    <Group spacing={8}>
                        <IconMapPinFilled size={18} color='#5D5D5D' style={{color: '#5D5D5D'}}/>
                        <Text size={12} fw={500} color='#5D5D5D' lh={'normal'}>{data?.city}, {data?.state}</Text>
                    </Group>
                </div>
                <Space h={30}/>
                <Button onClick={onClick} fullWidth>View Venue</Button>
            </div>
        </div>
    </Skeleton>
  )
}
