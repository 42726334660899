import { createStyles, Container, Group, Anchor, Text, rem, Image } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';

import { IconBrandInstagram, IconBrandFacebook } from '@tabler/icons-react';

import { INSTAGRAM_URL, FACEBOOK_URL, PLAYSTORE_URL, APPSTORE_URL } from '../../utils/Contants';

import AppStoreLogo from '../../assets/logo/AppStoreLogo.png'
import GooglePlayLogo from '../../assets/logo/GooglePlayLogo.png'

const useStyles = createStyles((theme) => ({
    footer: {
        background: 'linear-gradient(269deg, #F37020 0%, #F5CF0E 100%)',
        
        [theme.fn.smallerThan('sm')]: {
            background: 'linear-gradient(207deg, #F6D00F 0%, #F47121 100%)',
        },
    },
      
    inner: {
        paddingTop: rem(40),
        paddingBottom: rem(40),
        [theme.fn.smallerThan('md')]: {
            paddingTop: rem(40),
            paddingBottom: rem(40),
        },
    },

    flexRow: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    groupLeft: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        [theme.fn.smallerThan('md')]: {
            alignItems: 'flex-start',
            paddingBottom: rem(40),
        },
    },

    groupCenter: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingBottom: rem(40),
        [theme.fn.smallerThan('md')]: {
            paddingBottom: rem(15),
        },
        
    },

    groupRight: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        [theme.fn.smallerThan('md')]: {
            alignItems: 'flex-start',
            paddingBottom: rem(40),
        },
    },

      
    links: {
        // @media (max-width: $mantine-breakpoint-xs) {
        //   margin-top: var(--mantine-spacing-md);
        // }
        [theme.fn.smallerThan('md')]: {
            paddingBottom: rem(25),
        },
    },

    downloadBtn: {
        width: 178,
        cursor: 'pointer',

        [theme.fn.smallerThan('xs')]: {
            width: '47%',
        },
    },

    socialBtn: {
        width: 53,
        height: 53,
        borderRadius: 53,
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#F47120',
        border: '2px solid #FFFFFF',
        [theme.fn.smallerThan('sm')]: {
            width: 43,
            height: 43,
        },
        transition: 'all 0.5s ease',
        '&:hover': {
            border: '2px solid #F47120',
        },
    },

    flexRowReverse: {
        display: 'flex',
        justifyContent: 'space-between',
        // alignItems: 'center',
        [theme.fn.smallerThan('md')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            gap: 20
        },
    },

    sectionTitle: {
        fontSize: 20,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 18,
        },
        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
    }
}))


export default function ICourtFooter() {
    const { classes } = useStyles();
    const matches = useMediaQuery('(min-width: 48em)');

    const links = [
        { link: '/search', label: 'EXPLORE' },
        // { link: '/about', label: 'ABOUT US' },
        { link: 'https://wa.me/601111290027', label: 'HELP CENTRE', newTab: true },
    ];

    const items = links.map((link, index) => (
        <Anchor
            key={link.label}
            component={Link}
            to={link.link}
            target={link.newTab ? '_blank' : '_self'} 
            c="#FFFFFF"
            size={matches?12:12}
            fw={500}
            mb={15}
        >
            {link.label}
        </Anchor>
    ));

    return (
        <div className={classes.footer}>
        <Container className={classes.inner} size={'xl'}>
                <div className={classes.flexRow}>
                    <div className={classes.groupLeft}>
                        <Text color='#FFFFFF' fw={600} pb={15} className={classes.sectionTitle}>Download Our App Now</Text>
                        <Group>
                            <a href={APPSTORE_URL} target='_blank' rel='noreferrer'>
                            <Image width={156} height={50} src={AppStoreLogo} fit='contain'/>
                            </a>
                            <a href={PLAYSTORE_URL} target='_blank' rel='noreferrer'>
                            <Image width={156} height={50} src={GooglePlayLogo} fit='contain'/>
                            </a>
                        </Group>
                        
                    </div>
                    <div className={classes.groupCenter}>
                        <Text color='#FFFFFF' fw={700} pb={15} className={classes.sectionTitle}>iCourt</Text>
                        {items}
                        
                    </div>
                    <div className={classes.groupRight}>
                    <Text color='#FFFFFF' fw={600} pb={15} className={classes.sectionTitle}>Connect with Us</Text>
                        <Group>
                            <a href={FACEBOOK_URL} target='_blank' rel='noreferrer'>
                                <div className={classes.socialBtn}>
                                    <IconBrandFacebook size={matches ? 30 : 26}/>
                                </div>
                            </a>
                            <a href={INSTAGRAM_URL} target='_blank' rel='noreferrer'>
                                <div className={classes.socialBtn}>
                                    <IconBrandInstagram size={matches ? 30 : 26} />
                                </div>
                            </a>
                        </Group>
                    </div>
                </div>
                <div className={classes.flexRowReverse}>
                    <Text size={12} color='#FFFFFF' align='center'>2023 | ICOURT MANAGEMENT SDN BHD</Text>
                    <Group spacing={matches?30:10}>
                        {/*
                         <Anchor component={Link} to='/terms-of-use'
                        c="#FFFFFF"
                        size={matches?12:10}
                        fw={500}
                        >
                            TERM OF USE
                        </Anchor>
                         */}
              
                        <Anchor component={Link} to='/privacy-policy'
                        c="#FFFFFF"
                        size={matches?12:10}
                        fw={500}
                        >
                            PRIVACY POLICY
                        </Anchor>
         
                        <Anchor component={Link} to='/terms-conditions'
                        c="#FFFFFF"
                        size={matches?12:10}
                        fw={500}
                        >
                            TERMS & CONDITION
                        </Anchor>
                    </Group>
                </div>
        </Container>
        </div>
    );
}