import React, { useState } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Divider, Loader  } from '@mantine/core'
import { IconUserFilled, } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import UpcomingCard from '../../components/Profile/UpcomingCard';
import HistoryCard from '../../components/Profile/HistoryCard';
import VenueCard from '../../components/Profile/VenueCard';
import ConfirmationModal from '../../components/General/ConfirmationModal';

import useLogout from '../../hooks/useLogout';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(10),
    },

    containerRow: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'initial',
        },
    },

    navContainer: {
        flex: 2, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px', marginRight: 25,
        [theme.fn.smallerThan('sm')]: {
            marginRight: 0,
            marginBottom: 25,
        },
    },

    contentContainer: {
        flex: 8, 
    },

    pointsContainter: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px',
        [theme.fn.smallerThan('sm')]: {
            padding: '15px 20px',
        },
    },

    tabContainer: {
        display: 'flex',
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        overflow: 'hidden',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
    },

    tabBtn: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        color: '#202020',

        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',

        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: 0,
        borderTopWidth: 4,
        borderBottomWidth: 4,
        borderColor: '#FFFFFF',

        '&:hover': {
            color: '#F47120',
        },
    },

    tabBtnActive: {
        color: '#F47120',
        borderBottomColor: '#F47120',
    },

    pillContainer: {
        display: 'flex',
    },

    pillBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        color: '#5D5D5D',

        padding: '0px 22px',

        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',

        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#E6E6E6',

        borderRadius: 10,

        '&:hover': {
            color: '#F6851E',
        },
    },

    pillBtnActive: {
        color: '#F6851E',
        borderColor: '#F6851E',
    },

    title: {
        fontSize: 15,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    row: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    profilePlaceholderSM: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 38, height: 38, borderRadius: 50
    },

    profilePlaceholderXL: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 100, height: 100, borderRadius: 100
    },

    upcomingContainer: {
        display: 'flex', flexDirection: 'column',
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, marginBottom: 15, overflow: 'hidden',
        padding: '15px 20px 20px 20px'
    },

    paginationContainer: {
        marginTop: rem(25),
        display: 'flex',
        justifyContent: 'center',
    },

    contentBox: {
        backgroundColor: '#FFFFFF',
        padding: `${rem(15)} ${rem(15)}`,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
        marginBottom: 15,
    },

    innerContainer: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            // flexDirection: 'column',
        },
    },

    venueImgWrapper: {
        flex: 1, 
        [theme.fn.smallerThan('sm')]: {
            flex: 2
        },
    },

    venueTextWrapper: {
        flex: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
        [theme.fn.smallerThan('sm')]: {
            // flex: 2
        },
    },

    btnContainer: {
        display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'
    },
}))

const UPCOMING_TAB = 'UPCOMING'
const LIKED_TAB = 'LIKED'
const HISTORY_TAB = 'HISTORY'

export default function Booking() {
    const { classes, cx } = useStyles();
    const matches = useMediaQuery('(min-width: 48em)');
    const [activeTab, setActiveTab] = useState(UPCOMING_TAB)
    const navigate = useNavigate();
    const logout = useLogout();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient()

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)
    const [cancelBookingId, setCancelBookingId] = useState(null)
    const [likeVenueId, setLikeVenueId] = useState(null)

    const signOut = async () => {
        await logout();
        navigate('/');
    }

    const getUpcomingQuery = useQuery(
        "getUpcoming",
        async ({ signal }) => (await axiosPrivate.post("/schedule/read-upcoming", { uid: auth?.uid }, { signal })).data.schedule,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const getLikedQuery = useQuery(
        "getLiked",
        async ({ signal }) => (await axiosPrivate.post("/liked/read-location-by-uid", { uid: auth?.uid }, { signal })).data.locations,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const getHistoryQuery = useQuery(
        "getHistory",
        async ({ signal }) => (await axiosPrivate.post("/schedule/read-history", { uid: auth?.uid }, { signal })).data.schedule,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const cancelBookingMutation = useMutation(
        (data) => axiosPrivate.post("/schedule/change-status", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries("getBookingDetails")
                queryClient.invalidateQueries("getUpcoming")
                queryClient.invalidateQueries("getHistory")
                notifications.show({
                    title: 'Success',
                    message: 'Cancelled booking',
                    color: 'green',
                })
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const unlikeVenueMutation = useMutation(
        (data) => axiosPrivate.post("/liked/remove-by-uid", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries("getLiked")
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const onClickViewDetials = (id) => {
        navigate(`/profile/booking/${id}`)
    }

    const onClickViewVenue = (id) => {
        navigate(`/venue/${id}`)
    }

    const onClickCancelBooking = (id) => {
        setCancelBookingId(id)
        setConfirmationModalVisible(true)
    }

    const onClickConfirmedCancelBooking = () => {
        setConfirmationModalVisible(false)
        cancelBookingMutation.mutate({uid: auth.uid,scheduleId: cancelBookingId, isCancelled: true})
    }

    const onClickLikedUnLikedVenue = (item) => {
        if(unlikeVenueMutation.isLoading) return
        setLikeVenueId(item?.locationId)
        unlikeVenueMutation.mutate({ uid: auth.uid, locationId: item?.locationId })
    }

    const onClickPayNow = (id) => {
        navigate(`/venue/payment/${id}`)
    }

    return (
        <Container size={'lg'} className={classes.root}>
            <ConfirmationModal opened={confirmationModalVisible} onClose={()=>setConfirmationModalVisible(false)} text={'Cancel Booking?'} onClick={onClickConfirmedCancelBooking} approveBtnText='Confirm'/>
            <div className={classes.containerRow}>
                {/* Nav Section */}
                <div className={classes.navContainer}>
                    <Group spacing={10}>
                        <div className={classes.profilePlaceholderSM}>
                            <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                        </div>
                        <div>
                        <Text fw={600} color='#202020' lh={'normal'} size={13}>{auth?.fullname}</Text>
                        <Space h={4}/>
                        <Anchor component={Link} to={'/profile'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={11}>Edit Profile</Text>
                        </Anchor>
                        </div>
                    </Group>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        {/* <Anchor component={Link} to={'/profile/loyalty-points'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Loyalty Point</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/wallet'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Wallet</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/voucher'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Voucher</Text>
                        </Anchor>
                        <Space h={15}/> */}
                        <Anchor component={Link} to={'/profile/booking'} color='brandSecondary.6'>
                            <Text fw={500} lh={'normal'} size={12}>My Booking</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/membership'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Membership</Text>
                        </Anchor>
                    </div>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor onClick={signOut} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>Log Out</Text>
                        </Anchor>
                    </div>
                </div>
                {/* Content Section */}
                <div className={classes.contentContainer}>
                    <div className={classes.tabContainer}>
                        <div onClick={()=>setActiveTab(UPCOMING_TAB)} className={cx(classes.tabBtn, { [classes.tabBtnActive]: activeTab === UPCOMING_TAB })}>
                            <Text fw={500} lh={'normal'} size={14}>Upcoming ({getUpcomingQuery.data.length})</Text>
                            {getUpcomingQuery.isFetching ?
                            <Loader size='xs' ml={5}/>
                            : null}
                        </div>
                        <div onClick={()=>setActiveTab(LIKED_TAB)} className={cx(classes.tabBtn, { [classes.tabBtnActive]: activeTab === LIKED_TAB })}>
                            <Text fw={500} lh={'normal'} size={14}>Liked ({getLikedQuery.data.length})</Text>
                            {getLikedQuery.isFetching ?
                            <Loader size='xs' ml={5}/>
                            : null}
                        </div>
                        <div onClick={()=>setActiveTab(HISTORY_TAB)} className={cx(classes.tabBtn, { [classes.tabBtnActive]: activeTab === HISTORY_TAB })}>
                            <Text fw={500} lh={'normal'} size={14}>History</Text>
                            {getHistoryQuery.isFetching ?
                            <Loader size='xs' ml={5}/>
                            : null}
                        </div>
                    </div>

                    <Space h={15}/>
                    {activeTab === UPCOMING_TAB ?
                    <>
                    {getUpcomingQuery.data.map((item, i)=>(
                        <UpcomingCard key={i} data={item} isError={getUpcomingQuery.isError} loading={getUpcomingQuery.isFetching} onClick={()=>onClickViewDetials(item?.scheduleId)} onClickPayNow={()=>onClickPayNow(item?.scheduleId)} onClickCancelBooking={()=>onClickCancelBooking(item?.scheduleId)}/>
                    ))}
                    </>
                    : activeTab === LIKED_TAB ?
                    <>
                    {getLikedQuery.data.map((item, i)=>(
                        <VenueCard key={i} data={item} isError={getLikedQuery.isError} loading={getLikedQuery.isFetching} onClick={()=>onClickViewVenue(item?.locationId)} onClickLikedUnLikedVenue={()=>onClickLikedUnLikedVenue(item)}/>
                    ))}
                    </>
                    : activeTab === HISTORY_TAB ?
                    <>
                    {getHistoryQuery.data.map((item, i)=>(
                        <HistoryCard key={i} data={item} isError={getHistoryQuery.isError} loading={getHistoryQuery.isFetching} onClick={()=>onClickViewDetials(item?.scheduleId)}/>
                    ))}
                    </>
                    : null}
                </div>
                
            </div>
        </Container>
    )
}
