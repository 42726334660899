import {
    createStyles,
    Header,
    Container,
    Group,
    Burger,
    Paper,
    Transition,
    rem,
    UnstyledButton,
    Text,
    Space,
  } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { IconUser } from '@tabler/icons-react';

import useAuth from '../../hooks/useAuth';
  
const HEADER_HEIGHT = rem(70);
const HEADER_HEIGHT_MOBILE = rem(55);

  
const useStyles = createStyles((theme) => ({
    root: {
        zIndex: 999,
        backgroundColor: '#FFFFFF',

        // transition: 'background-color 0.25s ease',

        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    },

    dropdown: {
        position: 'absolute',
        top: HEADER_HEIGHT,
        left: 0,
        right: 0,
        zIndex: 999,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopWidth: 0,
        overflow: 'hidden',
        height: `calc(100lvh - ${rem(HEADER_HEIGHT)})`,
        backgroundColor: 'rgba(0, 0, 0, 0.40)',
        backdropFilter: 'blur(8px)',

        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
        [theme.fn.smallerThan('sm')]: {
            top: HEADER_HEIGHT_MOBILE,
            height: `calc(100lvh - ${rem(HEADER_HEIGHT_MOBILE)})`,
        },
    },

    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        padding: `${rem(0)} ${rem(30)}`,

        [theme.fn.smallerThan('sm')]: {
            padding: `${rem(0)} ${rem(20)}`,
        },
    },

    links: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    burger: {

        color: '#F47120',
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(16)}`,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: '#F47120',
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: 'rgba(125,125,125,0.2)',
        },

        [theme.fn.smallerThan('sm')]: {
            borderRadius: 0,
            fontSize: 18,
            padding: `${rem(20)} ${rem(20)}`,
        },

    },

    linkActive: {
        '&, &:hover': {
            textDecoration: 'underline',
            textDecorationThickness: 3,
            textDecorationColor: theme.colors.brand[6],
        },
    },

    logo: {
        fontSize: 24,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 20,
        },
        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
    },

    loginBtn: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', height: 36, paddingLeft:20, paddingRight: 20, borderRadius: 100, border: '2px solid #FFFFFF', color: '#202020',
        transition: 'all 0.25s ease',
        userSelect: 'none',
        cursor: 'pointer',
        '&:hover': {
            border: '2px solid #202020',
        },
    },

    joinBtn: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', height: 36, width: 236, borderRadius: 100, border: '2px solid #E7C92E', color: '#E7C92E',
        transition: 'all 0.25s ease',
        userSelect: 'none',
        cursor: 'pointer',
        '&:hover': {
            border: '2px solid #F47120',
            backgroundColor: '#F47120',
            color: '#FFFFFF'
        },
    },

    dropdownActive: {
        backgroundColor: 'rgba(0,0,0,1)',
        backdropFilter: 'blur(8px)',
    },
}));
  
export default function ZTRHeader() {
    const matches = useMediaQuery('(min-width: 48em)');
    const [opened, { toggle, close }] = useDisclosure(false);
    const { classes, cx } = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const { auth } = useAuth();

    let links = [
        { label: 'Home', link: '/' },
        { label: `${auth?.uid ? 'Profile' : 'Login'}`, link: `${auth?.uid ? '/profile' : '/login'}` },
        { label: 'Become our Venue Partner', link: '/venue-partner' },
    ]
  
    const items = links.map((link) => (
      <Link
        key={link.label}
        to={link.link}
        className={cx(classes.link, { [classes.linkActive]: link.link === `/${location.pathname.split("/")[1]}` })}
        onClick={() => {
          close();
        }}
      >
        {link.label}
      </Link>
    ));
  
    return (
      <Header height={matches ? HEADER_HEIGHT : HEADER_HEIGHT_MOBILE} className={cx(classes.root)} withBorder={false}>
            <Container size={2000} className={classes.header}>
                <UnstyledButton onClick={()=>navigate('/')}>
                    <Text variant="gradient" gradient={{ from: '#F57320', to: '#F6CF0F', deg: 267 }} fw={900} className={classes.logo}>iCourt</Text>
                </UnstyledButton>
                
                <Group spacing={5} className={classes.links}>
                    {/* {items} */}
                    {auth?.uid ?
                        <div onClick={()=>navigate('/profile')} className={classes.loginBtn}>
                            <IconUser size={14}/>
                            <Space w={6}/>
                            <Text size={12} fw={600} align='center'>{auth?.fullname}</Text>
                        </div>
                        :
                        <div onClick={()=>navigate('/login')} className={classes.loginBtn}>
                            <IconUser size={14}/>
                            <Space w={6}/>
                            <Text size={12} fw={600} align='center'>Log In</Text>
                        </div>
                    }
                    {/* <div className={classes.joinBtn}>
                        <Text size={12} fw={700} align='center'>Become our Venue Partner</Text>
                    </div> */}
                </Group>
        
                <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" color='#F47120' />
        
                <Transition transition="slide-left" duration={200} mounted={opened}>
                    {(styles) => (
                    <Paper className={classes.dropdown} style={styles}>
                        <div style={{backgroundColor: '#FFF'}}>
                            {items}
                        </div>
                    </Paper>
                    )}
                </Transition>
            </Container>
      </Header>
    );
}