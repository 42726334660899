import React, { useState, useEffect } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Divider, Button, TextInput, Box, Skeleton, FileButton, Avatar } from '@mantine/core'
import { IconUserFilled } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { Link, useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useQuery, useMutation } from 'react-query';

import ConfirmationModal from '../../components/General/ConfirmationModal';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useLogout from '../../hooks/useLogout';
import useAuth from '../../hooks/useAuth';

const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
        paddingTop: rem(25),
        paddingBottom: rem(25),
    },

    containerRow: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'initial',
        },
    },

    navContainer: {
        flex: 2, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px', marginRight: 25,
        [theme.fn.smallerThan('sm')]: {
            marginRight: 0,
            marginBottom: 25,
        },
    },

    contentWrapper: {
        flex: 8,
    },

    contentContainer: {
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '25px 40px',
        [theme.fn.smallerThan('sm')]: {
            padding: '25px 25px',
        },
    },

    title: {
        fontSize: 15,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    row: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    profilePlaceholderSM: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 38, height: 38, borderRadius: 50
    },

    profilePlaceholderXL: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 100, height: 100, borderRadius: 100
    },

}))


export default function EditProfile() {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const logout = useLogout();
    const { auth, setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const [file, setFile] = useState(null)
    const [preview, setPreview] = useState(null)

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)

    const [inputData, setInputData] = useState({
        fullname: '',
        phone_number: '',
        email: '',
    })

    useEffect(() => {
        if (!file) {
            setPreview(null)
            return
        }

        const objectUrl = URL.createObjectURL(file)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    const clearFile = () => {
        setFile(null);
    };

    const signOut = async () => {
        await logout();
        navigate('/');
    }

    const form = useForm({
        initialValues: {
            fullname: '',
            phone_number: '',
            email: '',
        },
    
        validate: {
            fullname: (value) => (value.length < 1 ? 'Please enter your name' : null),
            phone_number: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : value.trim().length < 9 ? 'Invalid Phone Number' : !/^\d*$/.test(value.trim()) ? 'Invalid Phone Number' : null),
            email: (value) => (value.length < 1 ? 'Please enter your email' : /^\S+@\S+$/.test(value) ? null : 'Invalid email address'),
        },
    });

    const getProfileQuery = useQuery(
        "getProfile",
        async ({ signal }) => (await axiosPrivate.post("/accounts/get-to-edit", {uid: auth.uid}, { signal })).data.userDetails,
        {
            initialData: null,
            onSuccess: (res) => {
                // console.log(res)
                form.setValues(res);
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const editProfileMutation = useMutation(
        (data) => axiosPrivate.post("/accounts/edit-profile", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res.data)
                notifications.show({
                    title: 'Success',
                    message: 'Account updated!',
                    color: 'green',
                })
                if(res.data.userDetails){
                    setAuth({...auth, fullname: res?.data?.userDetails?.fullname, })
                }
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                // console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const onUpdatePressed = (data) => {
        setInputData(data)
        setConfirmationModalVisible(true)
    }

    const onConfirmedPressed = () => {
        // let data = getValues()
        setConfirmationModalVisible(false)
        editProfileMutation.mutate({uid: auth.uid, fullname: inputData.fullname.trim(), email: inputData.email.trim(), phone_number: inputData.phone_number.trim()})
    }

    return (
        <Container size={'lg'} className={classes.root}>
        <ConfirmationModal opened={confirmationModalVisible} onClose={()=>setConfirmationModalVisible(false)} text={'Edit Profile?'} onClick={onConfirmedPressed} approveBtnText='Confirm'/>
            <div className={classes.containerRow}>
                {/* Nav Section */}
                <div className={classes.navContainer}>
                    <Group spacing={10}>
                        <div className={classes.profilePlaceholderSM}>
                            <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                        </div>
                        <div>
                        <Text fw={600} color='#202020' lh={'normal'} size={13}>{auth?.fullname}</Text>
                        <Space h={4}/>
                        <Anchor component={Link} to={'/profile'} color='brandSecondary.6'>
                            <Text fw={500} lh={'normal'} size={11}>Edit Profile</Text>
                        </Anchor>
                        </div>
                    </Group>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        {/* <Anchor component={Link} to={'/profile/loyalty-points'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Loyalty Point</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/wallet'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Wallet</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/voucher'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Voucher</Text>
                        </Anchor>
                        <Space h={15}/> */}
                        <Anchor component={Link} to={'/profile/booking'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Booking</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/membership'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Membership</Text>
                        </Anchor>
                    </div>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor onClick={signOut} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>Log Out</Text>
                        </Anchor>
                    </div>
                </div>
                {/* Content Section */}
                <div className={classes.contentWrapper}>
                <Skeleton animate={!getProfileQuery.isError} visible={getProfileQuery.isFetching || getProfileQuery.isError}>
                    <div className={classes.contentContainer}>
                        <Text fw={700} color='#202020' lh={'normal'} className={classes.title}>Edit Profile</Text>
                        <Divider my={25}/>  
                        <div className={classes.row}>
                            {/* <div style={{flex: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                {preview ?
                                <Avatar src={preview} size={100} radius={100} alt="profile image" />
                                :
                                <div className={classes.profilePlaceholderXL}>
                                    <IconUserFilled size={48} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                                </div>
                                }
                                <Space h={15}/>
                                {file && preview ?
                                    <>
                                    <Box w={120}>
                                    <Button onClick={clearFile} variant="default" size='xs' mb={10} fullWidth>Clear Image</Button>
                                    <Button size='xs' fullWidth>Save Changes</Button>
                                    </Box>
                                    </>
                                :
                                <Box w={120}>
                                <FileButton onChange={setFile} accept="image/png,image/jpeg">
                                    {(props) => <Button variant="default" size='xs' {...props} fullWidth>Upload Image</Button>}
                                </FileButton>
                                </Box>
                                }
                                
                                <Space h={15}/>
                                <Text fw={500} lh={'normal'} color='#C4C4C4' size={10}>Maximum File Size: 1MB</Text>
                                <Text fw={500} lh={'normal'} color='#C4C4C4' size={10}>File Format: JPEG, JPG & PNG</Text>
                            </div>
                            <Divider mx={40} orientation="vertical"/> */}
                            <div style={{flex: 5}}>
                                <form onSubmit={form.onSubmit((values) => onUpdatePressed(values))}>
                                    <Text fw={600} size={12} color='#202020' lh={'normal'} pb={5}>Full Name</Text>
                                    <TextInput
                                        placeholder="Full Name"
                                        {...form.getInputProps('fullname')}
                                    />
                                    <Space h={15}/>
                                    <Text fw={600} size={12} color='#202020' lh={'normal'} pb={5}>Phone Number</Text>
                                    <TextInput
                                        placeholder="Phone Number"
                                        icon={<Text size={14} color='#2D2D2D' lh={'normal'}>+60</Text>}
                                        disabled
                                        {...form.getInputProps('phone_number')}
                                    />
                                    <Space h={15}/>
                                    <Text fw={600} size={12} color='#202020' lh={'normal'} pb={5}>Email Address</Text>
                                    <TextInput
                                        placeholder="Email Address"
                                        {...form.getInputProps('email')}
                                    />
                                    <Space h={30}/>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Box w={100}>
                                            <Button type='submit' fullWidth loading={editProfileMutation.isLoading}>Save</Button>
                                        </Box>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Skeleton>
                </div>
            </div>
        </Container>
    )
}
