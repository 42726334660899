import React from 'react'
import { Text, Radio } from '@mantine/core'
import { IconCircle, IconCircleCheckFilled } from '@tabler/icons-react'

import { convertToCurrency } from '../../utils/Currency'

export default function CourtCard({data, select, onSelect, disable = false}) {
  return (
        <div onClick={disable ? null : onSelect} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid rgba(0, 0, 0, 0.15)', padding: '12px 15px', borderRadius: 5, cursor: 'pointer'}}>
            <Text fw={500} color='#202020' lh={'normal'} size={14}>{data?.name} - RM{convertToCurrency(data?.price)}</Text>
            {JSON.parse(select).some((obj)=>{ return obj.courtId === data?.courtId }) ?
                <IconCircleCheckFilled size={20} style={{color:'#F6851E'}}/>
                :
                <IconCircle size={20} style={{color:'#E6E6E6'}}/>
            }
        </div>
  )
}

/*
<Pressable onPress={()=>onSelect(item)} style={[styles.selection]}>
    <View>
        <Text style={[styles.selectionText,]}>{item?.name}</Text>
        <Text style={styles.selectionDescText}>RM{convertToCurrency(item?.price)}</Text>
    </View>
    {JSON.parse(select).some((obj)=>{ return obj.courtId === item?.courtId }) ?
    <Octicons name="check-circle-fill" size={20} color="#F6851E" />
    :
    <Octicons name="circle" size={20} color="#E6E6E6" />
    }
    
</Pressable>
*/
