import React from 'react'
import { Outlet } from 'react-router-dom'
import { MantineProvider, AppShell } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import ICourtHeader from './ICourtHeader';
import ICourtFooter from './ICourtFooter';

import MontserratFonts from '../../assets/fonts/MontserratFonts';

export default function ICourtAppShell() {

  return (
    <MantineProvider 
        theme={{ 
        fontFamily: 'Montserrat, sans-serif',
        colorScheme: 'light',
        colors: {
            /*brand: ["#F9FAFD", "#D3DAF5", "#A9BAF5", "#7A97FF", "#6583EE", "#5774DA", "#4D67C6", "#495FAE", "#4B5B94", "#4A557E"],
            lightGray: ['#FAFAFA', '#F9F9F9', '#F8F8F8', '#F7F7F7', '#F6F6F6', '#F5F5F5', '#F4F4F4', '#F3F3F3', '#F2F2F2', '#F1F1F1'],
            primary: ['#8FC1E3'],*/
            brand: ["#fffae2","#fbf4cf","#f5e8a3","#efdb72","#ead04a","#e7c930","#e6c620","#ccae0f","#b59b03","#9d8500"],
            brandSecondary: ["#fff1e2","#ffe1cd","#fcc29e","#f9a16b","#f6853f","#f47323","#f46a13","#d95806","#c24d00","#aa4000"],
        },
        primaryColor: 'brand',
        activeStyles: { transform: 'scale(0.98)' }}} withGlobalStyles withNormalizeCSS>
            <MontserratFonts />
            <Notifications position="top-center" zIndex={2077}/>
                <AppShell
                    padding={0}
                    header={<ICourtHeader />}
                    footer={<ICourtFooter />}
                    styles={(theme) => ({
                        main: { backgroundColor: '#FAFAFA' },
                    })}
                >
                <Outlet />
            </AppShell>
    </MantineProvider>
  )
}
