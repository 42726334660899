import React from 'react'
import { Skeleton, Text, Space, Group, Button } from '@mantine/core'

import { IconMapPinFilled, IconCalendarEvent, IconClockFilled, IconUsersGroup, IconUserFilled } from '@tabler/icons-react'

import { formatMMMM_DD_DOW, formath_mm_a } from '../../utils/DateTime'

export default function MatchCard({ data, onClick, isError, loading }) {

    const players = JSON.parse(data?.players ? data?.players : '[]')

  return (
    <Skeleton animate={!isError} visible={loading || isError}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '25px 30px', backgroundColor: '#FFFFFF', borderRadius: 5, boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.15)', overflow: 'hidden', margin: 5}}>
            <Text size={12} fw={500} color='#F47120' lh={'normal'}>{data?.sport}</Text>
            <Space h={5}/>
            <Text size={20} fw={700} color='#202020' lh={'normal'}>Public Match</Text>
            <Space h={5}/>
            <Text size={12} fw={500} color='#5D5D5D' lh={'normal'}>Hosted by {data?.host}</Text>
            <Space h={30}/>
            <Group spacing={8}>
                <IconMapPinFilled size={20} color='#5D5D5D' style={{color: '#5D5D5D'}}/>
                <Text size={13} fw={500} color='#5D5D5D' lh={'normal'}>{data?.locationName}</Text>
            </Group>
            <Space h={15}/>
            <Group spacing={8}>
                <IconCalendarEvent size={20} color='#5D5D5D' style={{color: '#5D5D5D'}}/>
                <Text size={13} fw={500} color='#5D5D5D' lh={'normal'}>{formatMMMM_DD_DOW(data?.startDatetime)}</Text>
            </Group>
            <Space h={15}/>
            <Group spacing={8}>
                <IconClockFilled size={20} color='#5D5D5D' style={{color: '#5D5D5D'}}/>
                <Text size={13} fw={500} color='#5D5D5D' lh={'normal'}>{formath_mm_a(data?.startDatetime)} - {formath_mm_a(data?.endDatetime)}</Text>
            </Group>
            <Space h={15}/>
            <Group spacing={8} align='flex-start'>
                <IconUsersGroup size={20} color='#5D5D5D' style={{color: '#5D5D5D'}}/>
                <div>
                    <Text size={13} fw={500} color='#5D5D5D' lh={'normal'} pt={2} pb={10}>{data?.noOfPlayers - players.length} / {data?.noOfPlayers} Slot(s) Left</Text>
                    <div style={{display: 'flex', flex: 1,}}>
                    {[...Array(data?.noOfPlayers)].map((x, i) => {
                        if(i>4)
                        return null
                        if(i===4 && data?.noOfPlayers > 5 && players.length > 5)
                        return(
                            <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                                <Text size={13} fw={700} color='#FFFFFF' lh={'normal'}>+{players.length-i}</Text>
                            </div>
                        )
                        else if(players.length > i)
                        return(
                            <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                                <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                            </div>
                        )
                        else
                        return(
                            <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: '#D4D4D4', marginRight: 5}}>
                                <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                            </div>
                        )
                    }
                        
                    )}
                    </div>
                </div>
            </Group>
            <Space h={30}/>
            <Button onClick={onClick} fullWidth>View Match</Button>
        </div>
    </Skeleton>
  )
}
