import React, { useState } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Group, Divider, Button,Pagination  } from '@mantine/core'
import { IconUserFilled, IconCoins } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';

import useLogout from '../../hooks/useLogout';


const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(25),
    },

    containerRow: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'initial',
        },
    },

    navContainer: {
        flex: 2, backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px', marginRight: 25,
        [theme.fn.smallerThan('sm')]: {
            marginRight: 0,
            marginBottom: 25,
        },
    },

    contentContainer: {
        flex: 8, 
    },

    pointsContainter: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px',
        [theme.fn.smallerThan('sm')]: {
            padding: '15px 20px',
        },
    },

    tabContainer: {
        display: 'flex',
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        overflow: 'hidden',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
    },

    tabBtn: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        color: '#202020',

        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',

        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: 0,
        borderTopWidth: 4,
        borderBottomWidth: 4,
        borderColor: '#FFFFFF',

        '&:hover': {
            color: '#F47120',
        },
    },

    tabBtnActive: {
        color: '#F47120',
        borderBottomColor: '#F47120',
    },

    title: {
        fontSize: 15,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 14,
        },
    },

    row: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    profilePlaceholderSM: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 38, height: 38, borderRadius: 50
    },

    profilePlaceholderXL: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(225deg, #F47120 0%, #F7D010 100%)',
        width: 100, height: 100, borderRadius: 100
    },

    logContainer: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: 5, padding: '15px 20px', marginBottom: 10,
    },

    paginationContainer: {
        marginTop: rem(25),
        display: 'flex',
        justifyContent: 'center',
    },
}))

const LOG = [
    {
        type: 'Redeemed',
        action: 'To Voucher',
        date: '24 October 2023',
        points: 1000,
    },
    {
        type: 'Earned',
        action: 'From Top Up',
        date: '23 October 2023',
        points: 1400,
    },
    {
        type: 'Redeemed',
        action: 'To Voucher',
        date: '24 October 2023',
        points: 1000,
    },
    {
        type: 'Earned',
        action: 'From Top Up',
        date: '23 October 2023',
        points: 1400,
    },
    {
        type: 'Redeemed',
        action: 'To Voucher',
        date: '24 October 2023',
        points: 1000,
    },
    {
        type: 'Earned',
        action: 'From Top Up',
        date: '23 October 2023',
        points: 1400,
    },
    {
        type: 'Redeemed',
        action: 'To Voucher',
        date: '24 October 2023',
        points: 1000,
    },
    {
        type: 'Earned',
        action: 'From Top Up',
        date: '23 October 2023',
        points: 1400,
    },
    {
        type: 'Redeemed',
        action: 'To Voucher',
        date: '24 October 2023',
        points: 1000,
    },
    {
        type: 'Earned',
        action: 'From Top Up',
        date: '23 October 2023',
        points: 1400,
    },
]

const ALL_TAB = 'ALL'
const EARNING_TAB = 'EARNING'
const SPENDING_TAB = 'SPENDING'

export default function LoyaltyPoints() {
    const { classes, cx } = useStyles();
    const matches = useMediaQuery('(min-width: 48em)');
    const [activeTab, setActiveTab] = useState(ALL_TAB)
    const [activePage, setPage] = useState(1)
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/');
    }

    return (
        <Container size={'lg'} className={classes.root}>
            <div className={classes.containerRow}>
                {/* Nav Section */}
                <div className={classes.navContainer}>
                    <Group spacing={10}>
                        <div className={classes.profilePlaceholderSM}>
                            <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                        </div>
                        <div>
                        <Text fw={600} color='#202020' lh={'normal'} size={13}>Tan Chong Wei</Text>
                        <Space h={4}/>
                        <Anchor component={Link} to={'/profile'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={11}>Edit Profile</Text>
                        </Anchor>
                        </div>
                    </Group>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor component={Link} to={'/profile/loyalty-points'} color='brandSecondary.6'>
                            <Text fw={500} lh={'normal'} size={12}>My Loyalty Point</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/wallet'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Wallet</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/voucher'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Voucher</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/booking'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Booking</Text>
                        </Anchor>
                        <Space h={15}/>
                        <Anchor component={Link} to={'/profile/membership'} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>My Membership</Text>
                        </Anchor>
                    </div>
                    <Divider my={15}/>
                    <div style={{padding: '0px 15px'}}>
                        <Anchor onClick={signOut} c={'#5D5D5D'}>
                            <Text fw={500} lh={'normal'} size={12}>Log Out</Text>
                        </Anchor>
                    </div>
                </div>
                {/* Content Section */}
                <div className={classes.contentContainer}>
                    <div className={classes.pointsContainter}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className={classes.profilePlaceholderSM}>
                                <IconCoins size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                            </div>
                            <Space w={10}/>
                            <div>
                            <Text fw={600} lh={'normal'} color='#202020' size={22}>1000 <span style={{fontWeight: 500, fontSize: 12}}>points</span></Text>
                            <Text fw={400} lh={'normal'} color='#5D5D5D' size={10}>Coin will expired on 31 December 2023</Text>
                            </div>
                        </div>
                        <Button size='xs'>Earn More</Button>
                    </div>
                    <Space h={15}/>
                    <div className={classes.tabContainer}>
                        <div onClick={()=>setActiveTab(ALL_TAB)} className={cx(classes.tabBtn, { [classes.tabBtnActive]: activeTab === ALL_TAB })}>
                            <Text fw={500} lh={'normal'} size={14}>All</Text>
                        </div>
                        <div onClick={()=>setActiveTab(EARNING_TAB)} className={cx(classes.tabBtn, { [classes.tabBtnActive]: activeTab === EARNING_TAB })}>
                            <Text fw={500} lh={'normal'} size={14}>Earning</Text>
                        </div>
                        <div onClick={()=>setActiveTab(SPENDING_TAB)} className={cx(classes.tabBtn, { [classes.tabBtnActive]: activeTab === SPENDING_TAB })}>
                            <Text fw={500} lh={'normal'} size={14}>Spending</Text>
                        </div>
                    </div>
                    <Space h={15}/>
                    {LOG.map((data, i)=>(
                        <div key={i} className={classes.logContainer}>
                            <div>
                                <Text fw={600} lh={'normal'} color='#202020' size={14}>{data?.type}</Text>
                                <Space h={4}/>
                                <Text fw={500} lh={'normal'} color='#5D5D5D' size={12}>{data?.action}</Text>
                                <Space h={12}/>
                                <Text fw={400} lh={'normal'} color='#ADADAD' size={12}>{data?.date}</Text>
                            </div>
                            {data?.type === 'Redeemed' ? 
                                <Text fw={500} lh={'normal'} color='#202020' size={16}>- {data?.points}</Text>
                                :
                                <Text fw={500} lh={'normal'} color='#F6851E' size={16}>+ {data?.points}</Text>
                            }
                        </div>
                    ))}
                    <div className={classes.paginationContainer}> 
                        <Pagination value={activePage} onChange={setPage} total={10} size={matches? 'md' : 'sm'}/>
                    </div>
                </div>
            </div>
        </Container>
    )
}
