import React, { useState } from 'react'
import { createStyles, Anchor, rem, Container, Text, Space, Button, Group, Skeleton  } from '@mantine/core'
import { IconChevronLeft, IconUserFilled } from '@tabler/icons-react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import ConfirmationModal from '../../components/General/ConfirmationModal';

import useAuth from '../../hooks/useAuth';

import axios from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { formatMMMM_DD_DOW, formath_mm_a } from '../../utils/DateTime'

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(25),
        paddingBottom: rem(25),
    },

    contentContainer: {
        backgroundColor: '#FFFFFF',
        padding: `${rem(30)} ${rem(30)}`,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
    },

    row: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    }
}))

const MATCH = {
    id: '1',
    sport: 'BADMINTON',
    type: 'Friendly Match 2 vs 2',
    host: 'Tan Chong Wei',
    venue: 'Sport Heroes (Lead Sport Badminton)',
    date: 'October 3, Tuesday',
    timeFrom: '8:00 pm',
    timeTo: '10:00 pm',
    players: 4,
    slot: 2,
    desc: 'Looking to find 2 more players to have a friendly match. Only intermediate & advance players only.*Price for the court will be split equally and can be paid with TNG or Duit Now.*',
    location: 'No 8 Jalan TSB 1 Taman Industri Sungai Buloh, Kota Damansara, 47000 Petaling Jaya, Selangor',
    court: 'Court 12',
}

export default function ViewMatch() {
    const { classes } = useStyles();
    const { id } = useParams();
    const { auth } = useAuth();
    const queryClient = useQueryClient();
    const location = useLocation();
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate();

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)

    const { data, isFetching, isError, isRefetching, refetch } = useQuery(
        "getGameDetails",
        async ({ signal }) => (await axios.post("/schedule/read-public-game-details", {scheduleId: id, uid: auth ? auth.uid : ''}, { signal })).data.schedule,
        {
            initialData: null,
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                // console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const joinMatchMutation = useMutation(
        (data) => axiosPrivate.post("/schedule/join-game", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries("getGameDetails")
                notifications.show({
                    title: 'Success!',
                    message: 'Successfully joined game',
                    color: 'green',
                })
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                // console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const leaveMatchMutation = useMutation(
        (data) => axiosPrivate.post("/schedule/leave-game", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                queryClient.invalidateQueries("getGameDetails")
                notifications.show({
                    title: 'Success!',
                    message: 'Successfully leave game',
                    color: 'green',
                })
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                // console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const onJoinLeavePressed = () => {
        setConfirmationModalVisible(false)
        if(!auth) {
            navigate('/login', { state:{ from: location }, replace: true });
            return; 
        }
        if(!data?.isJoined){
            joinMatchMutation.mutate({uid: auth.uid, scheduleId: id})
        }
        else{
            console.log('Leave Match')
            leaveMatchMutation.mutate({uid: auth.uid, scheduleId: id})
        }
    }

  return (
    <Container size={'sm'} className={classes.root}>
        <ConfirmationModal opened={confirmationModalVisible} onClose={()=>setConfirmationModalVisible(false)} text={`Are you sure you want to ${!data?.isJoined ? 'join' : 'leave'}?`} onClick={onJoinLeavePressed} approveBtnText='Confirm'/>
        <Anchor component={Link} to={'/'} color='brandSecondary'>
            <Group spacing={8}>
                <IconChevronLeft size={22}/>
                <Text fw={500} size={15}>Back to Home</Text>
            </Group>
        </Anchor>
        <Space h={25}/>
        <Skeleton animate={!isError} visible={isFetching || isError}>
        <div className={classes.contentContainer}>
                <Text size={14} fw={500} color='#F47120' lh={'normal'} tt='uppercase'>{data?.sport}</Text>
                <Space h={5}/>
                <Text size={18} fw={700} color='#202020' lh={'normal'}>Public Match</Text>
                <Space h={5}/>
                <Text size={14} fw={500} color='#5D5D5D' lh={'normal'}>Hosted by {data?.host}</Text>
                <Space h={25}/>
                <Text size={14} fw={500} color='#F47120' lh={'normal'}>DESCRIPTION</Text>
                <Space h={5}/>
                <Text size={14} fw={400} color='#202020' lh={'normal'} align='justify'>{data?.description}</Text>
                <Space h={25}/>
                <Text size={14} fw={500} color='#F47120' lh={'normal'}>LOCATION</Text>
                <Space h={5}/>
                <Text size={16} fw={700} color='#202020' lh={'normal'}>{data?.locationName}</Text>
                <Space h={5}/>
                <Text size={14} fw={500} color='#5D5D5D' lh={'normal'}>{data?.address}, {data?.post_code} {data?.city}, {data?.state}</Text>
                <Space h={25}/>
                <div className={classes.row}>
                    <div style={{flex: 1}}>
                        <Text size={14} fw={500} color='#F47120' lh={'normal'}>DATE & TIME</Text>
                        <Space h={5}/>
                        <Text size={16} fw={700} color='#202020' lh={'normal'}>{formatMMMM_DD_DOW(data?.startDatetime)}</Text>
                        <Space h={5}/>
                        <Text size={14} fw={500} color='#5D5D5D' lh={'normal'}>{formath_mm_a(data?.startDatetime)} - {formath_mm_a(data?.endDatetime)}</Text>
                        <Space h={25}/>
                    </div>
                    <div style={{flex: 1}}>
                        <Text size={14} fw={500} color='#F47120' lh={'normal'}>COURT BOOKED</Text>
                        <Space h={5}/>
                        <Text size={16} fw={700} color='#202020' lh={'normal'}>{data?.courtArrName ? data?.courtArrName.map((item, i)=>(data?.courtArrName.length === i+1 ?  item : item + ', ')) : null}</Text>
                        <Space h={25}/>
                    </div>
                </div>
                {/* <Space h={25}/> */}
                <Text size={14} fw={500} color='#F47120' lh={'normal'}>PLAYERS</Text>
                <Space h={5}/>
                <Text size={16} fw={700} color='#202020' lh={'normal'}>{data?.noOfPlayers - data?.players.length} Slot(s) left</Text>
                <Space h={5}/>
                <div style={{display: 'flex', flex: 1,}}>
                {[...Array(data?.noOfPlayers)].map((x, i) => {
                    if(i>4)
                    return null
                    if(i===4 && data?.noOfPlayers > 5 && data?.players.length > 5)
                    return(
                        <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                            <Text size={13} fw={700} color='#FFFFFF' lh={'normal'}>+{data?.players.length-i}</Text>
                        </div>
                    )
                    else if(data?.players.length > i)
                    return(
                        <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: 'linear-gradient(180deg, #F47120 0%, #E7C92E 100%)', marginRight: 5}}>
                            <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                        </div>
                    )
                    else
                    return(
                        <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',width: 42, height: 42, borderRadius: 50, background: '#D4D4D4', marginRight: 5}}>
                            <IconUserFilled size={20} color='#FFFFFF' style={{color: '#FFFFFF'}}/>
                        </div>
                    )
                }
                )}
                </div>
                <Space h={50}/>
                <Button onClick={()=>setConfirmationModalVisible(true)} fullWidth disabled={data?.isJoined && data?.uid === auth?.uid}>{!data?.isJoined ? 'Join Match' : 'Leave Match'}</Button>
        </div>
        </Skeleton>
    </Container>
  )
}
