import React from 'react'
import { createStyles, Image, Space, Text, Divider, Group, Box, ActionIcon, rem, Button,   } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks';

import { IconCircleCheckFilled, IconHeartFilled, IconHeart } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({

    contentBox: {
        backgroundColor: '#FFFFFF',
        padding: `${rem(15)} ${rem(15)}`,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
        marginBottom: 15,
    },

    innerContainer: {
        display: 'flex',
        [theme.fn.smallerThan('sm')]: {
            // flexDirection: 'column',
        },
    },

    venueImgWrapper: {
        flex: 1, 
        [theme.fn.smallerThan('sm')]: {
            flex: 2
        },
    },

    venueTextWrapper: {
        flex: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
        [theme.fn.smallerThan('sm')]: {
            // flex: 2
        },
    },

    btnContainer: {
        display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'
    },

    fontSM: {
        fontSize: 12,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 11,
        },
    },

    fontLG: {
        fontSize: 20,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 16,
        },
    },
}))

export default function MembershipCard({ data, onClick, isError, loading, }) {
    const { classes, cx } = useStyles();
    const matches = useMediaQuery('(min-width: 48em)');

    const gallery = JSON.parse(data?.gallery ? data?.gallery : '[]')

    let liked = true;

  return (
    <div className={classes.contentBox}>
        <div className={classes.innerContainer}>
            <div className={classes.venueImgWrapper}>
                <Image height={matches?200 : 100} src={gallery.length > 0 ? gallery[0] : null} style={{borderRadius: 5}} radius={5} alt="With default placeholder" withPlaceholder />
            </div>
            <Space w={20}/>
            <div className={classes.venueTextWrapper}>
                <div>
                    <Text fw={500} color='#F47120' lh={'normal'} className={classes.fontSM}>{data?.sports.map((item, i)=>(data?.sports.length === i+1 ?  item.sports_name : item.sports_name + ', '))}</Text>
                    <Space h={5}/>
                    <Text fw={700} color='#202020' lh={'normal'} className={classes.fontLG}>{data?.name}</Text>
                    <Space h={10}/>
                    <Text fw={500} color='#5D5D5D' lh={'normal'} className={classes.fontSM}>{data?.address}, {data?.post_code} {data?.city}, {data?.state}</Text>
                </div>
                {matches ?
                <>
                <div>
                    <Divider my={14}/>
                    <div style={{display: 'flex', gap: 20}}>
                        {data?.have_toilet ?
                        <Group spacing={5}>
                            <IconCircleCheckFilled size={16} color='#F47120' style={{color: '#F47120'}}/>
                            <Text size={12} fw={500} color='#5D5D5D' lh={'normal'}>Toilet</Text>
                        </Group>
                        : null}
                        {data?.have_parking ?
                        <Group spacing={5}>
                            <IconCircleCheckFilled size={16} color='#F47120' style={{color: '#F47120'}}/>
                            <Text size={12} fw={500} color='#5D5D5D' lh={'normal'}>Free Parking</Text>
                        </Group>
                        : null}
                        {data?.have_shower ?
                        <Group spacing={5}>
                            <IconCircleCheckFilled size={16} color='#F47120' style={{color: '#F47120'}}/>
                            <Text size={12} fw={500} color='#5D5D5D' lh={'normal'}>Bathroom</Text>
                        </Group>
                        : null}
                        {data?.have_shop ?
                        <Group spacing={5}>
                            <IconCircleCheckFilled size={16} color='#F47120' style={{color: '#F47120'}}/>
                            <Text size={12} fw={500} color='#5D5D5D' lh={'normal'}>Shop</Text>
                        </Group>
                        : null}
                    </div>
                    <Divider my={14}/>
                </div>
                <div className={classes.btnContainer}>
                    <Group spacing={10}>
                        <Box w={200}>
                            <Button onClick={onClick} fullWidth>View Venue</Button>
                        </Box>
                    </Group>
                </div>
                </>
                : null}
                
            </div>
        </div>
        {!matches ?
            <>
            <Space h={10}/>
            <div className={classes.btnContainer}>
                <Group spacing={10}>
                    <Box w={150}>
                        <Button onClick={onClick} size='xs' fullWidth>View Venue</Button>
                    </Box>
                </Group>
            </div>
            </>
        : null }
    </div>
  )
}
