import {
    createStyles, Button, Text, Modal, LoadingOverlay, CloseButton, ScrollArea, Image, Space
} from '@mantine/core';
import { IconCircle, IconCircleCheckFilled } from '@tabler/icons-react';
import { useState } from 'react';

const useStyles = createStyles((theme) => ({

    modalHeader: {
        height: 50, padding: '0px 20px',display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
    },

    modalFooter: {
        height: 50, padding: '0px 20px',display: 'flex', alignItems: 'center', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
    }

}))

export default function VoucherModal({opened, onClose, vouchers = [], selectedVoucher = null, setSelectedVoucher, isLoading = false}) {
    const { classes } = useStyles();

    const [select, setSelect] = useState(selectedVoucher)

    const onClickSelectVoucher = () => {
        setSelectedVoucher(select)
        onClose()
    }

  return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            padding={0}
            zIndex={999}
            size={'lg'}
        >
            <LoadingOverlay visible={isLoading} overlayBlur={2} radius='lg'/>
            <div className={classes.modalHeader}>
                <Text fw={600} color='#202020' lh={'normal'} size={15}>Select a voucher</Text>
                <CloseButton onClick={onClose} aria-label="Close modal"  iconSize={20} />
            </div>
            <ScrollArea h={350} p={20} pb={10}>
                {vouchers.map((data, i)=>(
                    <div onClick={()=>setSelect(data?.id)} key={i} style={{border: '1px solid #E6E6E6', padding: '15px 20px', marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{borderRadius: 5, overflow: 'hidden'}}>
                            <Image width={84} height={84} src={null} alt="With default placeholder" withPlaceholder />
                            </div>
                            <Space w={15}/>
                            <div>
                                <Text fw={500} color='#202020' lh={'normal'} size={15} pb={5}>{data?.name}</Text>
                                <Text fw={400} color='#5D5D5D' lh={'normal'} size={13}>{data?.validity}</Text>
                            </div>
                        </div>
                        {select === data?.id ?
                        <IconCircleCheckFilled size={24} color='#F6851E' style={{color: '#F6851E'}}/>
                        :
                        <IconCircle size={24} color='#E6E6E6'/>
                        }
                        
                    </div>
                ))}
            </ScrollArea>
            <div className={classes.modalFooter}>
                <Button size='xs' onClick={onClickSelectVoucher} fullWidth disabled={!select}>
                    Select Voucher
                </Button>
            </div>
        </Modal>
  )
}
