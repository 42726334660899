import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ICourtAppShell from './components/General/ICourtAppShell';

import Home from './pages/Home/Home';
import ViewMatch from './pages/Match/ViewMatch';
import SearchVenue from './pages/Venue/SearchVenue';
import ViewVenue from './pages/Venue/ViewVenue';
import BookVenue from './pages/Venue/BookVenue';

import Payment from './pages/Payment/Payment';
import PaymentStatus from './pages/Payment/PaymentStatus';

//  Auth
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
// import ForgetPassword from './pages/Auth/ForgetPassword';

//Profile
import EditProfile from './pages/Profile/EditProfile';
import LoyaltyPoints from './pages/Profile/LoyaltyPoints';
import Wallet from './pages/Profile/Wallet';
import Voucher from './pages/Profile/Voucher';
import Booking from './pages/Profile/Booking';
import TopUp from './pages/Profile/TopUp';
import ViewBooking from './pages/Profile/ViewBooking';
import BookingStatus from './pages/Venue/BookingStatus';
import Membership from './pages/Profile/Membership';

//Misc
import NotFound from './pages/Misc/NotFound';
import TermsConditions from './pages/Misc/TermsConditions';
import TermsOfUse from './pages/Misc/TermsOfUse';
import PrivacyPolicy from './pages/Misc/PrivacyPolicy';

import PersistLogin from './components/General/PersistLogin';
import RequireAuth from './components/General/RequireAuth';

function App() {


  return (
    <Routes>
        <Route element={<ICourtAppShell />}>
            <Route element={<PersistLogin />}>
                {/* Public Route */}
                <Route path="/" element={<Home />} />
                <Route path="/match/:id" element={<ViewMatch />} />
                <Route path="/search" element={<SearchVenue/>} />
                <Route path="/venue/:id" element={<ViewVenue/>} />

                {/* Private Route */}
                <Route element={<RequireAuth />}>
                    <Route path="/venue/book/:id" element={<BookVenue/>} />
                    <Route path="/venue/payment/:id" element={<Payment/>} />
                    <Route path="/venue/payment-status/:id" element={<PaymentStatus/>} />
                    <Route path="/venue/booking-status/:status" element={<BookingStatus/>} />
                    <Route path="/profile" element={<EditProfile/>} />
                    <Route path="/profile/loyalty-points" element={<LoyaltyPoints/>} />
                    <Route path="/profile/wallet" element={<Wallet/>} />
                    <Route path="/profile/wallet/top-up" element={<TopUp/>} />
                    <Route path="/profile/voucher" element={<Voucher/>} />
                    <Route path="/profile/booking" element={<Booking/>} />
                    <Route path="/profile/booking/:id" element={<ViewBooking/>} />
                    <Route path="/profile/membership" element={<Membership/>} />
                </Route>
            </Route>

            {/* Public Route */}
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />

            {/* <Route path="/forget-password" element={<ForgetPassword/>} /> */}
        </Route>

        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

export default App;
