import axios from "axios";
import { BASE_API_URL } from "../utils/Contants";

axios.defaults.withCredentials = true

export default axios.create({
    baseURL: BASE_API_URL,
})

export const axiosPrivate = axios.create({
    baseURL: BASE_API_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
})