export const TERMS_AND_CONDITIONS = `<h1 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 21px;border: 0px;">Last Updated: 20 Jul 2022</h1>
<h1 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 21px;border: 0px;">Terms of i-Court app</h1>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">TERM OF USER APP USE</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">The following terminology applies to these Term and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: Client, You and Your refers to you, the person accessing this website and accepting the Company<sup style="font-size: 12px;border: 0px;">TM</sup>s terms and conditions. The Company, Ourselves, We, Our and Us, refers to our Company (I Court Management Sdn Bhd (Registration No:1391498)). &ldquo;Venue&rdquo; refers to the courts that can be booked through your site.</li>
    <li style="border: 0px;">By downloading, installing and/or using the I-Court User App we assume you accept these terms and conditions in full. If you do not accept all the terms and conditions stated on this page, please immediately discontinue your use of I-Court App and/or Services</li>
    <li style="border: 0px;">Please read these Terms and Conditions carefully before using this site. Printing a copy for your future reference is recommended.</li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">CHANGES</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">We reserve the right to amend, modify, suspend or to shut down I-Court User App for update purposes from time to time.</li>
    <li style="border: 0px;">You agree that your continued use of I-Court App and/or the Services shall constitute your acceptance of these Terms and Conditions including any term which may have been amended from time to time. You further agree that You are solely responsible to regularly check these Terms and Conditions for updates or amendments.</li>
    <li style="border: 0px;">We do not guarantee that the content on our site is free from error or omissions.</li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">YOUR USE ON THIS APP</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">Your use of this User App inclusive of published links, materials, comments, or information must not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material or material which is an invasion of privacy.</li>
    <li style="border: 0px;">Your use of this site is solely for your own personal use. You may not use our site or application:<ol style="border: 0px;">
            <li style="border: 0px;">To not copy, reproduce, resell, or otherwise deal with any contents on the site.</li>
            <li style="border: 0px;">In any way that breaks any applicable law and regulation.</li>
            <li style="border: 0px;">To replicate, distribute or repost any content on the site.</li>
        </ol>
    </li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">DISCLAIMER</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">I-Court User App is a platform that provides an alternative way to book and make payments for sport facilities.</li>
    <li style="border: 0px;">The services and all information, products, and other content included in or accessible from the services and provided &ldquo;as in&rdquo; and without warranties of any kind (implied, statutory, and implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose), all of which I Court Management Sdn Bhd expressly disclaims to the fullest extent permitted by law.</li>
    <li style="border: 0px;">In no circumstances shall I Court Management Sdn Bhd be liable for any loss, additional payment, or damage however from using our website in anyway.</li>
    <li style="border: 0px;">Booking confirmed in I-Court User App cannot be changed.</li>
    <li style="border: 0px;">We may change these term and conditions from time to time without prior notice. Changes will apply to any subsequent transactions.</li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">BOOKING PROCESS</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">To make a booking for a court, you will have to complete all steps mentioned until you receive a receipt and a booking number. We will not be responsible for any booking or payment failure.</li>
    <li style="border: 0px;">You will be assigned and handled by a third-party during payment.</li>
    <li style="border: 0px;">All venue House Rule are applicable for I-Court User App customers.</li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">PAYMENT</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">All payment will be redirected and handled by a third party, (Senangpay) Simplepay Gateway Sdn. Bhd.</li>
    <li style="border: 0px;">Upon every transaction, an email with the FPX reference number will be sent to the registered user&rsquo;s email address.</li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">CANCELLATION</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">Transactions made in I-Court User App are NOT refundable base on below term:<ol style="border: 0px;">
            <li style="border: 0px;">Booking cancellation less than 12 hours before check-in time.</li>
        </ol>
    </li>
    <li style="border: 0px;">Transactions made in I-Court User App are refundable based on below terms:<ol style="border: 0px;">
            <li style="border: 0px;">Full refund of booking fees if booking cancellation at least 24 hours before check-in time.</li>
            <li style="border: 0px;">50% refund of booking fees if booking cancellation more than 12 hours before check-in time</li>
            <li style="border: 0px;">Refund of booking fees are subjected to a 2.5% processing fees.</li>
        </ol>
    </li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">REFUND POLICY</h2>
<div style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 15px;border: 0px;">
    <ul style="border: 0px;">
        <li style="font-size: 15px;border: 0px;">Upon a termination request of your I-Court Account made by You, You are entitled to obtain a refund of any available balance in your I-Court Account. You shall ensure that You provide to Us the correct details of your own bank account and any other information as may be required by Us to enable Us to process the refund and refund the available balance to the bank account within fourteen (14) working days.</li>
        <li style="border: 0px;">If You intend to request for a refund in respect of any fees or payments made towards purchases made through your I-Court Account, please contact Us at icourt4545@gmail.com<span style="font-size: 8pt;border: 0px;">&nbsp;</span>, subject to Us determining to our satisfaction that your balance was wrongly deducted from your I-Court Account due to:<ol style="border: 0px;">
                <li style="border: 0px;">Technical error of I-Court where your attempted transaction does not go through; or</li>
                <li style="border: 0px;">Goods or services purchased through I-Court App was not made available after payment was made.</li>
                <li style="border: 0px;">We reserve our right not to refund any disputed amount arising from disputed transaction(s) to You if We believe You have acted in contrary to these Terms and Conditions.</li>
            </ol>
        </li>
    </ul>
    <div language="JavaScript" style="border: 0px;">
        <ul style="border: 0px;">
            <li style="border: 0px;">In the event your I-Court Account is terminated or suspended by Us due to fraudulent, illegal, unlawful transactions or money laundering suspicions including but not limited to breaches of any law or regulations, it shall be lawful for Us to retain for an indefinite period or release to the relevant authorities the balance in your I-Court Account in accordance with applicable legislation, regulation and/or guidelines. You shall not be entitled to claim any form of compensation from Us for any loss arising therefrom.</li>
            <li style="border: 0px;">Kindly note that there will be no refund under any circumstances whatsoever of any amount received by You as reward/bonus/cashback through the usage of I-Court App.</li>
            <li style="border: 0px;">Any eligible refunds of amounts which was reloaded via credit card will be made back to the credit card account used for the reload.</li>
            <li style="border: 0px;">I-Court may sell or issue vouchers representing the value of I-Court App credit for use within the I-Court App (&ldquo;I-Court Voucher&rdquo;). I-COURT Vouchers received or acquired from any party is not re-sellable, refundable, exchangeable, and cannot be replaced once issued by Us. You shall be solely responsible for any damage, loss and/or expiry of the I-Court Voucher. For the avoidance of doubt, We shall not refund, exchange, and/or replace the I-Court Voucher for any reason whatsoever.</li>
        </ul>
        <h2 style="color: rgb(54, 95, 145);font-size: 17px;border: 0px;">ENTIRE AGREEMENT</h2>
        <div style="font-size: 15px;border: 0px;">
            <ul style="border: 0px;">
                <li style="font-size: 15px;border: 0px;">These site terms (Including, where applicable, our Privacy Statement) set out the entire agreement between you and us and supersede all prior terms, conditions, warranties and/or representations to the fullest extent permitted by law.</li>
            </ul>
            <h2 style="color: rgb(54, 95, 145);font-size: 17px;border: 0px;">YOUR CONCERNS</h2>
            <div style="font-size: 15px;border: 0px;">
                <ul style="border: 0px;">
                    <li style="font-size: 15px;border: 0px;"><span style="border: 0px;">&nbsp;</span>If you have any concerns about any content that appears on these sites, please contact <a href="mailto:icourt4545@gmail.com" style="color: rgb(0, 123, 255);border: 0px;">icourt4545@gmail.com</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>`

export const REFUND_POLICY = `<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong>REFUND POLICY</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>&bull; Once you have made the payment, you have the right to cancel this warranty plan within 14 days of the policy start date. If you decide that you do not want this warranty plan, our side will make a refund to you.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>&bull; If there is damage within 30 days, you do not make any repairs or want to wait for a warranty plan from us, we reserve the right to cancel your warranty, and your payment will be fully refunded.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>&bull; After your warranty has reached 30 days and 400 km if you do not make any service on your vehicle and do not follow our SOP, your warranty will be cancelled and there will be no refund of any payments.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong>REFUND PROCESS</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>&bull; Our refund process will take 14 days.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>&bull; Our refunds are processed centrally around 14 working days. When returning a product for a refund, you will be asked for your name, IC number, contact number, address, reasons for a refund, and signature as authorization for the refund transaction into your bank account or credit card.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>&bull; Kindly contact info@xtenz.com.my. Our team will get in touch with you to process the refund. The refund will require up to 14 working days.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong>IMPORTANT NOTICE</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>If you have any further queries, kindly contact us at +6016 529 8622, or log on to www.xtenz.com.my.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>*Charges incurred for repairs will be claimed under your Private Car Comprehensive Warranty Policy.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;'>Thank you.</p>`

export const PRIVACY_POLICY = `<h1 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 21px;border: 0px;">Last Updated: 20 Jul 2022</h1>
<h1 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 21px;border: 0px;">Privacy Policy</h1>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">INFORMATION ABOUT US</h2>
<p style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 15px;border: 0px;">I Court Management Sdn Bhd with registered office at Kajang, Selangor, Malaysia ensures your privacy is protected and that your personal data is processed in accordance with Personal Data Protection Act 2010. We treat and view your personal data very seriously.</p>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">INFORMATION WE COLLECT</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">We collect the information you provide to us when you are using, I Court Management Vendor/User Apps.</li>
    <li style="border: 0px;">Account information: During signing up process, compulsory data such as name, email address, date of birth are required.</li>
    <li style="border: 0px;">Profile information: Building your profile requires your address, phone number and gender. These information may be used for identification purposes. Pictures uploaded for profile picture will be visible to others.</li>
    <li style="border: 0px;">Payment information: We collect your financial information (like your bank account or credit card information) when you use the Payment Services to process payments.</li>
    <li style="border: 0px;">Communications with I-Court member: We collect the information you choose to provide during your communication with us.</li>
    <li style="border: 0px;">Other information: You may otherwise choose to provide other information when filling up forms or survey. I-COURT will collect these information for future analysis and improvement.</li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">HOW WE USE THE INFORMATION WE COLLECT</h2>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">Account information: Enables you to access I-Court Booking features.</li>
    <li style="border: 0px;">Profile information: Personalize or otherwise customize your experience by, among other things, ranking search results or showing ads based on your search, booking history, and preferences.</li>
    <li style="border: 0px;">Payment information: Enable you to access and use Payment Service.</li>
    <li style="border: 0px;">Email: Allow us to keep in contact with you by sending you service or supporting messages such as updates, security alerts and account notifications.</li>
    <li style="border: 0px;">Feedbacks: To understand your concerns and providing is information for improvement.</li>
</ul>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">DISCLOSURE</h2>
<p style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 15px;border: 0px;">The personal data we collected from you or that you provide to us are generally kept confidential, but we may share your personal data with selected third partied including:</p>
<ul style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">
    <li style="border: 0px;">Any person to whom we are compelled or required to do so by law.</li>
    <li style="border: 0px;">Payment channels.</li>
    <li style="border: 0px;">Business partners, Suppliers, and sub-contractors for the performance of any contract we enter with them or you.</li>
    <li style="border: 0px;">Analytics and search engine providers that assist us in the improvement and optimization of our site.</li>
    <li style="border: 0px;">Our consultants, accountants, auditors, lawyers, or other financial professional advisers.</li>
    <li style="border: 0px;">Our business partners and affiliates that provide related services or products in connection with our business for joint promotions.</li>
    <li style="border: 0px;">Embedded content from other websites.</li>
</ul>
<p style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 15px;border: 0px;">Articles on this site my include embedded content (e.g. videos, images, articles, etc.) Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
<p style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 15px;border: 0px;">These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">COOKIES</h2>
<p style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 15px;border: 0px;">We employ the use of cookies. By using I COURT MANAGEMENT SDN BHD Vendor/User App, you consent to the use of cookies in accordance with I COURT MANAGEMENT SDN BHD privacy policy. Cookies enable us to respond to you as an individual and to tailor our operations to your needs by gathering and remembering information about your preferences. Cookies also helps us to provide you with a better experience in our site. You should be aware that more of the modern-day interactive websites use cookies. However, you can usually modify your browser setting to decline cookies if you prefer.</p>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">YOUR CONTROL OVER PERSONAL DATA AND ACCESS TO IT</h2>
<p style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;"><span style="font-size: 15px;border: 0px;">You have the rights to request for access to and correct the information you have provided. We will usually inform you (before collecting your data) if we intend to use your data for such purpose or if we intend to disclosure, your information to any third party for such purpose.</span></p>
<h2 style="text-align: left;color: rgb(54, 95, 145);background-color: rgb(255, 255, 255);font-size: 17px;border: 0px;">CONTACT</h2>
<p style="text-align: left;color: rgb(33, 37, 41);background-color: rgb(255, 255, 255);font-size: 16px;border: 0px;">Question, comments, and request regarding this Privacy Policy can be addressed to <a href="mailto:icourt4545@gmail.com" style="color: rgb(0, 123, 255);border: 0px;">icourt4545@gmail.com</a></p>`